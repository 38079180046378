import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { PaymentStripComponent } from './modules/payment-strip/payment-strip.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ExportUserDetailsComponent } from './pages/export-user-details/export-user-details.component';
import { SupportComponent } from './pages/support/support.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'signin',
    canActivate: [AuthenticationValidationService]
  },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInComponent
  },
  {
    path: 'export-details/:token',
    pathMatch: 'full',
    component: ExportUserDetailsComponent
  },
  {
    path: 'trackmylocation',
    pathMatch: 'full',
    component: TrackMyLocationComponent
  },
  {
    path: 'reset-password/:token',
    pathMatch: 'full',
    component: ResetPasswordComponent
  },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    component: PrivacyPolicyComponent
  },
  {
    path: 'support',
    pathMatch: 'full',
    component: SupportComponent
  },
  
  // {
  //   path: 'admin-dashboard',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/admin-dashboard/admin-dashboard.module#AdminDashboardModule'
  // },
  // {
  //   path: 'admin-dashboard',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/dashboard/dashboard.module#DashboardModule'
  // },
  {
    path: 'admin-dashboard',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'bookings',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/bookings/bookings.module#BookingsModule'
  },
  {
    path: 'emergency',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/emergency/emergency.module#EmergencyModule'
  },
  {
    path: 'passengers',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/passengers/passengers.module#PassengersModule'
  },
  // {
  //   path: 'manage-corporates',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/corporate-passenger/corporate-passenger.module#CorporatePassengerModule'
  // },
  // {
  //   path: 'occasional-trip',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/occasional-trip/occasional-trip.module#OccasionalTripModule'
  // },
  {
    path: 'vehicles',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/vehicles/vehicles.module#VehiclesModule'
  },
  {
    path: 'drivers',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/drivers/drivers.module#DriversModule'
  },
  {
    path: 'manage-zones',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/manage-cities/manage-cities.module#ManageCitiesModule'
  },
  // {
  //   path: 'manage-companies',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/manage-companies/manage-companies.module#ManageCompaniesModule'
  // },
  {
    path: 'marketing',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/marketing/marketing.module#MarketingModule'
  },
  {
    path: 'content-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/tenant-settings/tenant-settings.module#TenantSettingsModule'
  },
  {
    path: 'user-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/user-management/user-management.module#UserManagementModule'
  },
  // {
  //   path: 'operator-management',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/operator-management/operator-management.module#OperatorManagementModule'
  // },
  {
    path: 'settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/settings/settings.module#SettingsModule'
  },
  {
    path: 'events',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/event/event.module#EventModule'
  },
  {
    path: 'incidents-complaints',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/incidents-complaints/incidents-complaints.module#IncidentsComplaintsModule'
  },
  {
    path: 'reports',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/reports/reports.module#ReportsModule'
  },
  // {
  //   path: 'payment',
  //   pathMatch: 'full',
  //   component: PaymentStripComponent
  // },
  {
    path: 'queue-management/queue-driver-list',
    redirectTo: 'queue-management',
  },
  {
    path: 'queue-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/queue-management/queue-management.module#QueueManagementModule'
  },
  {
    path: 'shift-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/shift-management/shift-management.module#ShiftManagementModule'
  },
  {
    path: 'manage-locations',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/manage-locations/manage-locations.module#ManageLocationsModule'
  },
  {
    path: 'user-manual',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/user-manual/user-manual.module#UserManualModule'
  },
  {
    path: 'manage-shuttles',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/manage-shuttles/manage-shuttles.module#ManageShuttlesModule'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
