
<div class="signin-box-container scroll-bar-css ui-g">
  <!-- New Sign In Page -->
  <div class="signin-box-inner-container">
    <div class="ui-g-12 ui-g-nopad sign-in-text">
      <div class="ui-lg-6 ui-lg-offset-3 ui-md-8 ui-md-offset-2 ui-sm-12 ui-sm-offset-0 sign-in-content">
        {{signInDescription}}</div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-12 ui-md-4 ui-lg-4 ui-sm-12 ui-lg-offset-4 ui-md-offset-4 ui-sm-offset-0 ui-g-nopad">
        <p-panel>
          <div class="form-field ui-g-12 ui-g-nopad">
            <div class="text-center"><img src="/assets/images/temp/fifa_header.png"></div>
            <div class="form-field ui-g-12 switch-btn">Reset Password</div>
          </div>
          <!-- <div class="form-field ui-g-12">
            <div class="form-errors alert alert-danger" [hidden]='!loginErrors[0]["general"]'>
              <div *ngFor="let ErrorMessage of loginErrors['general']">
                {{ErrorMessage.message}}
              </div>
            </div>
          </div> -->
          <!-- <div class="form-field ui-g-12">
            <div class="form-errors alert alert-danger" [hidden]='!loginErrors[0]["email"]'>
              <div *ngFor="let ErrorMessage of loginErrors['email']">
                {{ErrorMessage.message}}
              </div>
            </div>
          </div> -->

          
          <div class="form-field ui-g-12">
            <div class="form-errors alert alert-danger" [hidden]='!changePasswordErrors[0]["general"]'>
              <div *ngFor="let ErrorMessage of changePasswordErrors['general']">
                {{ErrorMessage.message}}
              </div>
            </div>
          </div>
          

          <!-- <app-login></app-login> -->
          <form [formGroup]='setPasswordForm'>
            <div class="form-field  ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="email-label" translate>label_new_password</label>
              </div>
              <div class="form-control">
                <div class="form-control password-show">
                  <input formControlName="newPassword" id="username" class="full-width-transperent password-field" 
                  [type]="inputType" pInputText  />
                    <span class="eye-icon sign-in-eye" (click)="tooglePasswordType()">
                      <i *ngIf="inputType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                      <i *ngIf="inputType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="form-errors"
                  *ngIf="setPasswordForm.controls.newPassword.errors  && (setPasswordForm.controls.newPassword.dirty || setPasswordForm.controls.newPassword.touched)">
                  <div [hidden]="!setPasswordForm.controls.newPassword.errors.required" translate>
                    error_new_password_required
                  </div>
                  <div [hidden]="!setPasswordForm.controls.newPassword.errors.minlength && !setPasswordForm.controls.newPassword.errors.pattern" translate>
                    error_password_validation
                  </div>
                  <div [hidden]="!setPasswordForm.controls.newPassword.errors.maxlength" translate>
                    exceed_max_length
                  </div>
                </div>
              </div>
            </div>
          
            <div class="form-field  ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="newConfirmPassword" translate>label_new_confirm_password</label>
              </div>
              <div class="form-control">
                <div class="form-control password-show">
                  <input pInputText [type]="cnfInputType" id="password" formControlName="newConfirmPassword" 
                  class="full-width-transperent password-field"/>
                    <span class="eye-icon sign-in-eye" (click)="toogleCnfPasswordType()">
                      <i *ngIf="cnfInputType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                      <i *ngIf="cnfInputType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="form-errors"
                  *ngIf="setPasswordForm.controls.newConfirmPassword.errors  && (setPasswordForm.controls.newConfirmPassword.dirty || setPasswordForm.controls.newConfirmPassword.touched)">
                  <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.required" translate>
                    error_new_confirm_password_required
                  </div>
                  <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.minlength && !setPasswordForm.controls.newConfirmPassword.errors.pattern" translate>
                    error_password_validation
                  </div>
                  <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.maxlength" translate>
                    exceed_max_length
                  </div>
                </div>
              </div>
            </div>
          
            <div class="form-field ui-g-12">
              <div class="form-field ui-g-12 ui-g-nopad">
                <img src=''>
              </div>
              <div class="form-field ui-g-12 ui-g-nopad">
                <button pButton appRipple label="Reset" class='sign-in-buttons' type="button" [disabled]="!setPasswordForm.valid"
                  (click)="doChangePassword()"></button>
              </div>
          
            </div>
          </form>

          <div style="clear: both;"></div>
        </p-panel>

    </div>
    <div  class="ui-g-12 sign-in-text" style="text-align: center;position: fixed; bottom:0;padding-bottom:0;">
     
    </div>
  </div>
  
</div>

