<div class="ui-g form-container">

  <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
    <div class="form-errors alert alert-danger" [hidden]='!forgotPasswordErrors[0]["general"]'>
      <div *ngFor="let ErrorMessage of forgotPasswordErrors['general']">
        {{ErrorMessage.message}}
      </div>
    </div>

    <form [formGroup]='editforgotForm' autocomplete="off">

      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-label">
          <label for="username" translate>Username</label>
        </div>
        <div class="form-control">
          <input formControlName="username" type="text" autocomplete="off" pInputText />
        </div>
        <div class="form-errors" *ngIf="editforgotForm.controls.username.errors  && (editforgotForm.controls.username.dirty || editforgotForm.controls.username.touched)">
          <div [hidden]="!editforgotForm.controls.username.errors.required" translate>
            email_required
          </div>
          <div [hidden]="!editforgotForm.controls.username.errors.pattern" translate>
            error_invalid_email
          </div>
        </div>
      </div>

      <div class="form-buttons ui-g-12 ui-g-nopad">
        <button class="negative-button" pButton appRipple type="button" icon="fa fa-close" label="Cancel" (click)="closeEditForgot($event)"></button>
        <button pButton appRipple type="button" label="Send" icon="fa fa-check" [disabled]="!editforgotForm.valid" (click)="changePassword($event)"></button>
      </div>
    </form>
  </div>

</div>