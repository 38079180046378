import { AppSettings } from './../app.settings';
import { Directive, ElementRef, EventEmitter, Output, Input, OnChanges } from '@angular/core';
declare const google: any;
@Directive({
  selector: '[appSearchLocation]'
})
export class SearchLocationDirective implements OnChanges {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Input() onlyCity: Boolean = false;
  private _el: HTMLElement;
  autocomplete: any;
  constructor(el: ElementRef) {
    this._el = el.nativeElement;
    const input = this._el;
    input.onpaste = function(e) {
      e.preventDefault();
    }
    this.autocomplete = new google.maps.places.Autocomplete(input, {});
    this.autocomplete.setComponentRestrictions(
      { 'country': AppSettings.SUPPORTED_COUNTRY });
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      try {
        if (!place.geometry) {
          const geocoder = new google.maps.Geocoder();
          const self = this;
          geocoder.geocode({ 'address': place.name }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              const placeObj = {
                'name': place.name,
                'address': input['value'],
                'lat': results[0].geometry.location.lat(),
                'lng': results[0].geometry.location.lng(),
                'id': input.id
              };
              self.invokeEvent(placeObj);
            }
          });
        } else {
          const placeObj = {
            'name': place.name,
            'address': input['value'],
            'lat': place.geometry.location.lat(),
            'lng': place.geometry.location.lng(),
            'id': input.id
          };
          this.invokeEvent(placeObj);
        }
      } catch (ex) {
        console.warn(ex);
      }

    });

  }


  ngOnChanges() {
    if (this.onlyCity) {
      this.autocomplete.setOptions({ types: ['(cities)'] });
    }
  }
  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
