import { AppSettings } from './../../app.settings';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { UiService } from './../../services/ui.service';
import { UserCommonService } from './../../services/user-common.service';
import { StorageService } from './../../services/storage.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Message } from 'primeng/components/common/api';

@Component({
  selector: 'app-otp-verifiy',
  templateUrl: './otp-verifiy.component.html'
})
export class OtpVerifiyComponent implements OnInit {
  @Input() email;
  @Input() phone;


  @Output() onOtpPopClose: EventEmitter<any> = new EventEmitter();
  @Output() onOtpPopSave: EventEmitter<any> = new EventEmitter();

  msg: Message[] = [];
  otpForm = new FormGroup({
    otpInput: new FormControl()
  });

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private commonServices: UserCommonService,
    private uiSerice: UiService,
    private commonBindingDataService: CommonBindingDataService,
  ) { }


  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.required]],
    });
  }

  closeEditForgot(event) {
    this.otpForm.reset();
    this.onOtpPopClose.emit();
  }

  changePassword(event) {
    this.msg = [];
    let input;
    const otpInput = this.otpForm.controls.otpInput.value;
    input = {
      otp: otpInput,
      email: this.email
    };

    this.commonServices.validateOtp(JSON.stringify(input)).subscribe(results => {
      this.onOtpPopSave.emit(results);
      this.otpForm.reset();
    }, (error) => {

      this.dispalyMessage(error, 'error');
    });
  }

  dispalyMessage(msgObj, msgType) {
    this.msg = [];
    this.msg.push({
      severity: msgType,
      summary: 'Error',
      detail: msgObj.general[0].message
    });
  }

}
