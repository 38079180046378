import { Component, OnInit, Input, NgZone, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { ErrorBindingService } from './../../services/error-binding.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { UserCommonService } from './../../services/user-common.service';
import { AppSettings } from '../../app.settings';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  errorMessage: Message[] = [];
  @Input() changePasswordPopup: boolean;
  @Output() isShowChangePassword: EventEmitter<any> = new EventEmitter();
  inputType = 'password';
  cnfinputType = 'password';
  oldInputType = 'password';
  changePasswordErrors = [
    {
      oldPassword: false,
      newPassword: false,
      newConfirmPassword: false,
    }
  ];

  chagePasswordForm = new FormGroup({
    oldPassword: new FormControl(),
    newPassword: new FormControl(),
    newConfirmPassword: new FormControl()
  });

  constructor(
    private formBuilder: FormBuilder,
    private signOutService: UserCommonService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService
  ) { }

  ngOnInit() {
    this.chagePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required,Validators.pattern(AppSettings.ONLY_SPACE_NOT_ALLOWED_PATTERN)]],
      newPassword: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(AppSettings.PASSWORD_VALIDATIONS)]],
      newConfirmPassword: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(AppSettings.PASSWORD_VALIDATIONS)]]
    });
  }

  passwordNotMatched() {
    if (this.chagePasswordForm.controls.newPassword.value !== this.chagePasswordForm.controls.newConfirmPassword.value) {
      const error = {
        general: [
          {
            message: this.commonService.getLabel('error_new_confirm_password_not_match')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
    } else {
      return true;
    }
  }

  requestData() {
    return {
      oldPassword: this.commonService.encrypt(this.chagePasswordForm.controls.oldPassword.value),
      newPassword: this.commonService.encrypt(this.chagePasswordForm.controls.newPassword.value),
    };
  }   
  doChangePassword() {
    const passwordVerified = this.passwordNotMatched();
    if (passwordVerified) {
      const data = this.requestData();
      const c = this;
      this.signOutService.passwordChange(JSON.stringify(data)).subscribe(results => {
        if (results.general !== null && results.general !== undefined) {
          results.general.forEach(element => {
            this.errorMessage = [];
            this.errorMessage.push({ severity: 'success', summary: 'Success', detail: element.message });
          });
          setTimeout(() => {
            this.chagePasswordForm.reset();
            this.isShowChangePassword.emit(false);
          }, 2000);
        }
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
      });
    }

  }

  onCancel() {
    this.chagePasswordForm.reset();
    this.isShowChangePassword.emit(false);
  }

  tooglePasswordType() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }

  toogleCnfPasswordType() {
    if (this.cnfinputType === 'password') {
      this.cnfinputType = 'text';
    } else {
      this.cnfinputType = 'password';
    }
  }

  toogleOldPasswordType() {
    if (this.oldInputType === 'password') {
      this.oldInputType = 'text';
    } else {
      this.oldInputType = 'password';
    }
  }

}
