import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorBindingService } from 'app/modules/shared/services/error-binding.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';
import { AppSettings } from '../../modules/shared/app.settings';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  usernamePlaceholder;
  passwordPlaceHolder;
  signInLabel;
  signInLabelWithSSO;
  @Output() onSignInEmitter: EventEmitter<any> = new EventEmitter();
  @Output() onForgotPasswordEmitter: EventEmitter<any> = new EventEmitter();
  passwordType = 'password';

  signInForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl()
  });

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private commonBindingDataService: CommonBindingDataService,
    public userCommonService: UserCommonService,
    private errorBindingService: ErrorBindingService,
    private router: Router,
    private storageService: StorageService,




  ) { }

  ngOnInit() {
    this.usernamePlaceholder = this.commonBindingDataService.getLabel('Username');
    this.passwordPlaceHolder = this.commonBindingDataService.getLabel('Password');
    this.signInLabel = this.commonBindingDataService.getLabel('label_login');
    this.signInLabelWithSSO = this.commonBindingDataService.getLabel('label_login_sso');
    this.signInForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(50)]],
      // password: ['', [Validators.required,Validators.pattern(AppSettings.PASSWORD_VALIDATIONS)]],
      password: ['', [Validators.required]],

    });
  }

  doSignIn() {
    // this.commonBindingDataService.checkLoginUser.next('adminUser');
    this.storageService.setSessionStorage('loggedInUserType', 'adminUser');

    this.onSignInEmitter.emit(this.getLoginObject());
  }

  doSignInWithSSO() {
    //this.onSignInEmitter.emit(this.getLoginObjectForSSO());
    // this.commonBindingDataService.checkLoginUser.next('SSOUser');
    this.storageService.setSessionStorage('loggedInUserType', 'ssoUser');

    this.getLoginObjectForSSO();
  }

  getLoginObject() {
    const userName = this.signInForm.controls.username.value;
    let signInData = {};
    if (this.signInForm.controls.username.errors === null) {
      signInData = {
        'username': this.signInForm.controls.username.value,
        'password': this.commonBindingDataService.encrypt(this.signInForm.controls.password.value),
        'tenantId': AppSettings.TENANT
      };
    } else {
      signInData = {
        'username': this.signInForm.controls.username.value,
        'password': this.commonBindingDataService.encrypt(this.signInForm.controls.password.value),
        'tenantId': AppSettings.TENANT
      };
    }


    return signInData;
  }

  getLoginObjectForSSO() {
    window.location.href = `${AppSettings.BASE_URL}/${AppSettings.TENANT}/saml/ssologin`
  }

  onForgotPassword() {
    this.onForgotPasswordEmitter.emit();
  }

  eventHandler(event) {
    if (event.keyCode === 13) {
      if (this.signInForm.controls.username.errors) {
        setTimeout(() => {
          const element = this.renderer.selectRootElement('#username');
          element.focus();
        }, 500);
      } else if (this.signInForm.controls.password.errors) {
        setTimeout(() => {
          const element = this.renderer.selectRootElement('#password');
          element.focus();
        }, 500);
      } else {
        this.onSignInEmitter.emit(this.getLoginObject());
      }
    }
  }

  tooglePasswordType() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

}
