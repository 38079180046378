import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { ActivatedRoute } from '@angular/router';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';



@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicyData: any;
  bookingId;
  locationDetails: any = null;

  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private userCommonService: UserCommonService,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.bookingId = this.route.snapshot.queryParams['id'];

    this.commonBindingDataService.getPrivacyPolicy().subscribe((result) => {
      this.privacyPolicyData = result;
    }, (reason) => {
      // this.errorMessage = 'The page you are looking is not exist. Please contact to your Admin.';
    });

  }
}
