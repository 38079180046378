import { HttpClientModule } from '@angular/common/http';
// import * as moment from 'moment';
import { AppComponent } from './app.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
// import { Directive, ElementRef, Input, Renderer } from '@angular/core';
import { InputTextModule } from 'primeng/components/inputtext/inputtext';
import { StorageService } from './modules/shared/services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './modules/shared/components/loader/loader.service';
// import { TranslateService, TranslatePipe, TranslateDirective } from '@ngx-translate/core';

import { EditorModule } from 'primeng/components/editor/editor';
import { PanelModule } from 'primeng/components/panel/panel';
import { GrowlModule } from 'primeng/components/growl/growl';
import { DialogModule } from 'primeng/components/dialog/dialog';
import { ButtonModule } from 'primeng/components/button/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/components/common/api';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { FieldsetModule } from 'primeng/components/fieldset/fieldset';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { MultiSelectModule } from 'primeng/components/multiselect/multiselect';
import { ToastModule } from 'primeng/components/toast/toast';
import { OverlayPanelModule } from 'primeng/components/overlaypanel/overlaypanel';
import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordModule } from 'primeng/components/password/password';
import { MessageService } from 'primeng/components/common/messageservice';
import { PaymentStripComponent } from './modules/payment-strip/payment-strip.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CommonBindingDataService } from './modules/shared/services/common-binding-data.service';
import { ExportUserDetailsComponent } from './pages/export-user-details/export-user-details.component';
import { SupportComponent } from './pages/support/support.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    TrackMyLocationComponent,
    LoginComponent,
    PaymentStripComponent,
    PrivacyPolicyComponent,
    ExportUserDetailsComponent,
    SupportComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    InputTextModule,
    EditorModule,
    DialogModule,
    ButtonModule,
    GrowlModule,
    MultiSelectModule,
    AppRoutingModule,
    FieldsetModule,
    OverlayPanelModule,
    CalendarModule,
    FileUploadModule,
    ConfirmDialogModule,
    ToastModule,
    PasswordModule,
    SharedModule.forRoot()
  ],
  providers: [StorageService, ConfirmationService, LoaderService, NotificationsService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule
    };
  }
  constructor(private title: Title,
    private commonBindingDataService: CommonBindingDataService) {
    this.title.setTitle(this.commonBindingDataService.getLabel('title_common'));
  }
}
