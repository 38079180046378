import { NotificationsService } from './notifications.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from './storage.service';
import { AppSettings } from './../app.settings';
@Injectable()
export class AuthenticationValidationService implements CanActivate {

  constructor(private storageService: StorageService, private router: Router, private notificationService: NotificationsService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.storageService.getItemFromSessionStorage(AppSettings.TOKEN_KEY)) {
      this.notificationService.canFetchNotification = true;
      return true;
    }
    this.router.navigate(['/signin']);
    this.notificationService.canFetchNotification = false;
    return false;
  }
}
