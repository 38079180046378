import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {

      getTranslation(lang: string): Observable<any> {
            return of({
                  Username: 'Email',
                  Password: 'Password',
                  label_fullname: 'Fullname',

                  label_password_input: 'Password',
                  label_username: 'Username',
                  error_password_required: 'Password is required.',
                  exceed_max_length: 'Max length is exceed.',
                  password_too_short: 'Password is too short.',
                  comp_name: 'Company Name',
                  label_sign_in: 'Sign In',
                  label_login: 'Login',
                  label_login_sso: 'Login with SSO',
                  label_login_id: 'Login Id',
                  err_invalid_login_id: 'Invalid login id.',
                  err_invalid_user_name: 'Invalid username.',
                  err_login_id_required: 'Login id is required.',
                  label_sign_in_welcome_text: 'Welcome to the transport evolution.',
                  label_sign_in_description: 'Powerful dispatching, scheduling and journey management tool for event transportation',
                  label_read_more: 'Read More',
                  error_company_name_required: 'Company name is required.',
                  about_comp: 'About Company',
                  label_company_website: 'Company Website',
                  error_invalid_company_website: 'Invalid company website.',
                  error_required_company_website: 'Company website is required.',
                  error_invalid_website_url: 'Invalid website URL.',
                  err_username_required: 'Username is required.',
                  err_invalid_username: 'Invalid Username.',
                  err_userName_required: 'User Name is required.',
                  err_invalid_userName: 'Invalid User Name.',
                  label_company_location: 'Company Location',
                  address: 'Address',
                  address_col: 'Address :',
                  address_type: 'Address Type',
                  error_address_type_required: 'Address Type required.',
                  error_limit_address_type_required: 'Address type must not exceed 20 characters.',
                  label_address_type: 'Enter address type (eg. Hotel / Residence / Other).',

                  rating_col: 'Rating :',
                  noOfBookings: 'No. of Bookings :',
                  address_line1: 'Address line 1',
                  error_address_1_required: 'Address line 1 is required.',
                  address_line2: 'Address line 2',
                  label_gender: 'Gender',
                  label_date_of_birth: 'Date of birth',
                  web: 'Website',
                  phone: 'Phone Number',
                  fax: 'Fax',
                  error_invalid_fax: 'Fax number is invalid.',
                  error_max_length_excced: 'Character max length exceed.',
                  invalid: 'Invalid amount.',
                  label_amount: 'Amount',
                  invalid_hours: 'Invalid hours.',
                  invalid_package: 'Invalid route name.',
                  package: 'Route name required.',
                  hours: 'Hours required.',
                  cancellation: 'Cancellation fee required.',
                  add_hours: 'Additional hours required.',
                  addKm: 'Additional KM. required.',
                  base_fare: 'Base fare required.',
                  driver_percentage_req: 'Driver percentage is required',
                  distance_amount: 'Invalid distance amount',
                  distance_invalid: 'Invalid distance',
                  category: 'Category type is required.',
                  service: 'Service type is required.',
                  distance: 'Distance required.',
                  distance_fare: 'Distance fare required.',
                  wait_fare: 'Wait time fare required.',
                  ride_fare: 'Ride time fare required.',
                  vehicle_type_category_require: 'Vehicle category is required.',
                  hours_time: 'Hours',
                  mins: 'Minutes',
                  secs: 'Seconds',
                  route_fare: 'Fare required.',
                  route_radius: 'Radius required.',
                  route_radius_src: 'Radius required.',
                  route_dest: 'Destination required.',
                  pickup_time_validation: 'Please select valid time',
                  add_destination_validation: 'Please add Destination',
                  please_add_value_to_settings: 'Please add time, 0 is not allowed',
                  atleast_one_destination: 'Atleast have one Destination',
                  pickup_location_validation_msg: 'Please select proper pickup location',
                  pickup_location: 'Pickup location required.',
                  car_type_req: 'Car type Required.',
                  hotel_req: 'Hotel Required',
                  quotation_amount_req: 'Quotation amount required',
                  route_pattern: 'Use numbers only.',
                  amount_pattern: 'Use numbers with a decimal only',
                  route_length_src: 'Range 0.1 to 1 KM.',
                  route_pattern_src: 'Use numbers only.',
                  invalid_number: 'Invalid Number',
                  f_name: 'First Name',
                  f_name_enter: 'Enter first name',
                  l_name: 'Last Name',
                  l_name_enter: 'Enter last name',
                  passenger_f_name: 'Passenger first Name',
                  passenger_l_name: 'Passenger last Name',
                  search_passenger: 'Search Passenger',
                  email: 'Email ID',
                  label_document: 'Document',
                  document_type: 'Document type is required.',
                  docRegNo: 'Registration No is required.',
                  reg_num_required: 'Registration number is required.',
                  error_document_id_required: 'Document ID is required.',
                  error_invalid_document_id: 'Invalid document id.',
                  label_create_dept: 'Create Department',
                  label_edit_dept: 'Edit Department',
                  regId: 'Document ID',
                  min_length: 'Minimum 3 characters required.',
                  max_length: 'Exceeded character limit.',
                  min_length_username: 'Minimum 6 characters required.',
                  max_length_username: 'Exceeded 10 characters limit.',
                  max_length_login_id: 'Exceeded 12 characters limit.',
                  max_length_driver_login_id: 'Exceeded 20 characters limit.',
                  max_length_driver_login_id_50: 'Exceeded 50 characters limit.',
                  min_length_one: 'Minimum 1 characters required.',
                  issue_date: 'Date of issue is required.',
                  expiry_date: 'Date of expiry is required.',
                  start_date: 'Start date is required.',
                  end_date: 'End date is required.',
                  document: 'Document required.',
                  id_num: 'ID Num is required.',
                  driver_name: 'Driver name is required.',
                  name_pattern: 'Invalid name',
                  name_event_pattern: 'Invalid event name',
                  note_pattern: 'Invalid note',
                  invalid_instruction: 'Invalid instruction',
                  invalid_address_type: 'Invalid address type',
                  pattern_alphabets: 'Use only alphabets.',
                  phone_invalid: 'Enter a valid phone number.',
                  phone_invalid_enter: 'Enter phone number',
                  phone_email_enter: 'Enter email',

                  number_pattern: 'Use numbers only.',
                  fax_pattern: 'Invalid fax number',
                  email_required: ' E-mail is required.',
                  email_pattern: 'E-mail ID is invalid.',
                  address_req: 'Address is required.',
                  country_req: 'Country is required.',
                  state_req: 'State is required.',
                  company_name: 'Company name is required.',
                  city_req: 'City is required.',
                  city_pattern: 'Invalid city',
                  approval_options: 'Select approval option',
                  zipcode_req: 'Postal code is required.',
                  zipcode_invalid: 'Invalid postal code.',
                  transmission_type: 'Transmission type required.',
                  invalid_staff_transport_name: 'Invalid name',
                  contact_person_name: 'Contact person name is required.',
                  label_contact_number: 'Contact Number',
                  contact_number_require: 'Phone number is required.',
                  comp_name_required: 'Company name required.',
                  about_comp_required: 'About company required.',
                  website_required: 'Website required.',
                  phone_required: 'Phone number required.',
                  min_length_phone_number: 'Phone number should contain minimum 7 digit.',
                  max_length_phone_number: 'Phone number should contain maximum 13 digit.',
                  fax_required: 'Fax number required.',
                  invalid_site: 'Invalid URL',
                  service_type: 'Service Type',
                  user_type: 'User Type',
                  label_service_area: 'Service Area',
                  category_t: 'Vehicle Type',
                  vehicle_no: 'Vehicle No',
                  vehicle_details: 'Vehicle Make And Model',
                  vehicle_details_req: 'Required vehicle make and model',
                  vehicle_make: 'Vehicle Make',
                  error_vehicle_make_required: 'Vehicle make is required.',
                  model_yr: 'Model year',
                  capacity: 'Capacity',
                  dev_id: 'Device Id',
                  condition: 'Vehicle Condition',
                  reg_no: 'Registration No',
                  date_issue: 'Date Of Issue',
                  date_expiry: 'Date of Expiry',
                  label_expired: 'Expired',
                  label_expired_trips: 'Expired Journeys',
                  updated: 'Updated On',
                  question_req: 'Question required.',
                  answer_req: 'Answer required.',
                  hotel_name: 'Hotel name required.',
                  dispatcher_req: 'Dispatcher required ',
                  corporate_name: 'Please enter corporate name.',
                  corporate_code: 'Please enter corporate code.',
                  name_invalid: 'Please enter valid name',
                  corporate_contact_person_name: 'Please enter corporate person name.',
                  corporateCareExecutive: 'Please select a care executive.',
                  car_type: 'Car type required.',
                  drop_location: 'Drop location required.',
                  booking_date: 'Pickup date required.',
                  passenger_name: 'Passenger name required',
                  Package_req: 'Package required',
                  cmm_req: 'Car make & model required.',
                  service_type_req: 'Service Type required',
                  max_address: 'maximum 100 characters allowed',
                  note_req: 'Charge code required',
                  charge_req: 'Note required',
                  deptAdmin_req: 'Assign Department Admin required',
                  name_req: 'Name required',
                  empId_req: 'Employee id required',
                  empId_pattern: 'Invalid Employee id',
                  department_req: 'Department required',
                  message_passenger_not_assigned: 'Passenger data is not found.',
                  message_driver_not_assigned: 'Driver not assigned',
                  label_date_time: 'Date & time : ',
                  label_date_time_list: 'Date & time',
                  label_name: 'Name',
                  label_phone: 'Phone',
                  label_phone_no: 'Phone',
                  label_phone_number: 'Phone Number',
                  label_successful_trip: 'Successful Journeys',
                  label_distance_travelled: 'Distance Travelled (Km)',
                  label_total_trip_amount: 'Total Journey Amount',
                  label_credit: 'Credit',
                  label_duty_time: 'Duty Time',
                  label_duty_status: 'Status',
                  label_average_rating: 'Average Rating',
                  label_email: 'Email',
                  label_email_id: 'Email',
                  error_email_id_required: 'Email is required',
                  label_driver_details_header: 'Driver Details',
                  label_driver_bookings: 'Driver Bookings',
                  label_passenger_bookings: 'Passenger Bookings',
                  error_about_us_required: 'About us is required.',
                  label_passenger_details_header: 'Passenger Details',
                  label_status: 'Status',
                  label_rental_docs_uploaded: 'Rental Documents Uploaded',
                  label_hotel_booking: 'Hotel Bookings',
                  label_room_num: 'Room No',
                  label_quotation_amount_accepted: 'Accepted Quotation Amount',
                  lable_room_no_req: 'Please Enter Room No.',
                  label_booking: 'Bookings',
                  label_trip_id: 'Journey Id:',
                  label_trip_id_text: 'Journey Id',
                  label_service_type: 'Service Type',
                  label_service_id: 'Service Type:',
                  label_pickup: 'Pickup',
                  label_hotel_succuss: 'Hotel added successfully.',
                  label_pickup_date_time_sm_up: 'Pickup Date & Time:',
                  label_pickup_date_time: 'PickUp Date & Time:',
                  label_pickup_date_time_without_col: 'PickUp Date & Time',
                  label_drop_date_time_without_col: 'Drop Date & Time',
                  label_vehicle_group_name: 'Group Name',
                  label_vehicle_category: 'Vehicle Category:',
                  label_vehicle_type: 'Vehicle Type',
                  label_include_non_checked_in: 'Include Non Check-in Drivers',
                  label_vehicle_type_name: 'Vehicle Name',
                  label_category_name: 'Category Name',
                  label_category_description: 'Category Description',
                  error_category_description: 'Category Description is required.',
                  label_enter_vehicle_name: 'Enter Vehicle Name',
                  label_vehicle_type_header: 'Vehicle Type',
                  error_vehicle_type_required: 'Vehicle type is required',
                  error_vehicle_name_required: 'Vehicle name is required',
                  error_languageCode_required: 'Language is required',
                  error_category_name_required: 'Category name is required.',
                  error_decription_required: 'Description is required.',
                  error_vehicle_list_required: 'Vehicle list is required',
                  label_vehicle_icon_header: 'Vehicle Icon',
                  label_vehicle_type_text: 'Vehicle type',
                  label_select_language: 'Select Language',
                  noOfPackages: 'No. of Packages',
                  label_add_vehicle_type: 'Add Vehicle Type',
                  label_edit_vehicle_type: 'Edit Vehicle Type',
                  label_change_vehicle_name: 'Change Vehicle Name',
                  label_add_vehicle_category: 'Add Vehicle Type Category',
                  label_edit_vehicle_category: 'Edit Vehicle Type Category',
                  label_no_of_passenger: `No. of Passenger's`,
                  label_view_note_header: 'View Note',
                  label_package: 'Package:',
                  error_invalid_date: 'Invalid Date & time',
                  label_km: 'Km',
                  label_hr: 'Hr',
                  label_mins: 'Mins',
                  label_previous_quotation: 'Previous Quotations',
                  label_service_type_header: 'Service Type',
                  label_quoation_amount: 'Quotation amount',
                  label_quoation_note: 'Note',
                  label_qoutation_reason_for_cancel: 'Reason',
                  label_qoutation_fare_estimate: 'Fare Estimate',
                  error_quotation_amount: 'please enter quotation amount',
                  invalid_amount: 'Invalid amount',
                  error_quotation_note: 'Please enter quotation note',
                  error_quotatin_file: 'please upload quotation',
                  forgot_password: 'Forgot Password?',
                  select_area: 'Select Area',
                  label_car_type: 'Car Type',
                  label_car_make_model: 'Car make & model',
                  label_pickup_location: 'Pickup Location',
                  label_drop_location: 'Drop Location',
                  label_drop_off_location: 'Drop Off Location',
                  file_upload_error: 'Entity is too large to upload',
                  room_num_max_length: 'Max. 9 character allowed',
                  label_passenger_name: 'Passenger Name',
                  label_mobile_no: 'Phone Number',
                  label_phone_numb: 'Phone No.',
                  label_driver_mobile_no: 'Driver Mobile No',
                  label_alternate_number: 'Alternate Number',
                  label_assign_driver: 'Assign Driver',
                  label_assign_drivers: 'Assign Drivers',
                  label_note: 'Note',
                  label_incident: 'Incident Type',
                  label_packages: 'Packages',
                  label_CMM: 'Make',
                  label_No_Passenger: 'No Passenger',
                  label_from_date: 'From Date',
                  label_from_date_filter: 'From date',
                  label_shift_report: 'From Date',
                  error_from_date_required: 'From date is required.',
                  error_date_required: 'Date is required.',
                  error_time_required: 'Time is required.',
                  label_start_date: 'Start Date',
                  label_passenger_registered_with: 'Passenger Registered with',
                  label_to_date: 'To Date',
                  label_to_date_filter: 'To date',
                  error_to_date_required: 'To date is required.',
                  label_end_date: 'End Date',
                  label_start_date_registration: 'Start Date (Registration)',
                  label_role: 'Role',
                  label_invalid_role: 'Invalid role, contact to server team',
                  error_invalid_access_group: 'No access group assigned to the user.',
                  error_fetch_meta_data: 'Error occured during fetching user meta data.',
                  label_ID_Num: 'ID Num.',
                  label_address_line_1: 'Address line 1',
                  label_address_line_2: 'Address line 2',
                  label_country: 'Country',
                  label_state: 'State',
                  label_city: 'City',
                  label_approval_by_admin: 'Approval by Admin',
                  label_zip_code: 'Postal Code',
                  label_PO_box: 'PO box',
                  label_assign_role: 'Assign Role',
                  label_No: 'No.',
                  label_read: 'READ',
                  label_select: 'Select',
                  label_none: 'None',
                  label_select_hotel: 'Select Hotel',
                  label_driver_name: 'Driver Name',
                  label_booking_type: 'Booking Type',
                  label_sub_booking_type: 'Sub Booking Type',
                  label_sub_booking_created_as: 'Created As',
                  label_ride_schedule_at: 'Ride Schedule At',
                  label_ride_now: 'Ride Now',
                  label_ride_later: 'Ride Later',
                  label_booking_service: 'Booking Service',
                  label_booking_services: 'Booking Services',
                  label_payment_mode: 'Payment Mode',
                  label_invoice_date: 'Invoice Date',
                  label_invoice_amount: 'Invoice Amount',
                  label_refund_date: 'Refund Date',
                  label_refund_amount: 'Refund Amount',
                  err_refund_exceeds_value: 'Refund value exceeds total amount',
                  label_passenger_type: 'Passenger type',
                  label_passenger_type_caps: 'Passenger Type',
                  label_completed_bookings: 'Completed Bookings',
                  label_cancelled_bookings: 'Cancelled Bookings',
                  label_cancelled_trips: 'Cancelled Journeys',
                  label_trip_expired: 'Journey Expired',
                  label_trip_edited_at: 'Edited At',
                  label_trip_edited_by: 'Edited By',
                  label_cancelled_trips_amount: 'Cancelled Journeys Amount',
                  label_active_bookings: 'Active Bookings',
                  label_total_amount: 'Total Amount',
                  label_payment_received: 'Payment Received',
                  label_payment_due: 'Payment Due',
                  label_booking_code: 'Booking Code',
                  label_booking_count: 'Booking Counts',
                  label_waiting_time: 'Waiting Time',
                  label_km_in: 'KMs In',
                  label_km_covered: ' KM Covered',
                  label_km_out: 'KMs Out',
                  label_total_vehicles: 'Total Vehicles',
                  label_taxi_drivers: 'Taxi Drivers',
                  label_rental_car_drivers: 'Rental Car Drivers',
                  label_hotel_dispatchers: 'Hotel Dispatchers',
                  label_corporate_dispatchers: 'Corporate Dispatchers',
                  label_no_of_bookings: 'No. of Bookings',
                  label_month: 'Month',
                  label_year: 'Year',
                  label_transmission_type: 'Transmission Type',
                  label_registration_num: 'Registration Num',
                  label_date_issue: 'Date of Issue ',
                  label_date_expiry: ' Date of Expiry ',
                  label_documents: 'Documents',
                  label_document_type: 'Document Type',
                  label_category: 'Category',
                  label_VM: 'Vehicle Make',
                  label_vehicle_name: 'Vehicle Name',
                  label_VM_name: 'Model Name',
                  error_model_name_required: 'Model name is required.',
                  error_invalid_model_year: 'Invalid model year.',
                  label_vehicle_no: 'Vehicle No.',
                  label_vehicle_reg_no: 'Vehicle Registration Number',
                  label_dummy_vehicle: 'Dummy Vehicle',
                  label_dummy_default_vehicle: 'Dummy Default Vehicle',
                  error_dummy_vehicle_required: 'Dummy vehicle is required.',
                  error_vehicle_reg_no_required: 'Vehicle number is required.',
                  label_model: 'Model',
                  label_model_year: 'Model Year',
                  label_manufactur_year: 'Year of manufacture',
                  label_capacity: 'Capacity',
                  label_vehicle_capacity_lbl: 'Vehicle Capacity',
                  label_car_condition: 'Car Condition',
                  label_device_id: 'Device ID',
                  category_req: ' Category Required',
                  vehicle_number_req: ' Vehicle Number Required.',
                  vehicle_number_pattern: 'Invalid vehicle number, accept only alphanumeric value.',
                  vehicle_model_year_req: 'Model Year Required.',
                  vehicle_model_year_pattern: 'Invalid Model Year.',
                  capacity_req: 'Capacity is Required.',
                  label_adddress_note: 'Address Note',
                  two_digit_max: 'Only 2 digits allowed.',
                  car_condition_req: 'Car Condition Required.',
                  device_id_req: 'Device ID Required.',
                  aplha_numeric_pattern: 'Use only alpha-numeric characters.',
                  label_dashboard: 'Dashboard',
                  label_dispatch: 'Dispatch',
                  label_sales_quotations: 'Sales Quotations',
                  label_Passenger_type: 'Type of Passengers',
                  label_toggle_menu: 'Toggle Menu',
                  label_Passengers: 'Passengers',
                  label_Dispatchers: 'Dispatchers',
                  label_Vehicles: 'Vehicles',
                  label_Drivers: 'Drivers',
                  label_Staff_Transport: 'Staff Transport',
                  label_hotel: 'Hotel',
                  label_Hotels: 'Hotels',
                  label_corporate: 'Corporate',
                  label_Corporates: 'Corporates',
                  label_private: 'Private',
                  label_App_Settings: 'App Settings',
                  label_Settings: 'Settings',
                  label_Reports: 'Reports',
                  label_region: 'Region',
                  label_reference_setting: 'Reference Setting',
                  label_regions: 'Regions',
                  label_taxes: 'Taxes',
                  admin_settings: 'Admin Settings',
                  label_add_region: 'Add Region',
                  label_edit_region: 'Edit Region',
                  label_title: 'title',
                  label_promo_title: 'Promo title',
                  lable_promo_title_require: 'Title is required',
                  lable_promo_code_require: 'Code is required',
                  lable_promo_date_range_require: 'Date range is required',
                  lable_usage_require: 'Limit is required',
                  lable_usage_pattern: 'invalid limit',
                  lable_discount_require: 'Discount is required',
                  lable_max_discount_require: 'Max discount is required',
                  lable_discount_pattern: 'Invalid Discount',
                  lable_max_discount_pattern: 'Invalid max discount pattern',
                  label_promo_code: 'Promo Code',
                  label_all_taxes: 'All Taxes',
                  label_no_of_uses: 'No. of uses',
                  label_usage_limit: 'Usage Limit',
                  label_vehicle_types: 'Vehicle Types',
                  label_per_user_usage: 'Usage Limit per User',
                  label_add_promo_code: 'Add Promo Code',
                  label_update_promo_code: 'Edit Promo Code',
                  label_date_range: 'Date range',
                  label_usage: 'Usage',
                  label_used: 'Used',
                  label_usage_count: 'Usage Count',
                  label_limit_per_user: 'Limit per user',
                  label_mode: 'Mode',
                  label_discount: 'Discount',
                  label_max_discount: 'Max Discount',
                  label_time_settings: 'Time Settings',
                  label_Pricing: 'Pricing',
                  label_Routes: 'Routes',
                  label_edit: 'Edit',
                  label_update: 'Update',
                  label_delete: 'Delete',
                  label_add: 'Add',
                  label_package_name: 'Package Name',
                  label_base_fare: 'Base Fare',
                  error_base_fare_required: 'Base fare is required.',
                  error_fare_required: 'Fare is required.',

                  error_invalid_base_fare: 'Invalid base fare.',
                  label_distance: 'Distance',
                  label_distance_fare: 'Additional Distance Fare Per KM',
                  label_wait_time_fare: 'Wait Time Fare',
                  label_wait_time_fare_mins: 'Wait Time Fare (Minutes)',
                  label_ride_time_fare: 'Ride Time Fare',
                  error_ride_time_fare_required: 'Ride time fare is required.',
                  error_invalid_ride_time_fare: 'Invalid ride time fare.',
                  label_cancellation_fee: 'Passenger Cancellation Charges',
                  label_actions: 'Actions',
                  label_action: 'Action',
                  label_details: 'Details',
                  label_additional_hours: 'Additional Rate Per Hour',
                  label_hours: 'Hours',
                  label_additional_KM: 'Additional Rate Per KM ',
                  label_category_type: 'Vehicle type',
                  label_distance_unit: 'Distance (KM)',
                  label_distance_for_base_fare: 'Distance (KMs) For Base Fare',
                  label_send: 'Send',
                  label_save: 'Save',
                  label_book: 'Book',
                  label_cancel: 'Cancel',
                  please_select: 'Please Select',
                  label_all_cities: 'All Cities',
                  label_route_name: 'Route Name',
                  label_users: 'Users',
                  label_users_many: 'User(s)',
                  label_radius: 'Radius',
                  label_radius_m: 'Radius(meters)',
                  label_radius_unit: 'Radius(KM)',
                  label_no_routes: 'No. of Routes',
                  label_updated_on: 'Updated On',
                  label_Radius_Meters: 'Radius (Meters)',
                  label_source: 'Source',
                  label_destination: 'Destination',
                  label_add_destination: 'Add Destination',
                  label_fare: 'Fare',
                  label_fares: 'Fares',
                  label_add_route: 'Add Route',
                  label_update_route: 'Edit Route',
                  label_add_packages: 'Add Packages',
                  label_update_packages: 'Update Packages',
                  label_FAQ: 'FAQ',
                  label_description: 'Description',
                  label_invalid_description: 'Invalid Description',
                  label_terms_conditions: 'Terms & Conditions',
                  label_privacy_policy: 'Privacy Policy',
                  label_default: 'Default Vehicle',
                  label_view_all: 'View All',
                  label_assign_vehicle: 'Assign Vehicle',
                  label_please_select: 'Please Select',
                  label_this_vehicle: 'this vehicle?',
                  label_this_driver: ' this Driver?',
                  label_this_passenger: ' this passenger?',
                  label_this_event: ' this event?',
                  label_this_manual: 'this manual?',
                  msg_default_vehicle_confirmation: `Are you sure do you really want to assign this vehicle?
      If this vehicle is already assigned to another driver then it will be removed from that driver.`,
                  msg_reset_password_link_send_confirmation_driver: `Are you sure, you want to send reset-password link 
      on the registered email of the driver to reset their password`,
                  msg_reset_password_link_send_confirmation_user: `Are you sure, you want to send reset-password link 
      on the registered email of the user to reset their password`,
                  label_assign_default_vehicle: 'Assign Default vehicle',
                  label_rental_privacy_policy: 'Rental Policy',
                  label_allocated_vehicles: 'Allocated Vehicles',
                  label_chauffeur_privacy_policy: 'Chauffeur Policy',
                  label_password_match: 'password does not match',
                  label_docs_validation: 'Add atleast one document',
                  label_otp: 'Please enter OTP',
                  label_otp_msg: 'We will send One Time Password (OTP) on your registered email to reset password.',
                  label_current_km: 'Please enter Current KM',
                  label_enter: 'Please enter',
                  invalid_otp: 'Invalid OTP',
                  label_our_partners: 'Our Partners',
                  label_choose: 'Choose',
                  label_qusetion: 'Question',
                  label_answer: 'Answer',
                  label_clear: 'Clear',
                  label_clear_address: 'Clear Address',
                  label_promo_codes: 'Promo Codes',
                  label_roles: 'Roles',
                  label_add_role: 'Add Role',
                  label_edit_role: 'Edit Role',
                  label_role_detail: 'Role Details',
                  label_edit_term_Condition: 'Edit Terms & Conditions',
                  label_edit_privacy_policy: 'Edit Privacy Policy',
                  label_edit_rental_policy: 'Edit Rental Policy',
                  label_edit_chauffeur_policy: 'Edit Chauffeur Policy',
                  label_add_partner: 'Add Partner',
                  label_id: 'ID',
                  label_hash: '#',
                  label_corporate_info: 'Corporate Info',
                  label_contact_person: 'Contact Person',
                  label_department_count: 'Total Departments',
                  label_registration_Date: 'Registration Date',
                  label_total_bookings: 'Total Bookings',
                  label_deactivate: 'Deactivate',
                  label_activate_edit: 'Active & Edit',
                  label_activate: 'Activate',
                  label_Total: 'Total',
                  label_active: 'Active',
                  label_deactive: 'Deactive',
                  label_Active: 'active',
                  label_Deactive: 'deactive',
                  label_deactivate_confirm: 'deactivate',
                  label_activate_confirm: 'activate',
                  label_deactivated: 'Deactivated',
                  label_cancelled: 'Cancelled',
                  label_trip_cancelled: 'Journey Cancelled',
                  label_completed: 'Completed',
                  label_new: 'New',
                  label_regret: 'Regret',
                  label_corporate_information: 'Corporate Information',
                  label_package_pricing: 'Package Pricing',
                  lable_route_pricing: 'Route Pricing',
                  label_corporate_name: 'Corporate Name',
                  label_corporate_code: 'Corporate Code',
                  label_contact_person_name: 'Contact Person Name',
                  label_assign_corporate_executive: 'Assign Corporate Care Executive',
                  label_save_next: 'Save & Next',
                  label_save_quit: 'Save & Quit',
                  label_add_princing: 'Add Pricing',
                  label_back: 'Back',
                  label_finish: 'Finish',
                  label_next: 'Next',
                  label_quit: 'Quit',
                  label_additional_hours_fee: 'Additional Hours Rate',
                  label_additional_additional_information: 'Additional Information',

                  label_hotel_info: 'Hotel Info',
                  label_dispatcher_name: 'Dispatcher Name',
                  label_dispatcher_no: 'Dispatcher No',
                  label_hotel_name: 'Hotel Name',
                  label_dispatcher_details: 'Dispatcher Details',
                  label_hotel_information: 'Hotel Information',
                  label_current_location: 'Current Location',
                  labe_notifications_to: 'to',
                  label_notifications: 'Notifications',
                  label_no_notification_found: 'No more notification found.',
                  label_no_notification_list: 'No notification list is available.',
                  label_date_and_time: 'Date & time',
                  label_resend: 'Resend',
                  label_vehicle_info: 'Vehicle info',
                  label_vehicle_idriver_info: 'Vehicle & Driver info',

                  label_vehicle_count: 'Vehicle Count',
                  label_assign: 'Assign',
                  label_reassign: 'Reassign',
                  label_unassign: 'Unassign',
                  label_driver: 'Driver',
                  label_add_documents: 'Add Documents',
                  label_add_documents_details: 'Attached Documents Details',
                  label_extra_add_documents_details: 'Extra Attached Documents Details',
                  label_add_documents_details_by_driver: 'Attached Documents Details By Driver',
                  label_rating: 'Ratings',
                  label_num: 'Num.',
                  label_yes: 'Yes',
                  label_no: 'No',
                  label_all: 'All',
                  label_busy: 'Busy',
                  label_available: 'Available',
                  label_idle: 'Idle',
                  label_S_NO: 'S.NO',
                  label_vehicle: 'Vehicle',
                  error_vehicle_required: 'Vehicle is required.',
                  label_accesse_name: 'Accesses Name',
                  label_dispatcher: 'dispatcher',
                  label_trip_completed: 'Journeys Completed',
                  label_user_type: 'User Type',
                  label_vehicleNumber: 'Vehicle Number ',
                  label_driverID: 'Driver ID',
                  label_trip_date: 'Pickup Date & Time',
                  label_trip_amount: 'Journey Amount',
                  label_booking_date_time: 'Booking Date & Time',
                  label_trip_total: 'Journey fare',
                  label_BookingId: 'Booking Id',
                  label_trip_status: 'Journey Status',
                  label_tripID: 'Journey ID',
                  label_source_address: 'Source Address',
                  label_destination_address: 'Destination Address',
                  label_reset: 'Reset',
                  label_create_new: 'Create New',
                  label_add_new: 'Add New',
                  label_reset_filter: 'Reset Filter',
                  label_passenger: 'Passenger',
                  label_pickup_address_note: 'Pickup Address Note',
                  label_address_note: 'Address Note',
                  label_admin: 'Admin',
                  label_pickup_drop_location: 'Pickup & Drop location',
                  lable_pickup_date_time: 'Pickup Date',
                  label_booking_ID: 'Booking ID',
                  lable_upload_quote_pdf: 'Upload Quote (PDF)',
                  label_booking_Date: 'Booking Date',
                  label_upload_quote: 'Upload Quote',
                  label_start_trip: 'Start Journey',
                  label_end_trip_message: 'Journey is ended successfully',
                  label_extend_trip: 'Extend Journey',
                  label_end_trip: 'End Journey',
                  label_end_trip_amount: 'End Journey Amount',
                  label_end_trips: 'End Journeys',
                  label_cancel_trip: 'Cancel Journey',
                  label_security_check: 'Security Check',
                  label_delivery_check: 'Delivery Check',
                  label_car_return_check: 'Car Return Check',
                  label_return_security_check: 'Return Security Check',
                  label_resend_quote: 'Resend Quote',
                  label_taxi: 'Taxi',
                  label_rental: 'Rental',
                  label_rental_car: 'Rental Car',
                  label_chauffeur: 'Chauffeur',
                  label_booking_status: 'Booking Status',
                  label_payment_status: 'Payment Status',
                  label_search: 'Enter text to search..',
                  label_charge_code: 'Charge Code',
                  label_department: 'Department',
                  label_department_admin: 'Department Admin',
                  lable_employeeID: 'Employee ID',
                  label_active_trips: 'Active Journeys',
                  label_on_trips: 'On Journey',
                  label_on: 'ON',
                  label_off: 'OFF',
                  label_assign_department: 'Assign Department Admin',
                  lable_department_name: 'Department Name',
                  lable_employee_name: 'Employee Name',
                  label_no_trips: 'No. of Journeys',
                  label_view_details: 'View Details',
                  label_mark_vip: 'Mark As VIP',
                  label_approve: 'Approve',
                  label_reject: 'Reject',
                  label_pending_approval: 'Pending for Approval',
                  label_manage_employee: 'Manage Employees',
                  label_manage_departments: 'Manage Departments',
                  label_pricing_details: 'Pricing Details',
                  label_taxi_service: 'Taxi Service',
                  label_rental_car_service: 'Rental Car Service',
                  label_chauffeur_service: 'Chauffeur Service',
                  label_start_end_date: 'Start-End Date',
                  labee_pickup_time: 'Pickup Time',
                  label_staff_transport: 'staff-transport',
                  label_day: 'Days',
                  label_Assign_vehicle: 'Assign Vehicle',
                  label_View_Assign_vehicle: 'View Assign Vehicle',
                  label_total_fare: 'Total Fare (TNZ)',
                  label_holiday: 'Holidays',
                  label_another_drop_location: 'Another Drop Location',
                  label_type: 'Type',
                  label_view_route: 'View Route info',
                  label_route_information: 'Route Information',
                  label_renew_package: 'Renew Package',
                  label_track_driver: 'Track Driver',
                  label_car_no: 'Car No',
                  charge_code_maxlength: 'Allowed maximum 4 character',
                  label_Cancelation: 'Cancellation Reasons',
                  label_cancellation_reason: 'Cancellation Reason',
                  label_booking_cancel: 'Cancel Booking',
                  label_rating_comments: 'Rating & Comments',
                  label_reply: 'Reply',
                  label_cancel_reason: 'Cancel Reason',
                  label_reason: 'Reason',
                  label_trip_history: 'Journey History',
                  label_forgot_password: 'Forgot Password',
                  label_forgot: 'User Name/ Email /Phone No.',
                  label_validate_otp: 'Validate OTP',
                  cancel_reason_req: 'Cancel Reason Required.',
                  label_cancel_booking: 'Cancel Booking',
                  label_country_code: 'Country Code',
                  country_code_required: 'Country code is required',
                  country_code_invalid: 'Country code is invalid',
                  booking_details_result: 'No booking record found.',
                  booking_comment_details: 'No comment',
                  comment_heading: 'Rating & Comments',
                  label_driver_type: 'Driver Type',
                  label_confirm_extend_package: 'Do you really want to Extend Package?',
                  label_extend_package_confirmation_header: 'Extend Package Confirmation',
                  label_confirm_emp_confirm_booking_p1: 'Are you sure that you want to ',
                  lable_confirm_emp_confirm_booking_p2: ' this booking',
                  label_select_service_type: 'Select service type',
                  label_invalid_user: 'Invalid User Name/ Email /Phone No.',
                  label_driver_location: 'Driver Location',
                  label_report_driver_login_logout: 'Driver Login Logout',
                  label_report_driver_on_duty: 'Driver On Duty',
                  label_report_driver_income: 'Driver Income',
                  label_report_driver_mileage: ' Driver Mileage',
                  label_report_driver_performance: 'Driver Performance',
                  label_report_car_mileage: 'Car Mileage',
                  label_report_transction: 'Transaction',
                  label_report_refund: 'Refund',
                  label_report_promo_code: 'Promo Code',
                  label_report_referral_credit: 'Referral Credit',
                  label_report_passenger_feedback: 'Passenger Feedback',
                  label_report__feedback: 'Feedback',
                  label_mode_promo_code: 'Promo Code Mode',
                  label_mode_usage_type: 'Usage Type',
                  label_credit_amount: 'Credit Amount',
                  label_referred_on: 'Referred on',
                  label_clear_polygon: 'Clear Polygon',
                  label_region_name: 'Region Name',
                  error_region_name_required: 'Region Name is required.',
                  error_airport_region_name_required: 'Zone Name is required.',
                  label_alias_name: 'Alias Name',
                  error_alias_name_required: 'Alias Name is required.',
                  error_invalid_alias_name: 'Invalid alias name.',
                  error_airport_alias_name_required: 'Zone alias Name is required.',
                  label_sr_no: 'Sr No.',
                  label_view: 'View',
                  label_contact_us: 'Contact Us',
                  label_city_regions: 'City Regions',
                  label_city_fares: 'City Fares',
                  label_edit_fares: 'Edit Fares',
                  label_cities: 'Cities',
                  error_cities_required: 'City is required.',
                  label_estimated_fare: 'Estimated Fare',
                  file_size_limit: 'This file is too large. The maximum size allowed is 10MB',
                  vehicle_assigned_already: 'Are you sure do you really want to assign this Driver? He is already assigned to other vehicle',
                  driver_is_not_assigned: 'Driver is not assigned',
                  label_ride_type: 'Ride Type',
                  label_journey_type: 'Journey Type',
                  ride_type: 'Ride type is required',
                  payment_mode_required: 'Payment mode is required',
                  passenger_type_reqd: 'Passenger type is required',
                  driver_type_reqd: 'Driver type is required',
                  first_name_reqd: 'First name is required.',
                  last_name_reqd: 'Last name is required.',
                  journey_type_reqd: 'Journey type required.',

                  label_add_driver_in_queue: 'Add Driver In Queue',
                  label_add_driver: 'Add Driver',
                  label_update_driver: 'Edit Driver',
                  label_add_to_queue: 'Add To Queue',
                  label_remove_from_queue: 'Remove From Queue',
                  label_available_drivers: 'Available Drivers',
                  label_available_since: 'Available Since',
                  label_vehicle_assigneed: 'Vehicle Assigned',
                  label_shift_group: 'Shift Group',
                  label_shift_status: 'Shift Status',
                  label_queue_location: 'Queue Location',
                  label_added_on: 'Added On (Time)',
                  label_short_code: 'Short Code',
                  label_display_name_for_passenger: 'Display Name For Passenger',
                  label_location_category: 'Location Category',
                  label_driver_duty_on_off: 'Driver Duty ON / OFF',
                  label_privilege_settings: 'Privilege Settings',
                  label_privilege_level: 'Privilege Level',
                  label_driver_vehicles: 'Driver Vehicles',
                  label_activities: 'Activities',
                  label_user_information: 'User Information',
                  label_document_and_user_info: 'Document Details/Information',
                  label_additional_information: 'Additional Information',
                  label_license_number: 'License number',
                  error_address_required: 'Address is required.',
                  label_license_issued_date: 'License issued date',
                  label_license_expiry_date: 'License expiry date',
                  label_assigned_car: 'Assigned Car',
                  label_activity_logs: 'Activity logs',
                  label_home: 'Home',
                  label_Changed_profile_photo: 'Changed profile photo',
                  label_marked_in_queue_cfc: 'Marked in Queue (Q-CFC)',
                  label_finished_ride_of_neville_griffin: 'Finished ride of Neville Griffin',
                  label_create_new_passenger_type: 'Create New Passenger Type',
                  label_passenger_privilege_settings: 'Passenger Privilege Settings',
                  label_select_permissions: 'Select Permissions',
                  label_assign_locations: 'Assign Locations',
                  label_passenger_app: 'Passenger App',
                  label_per_jouerny: 'per journey',
                  label_radius_in_km: 'Radius in km',
                  label_radius_required: 'Radius is required.',
                  label_invalid_radius: 'Invalid radius.',
                  label_per_day: 'per day',
                  label_maximum_journey_count_per_day: 'Maximum Journey Count Per day',
                  label_maximum_passenger_count_per_journey: 'Maximum Passengers Count Per Journey',
                  label_maximum_number_of_journey_per_day: 'Maximum Number Of Journey Per Day',
                  label_maximum_wheelchair_allowed_per_journey: 'Maximum Wheelchair Allowed Per Journey',
                  label_maximum_childseat_allowed_per_journey: 'Maximum Childseat Allowed Per Journey',
                  label_maximum_babyseat_allowed_per_journey: 'Maximum Babyseat Allowed Per Journey',
                  error_maximum_passenger_count_per_journey: 'Maximum Passengers Count Per Journey is required.',
                  error_maximum_number_of_journey_per_day: 'Maximum Number Of Journey Per Day is required.',
                  error_maximum_wheelchair_allowed_per_journey: 'Maximum Wheelchair Allowed Per Journey is required.',
                  error_maximum_childseat_allowed_per_journey: 'Maximum Childseat Allowed Per Journey is required.',
                  error_maximum_babyseat_allowed_per_journey: 'Maximum Babyseat Allowed Per Journey is required.',

                  label_departure_journey: 'Departure Journey',
                  label_social_journey: 'Social Journey',
                  label_search_location: 'Search location',
                  label_click_here: 'Click here',
                  label_edit_privilege_settings: 'Edit Passenger Privilege Settings',
                  label_add_privilege_settings: 'Add Passenger Privilege Settings',
                  label_enter_email_address: 'Enter email address',
                  label_password: 'Enter password',
                  label_add_new_passenger: 'Add New Passenger',
                  label_passenger_note: 'Passenger Note',
                  label_general_information: 'General Information',
                  label_transportation_information: 'Transportation Information',
                  label_assign_driver_placehoder: 'Search driver by name or mobile Id (Press enter to search )',
                  label_passenger_search_placeholder: 'Search by name / barcode',
                  label_passenger_accreditation_search_placeholder: 'Search by name / accreditation id',
                  label_passenger_search_placeholder_list: 'Search by name / barcode ',
                  label_event_search_placeholder_list: 'Search by event name / event code ',
                  label_incidentscomplaints_search_placeholder_list: 'Search by category/ type/ raised by/ journey id ',
                  label_code: 'Code',
                  label_lacation_category: 'Location category',
                  label_prefered_for_player_coach: 'Preferred For',
                  label_google_address: 'Google address',
                  label_residential_postal_code: 'Zip Code',
                  label_location_display_address_for_booker: 'Location display name for booker',
                  label_location_display_name_for_passenger: 'Location display name for passenger',
                  label_location_short_code: 'Location short code',
                  title_location_display_name_for_passenger: 'Passenger friendly location name that will appear in passenger app',
                  title_location_display_address_for_booker: 'Display name for admin, this can be same as display name for passenger',
                  title_location_short_code: 'Short code to be displayed on admin portal',
                  title_location_aliase_name: 'This name will be displayed in the passenger application.',
                  title_zero_not_allow_at_start: '0 not allowed at first index',
                  label_location_pin: 'Location pin',
                  label_create_new_shift_group: 'Create New Shift Group',
                  label_update_shift_group: 'Edit Shift Group',
                  label_shift_group_details: 'Shift Group Details',
                  label_selected_drivers: 'Selected Drivers',
                  label_all_drivers: 'All Drivers',
                  label_add_new_driver: 'Add New Driver',
                  label_shift_group_name: 'Shift group name',
                  label_remove: 'Remove',
                  label_barcode: 'Barcode',
                  label_barcode_enter: 'Enter barcode',
                  label_playercode: 'Player Code',
                  label_playercode_placeholder: 'Enter player Code',

                  label_passenger_urn: 'Passenger URN',
                  label_person_urn: 'Person URN',
                  label_transport: 'Transport',
                  label_added_by: 'Added By',
                  label_apply: 'Apply',
                  label_press_enter_to_search: 'Press enter to search',
                  label_map_vehicle_search: 'Search vehicle',
                  label_queue_search_placeholder: 'Search by driver name / Accreditation ID',
                  label_shift_search_placeholder: 'Search by shift group',
                  // label_passenger_search_placeholder: 'Search by Name, Barcode, URN',
                  label_search_by_title_placeholder: 'Search by title',
                  label_passenger_addresses: 'Addresses',
                  label_journey_history: 'Journey History',
                  label_journey_id: 'Journey Id',
                  label_trip_distance: 'Journey Distance',
                  label_details_and_privileges: 'Details And Privileges',
                  label_group: 'Client Group',
                  label_group_id: 'Group Id',
                  label_job_title_code: 'Job Title Code',
                  label_job_title: 'Job Title',
                  label_event_code: 'Event Code',
                  label_event: 'Events',
                  label_incidents_complaints: 'Incidents & Complaints',
                  label_incidents: 'Incidents',
                  label_complaints: 'Complaints',
                  label_manage_incidents_complaints: 'Manage Incidents & Complaints',
                  label_create_new_event: 'Create New Event',
                  label_event_image: 'Event Image',
                  label_event_name: 'Event Name',
                  label_create_event: 'Create Event',
                  label_add_incident_compliant: 'Add New Incident / Complaint',
                  label_add_incident_compliant_bread: 'Add New Incident & Complaint',
                  label_edit_incident_compliant_bread: 'Edit Incident & Complaint',
                  label_incident_compliant_details: 'Incident / Complaint Details',
                  label_update_event: 'Update Event',
                  label_event_details: 'Event Details',

                  label_event_start_date: 'Event Start Date',
                  label_event_transportation_start_date: 'Event Start Date(Transportation Start Date)',
                  label_event_transportation_end_date: 'Event End Date (Transportation End Date)',
                  label_event_end_date: 'Event End Date',
                  label_event_description: 'Event Description',
                  label_event_short_code: 'Event Short Code',


                  label_accreditation_id: 'Accreditation ID',
                  label_invalid_accreditation_id: 'Invalid Accreditation ID',
                  label_accreditation_status: 'Accreditation Status',
                  error_accreditation_id: 'Invalid Accreditation ID.',
                  label_organization: 'Organization',
                  label_timestamp: 'Timestamp',
                  label_raise_alert: 'Raise Alert',
                  label_remove_alert: 'Remove Alert',
                  label_passenger_type_dup: 'Passenger type (dup)',
                  label_version: 'Version',
                  label_pass_status: 'Pass status',
                  label_valid_start_date: 'Valid start date',
                  label_passenger_urn_dup: 'Passenger URN (dup)',
                  label_refs_played_id: 'Refs player ID',
                  label_played_id: 'Player ID',
                  label_badge_description: 'Badge Description',
                  label_void_date: 'Void date',
                  label_maximum_passenger_count: 'Maximum passenger count',
                  label_maximum_number_of_journeys: 'Maximum number of journeys',
                  label_maximum_number_of_wheelchair: 'Maximum number of wheelchair',
                  label_maximum_number_of_childseat: 'Maximum number of childseat',
                  label_maximum_no_of_wheelchair: 'Maximum no. of wheelchair',
                  label_maximum_no_of_childseat: 'Maximum no of childseat',
                  label_stay_address: 'Stay Address',
                  label_stay_address_from_google: 'Stay Address(From Google)',
                  label_stay_addresses: 'Stay Addresses',
                  label_stay_address_type: 'Select Stay Address Type',
                  label_allowed_locations: 'Allowed Location',
                  label_instruction: 'Instruction',
                  label_instructions: 'Instructions',
                  label_location_on_map: 'Location on map',
                  label_call_sign: 'Radio Number',
                  label_wheelchair: 'Wheelchair',
                  label_wheelchair_support: 'Wheelchair support ',
                  label_wheelchair_support_form: 'Wheelchair support?',
                  error_only_space_is_not_allowd: 'Invalid input.',
                  error_invalid_vehicle_code: 'Invalid vehicle code, accept only alphanumeric value.',
                  error_invalid_mobile_id: 'Invalid mobile ID, accept only alphanumeric value.',
                  error_invalid_model_name: 'Invalid model name.',
                  error_wheelchair_support_required: 'Wheelchair support is required.',
                  err_this_field_is_mandatory: 'This field is mandatory.',
                  label_reg_no: 'Registration Number',
                  label_add_new_region: 'Add New Region',
                  label_about_us: 'About Us',
                  label_add_new_faq: 'Add New FAQ',
                  label_frequently_asked_questions: 'Frequently Asked Questions (FAQs)',
                  label_all_regions: 'All Regions',
                  label_applicable_to: 'Applicable To',
                  label_applicable_to_passenger: 'Applicable To Passenger',
                  label_driver_code_call_sign: 'Driver Code / Call Sign',

                  error_lacation_category_required: 'Location category is required.',
                  error_location_display_address_for_booker_required: 'Location display name for booker is required.',
                  error_invalid_location_display_address_for_booker: 'Invalid location display name for booker.',
                  error_select_address_from_google_suggestions_only: 'Please select address from google suggestion only.',
                  error_location_display_name_for_passenger_required: 'Location display name for passenger is required.',
                  error_invalid_location_display_name_for_passenger_required: 'Invalid location display name for passenger.',
                  error_location_short_code_required: 'Location short code is required.',
                  error_google_address_required: 'Google address is required.',
                  error_zero_not_allowed: 'Zero not allowed.',
                  // menu handles
                  menu_bookings: 'Bookings',
                  menu_corporate_bookings: 'Corporate Bookings',
                  menu_hotel_bookings: 'Hotel Bookings',
                  menu_sales_quotation: 'Sales Quotations',
                  menu_admin_dashboard: 'Dashboard',
                  menu_corporate_dashboard: 'Corporate Dashboard',
                  menu_passengers: 'Passengers',
                  menu_dispatchers: 'Dispatchers',
                  menu_vehicles: 'Vehicles',
                  menu_drivers: 'Drivers',
                  menu_staff_transport: 'Staff Transport',
                  menu_hotels: 'Hotels',
                  menu_corporates: 'Corporates',
                  menu_app_settings: 'App Settings',
                  menu_settings: 'Settings',
                  menu_notifications: 'App Notifications',
                  menu_reports: 'Reports',
                  menu_corporate_reports: 'Corporate Reports',
                  menu_corporate_approval: 'Corporate Approval',
                  menu_corporate_pending_approval: 'Pending for Approval',
                  menu_corporate_manage_employee: 'Manage Employees',
                  menu_corporate_manage_departments: 'Manage Departments',
                  menu_corporate_settings: 'Corporate Settings',
                  menu_manage_cities: 'Manage Cities',
                  menu_queue_management: 'Queue Management',
                  menu_shift_management: 'Shift Management',
                  menu_manage_locations: 'Locations',
                  label_outbound_queue: 'Outbound Queue',
                  label_all_locations: 'All Locations',
                  label_no_records_found: 'No records found.',
                  label_passenger_not_found: 'Passenger not found.',
                  // app settings access
                  access_get_privacy_policy: 'Get Privacy Policy',
                  access_get_contact_support: 'Get Contact Support',
                  access_get_rental_policy: 'Get Rental Policy',
                  access_getChauffeur_policy: 'Get Chauffeur Policy',
                  access_update_about_us: 'Update About Us',
                  access_update_terms_and_conditions: 'Update Terms And Conditions',
                  access_get_tenant_by_id: 'Get Tenant By Id',
                  access_add_faq: 'Add FAQ',
                  access_update_faq: 'Update FAQ',
                  access_get_faq: 'Get FAQs',
                  access_add_partner: 'Add Partner',
                  access_update_partner: 'Update Partner',
                  access_update_privacy_policy: 'Update Privacy Policy',
                  access_delete_partner: 'Delete Partner',
                  access_get_partners: 'Get Partners',
                  acces_get_service_types: 'Get Service Types',
                  access_get_labels: 'Get Labels',
                  access_get_service_area: 'Get Service Area',
                  error_service_area_required: 'Service area is required.',
                  access_get_terms_and_conditions: 'Get Terms And Conditions',
                  access_get_about_us: 'Get About Us',
                  access_udpate_rental_policy: 'Update Rental Policy',
                  access_update_chauffer_policy: 'Update Chauffeur Policy',
                  // Bookings accesses
                  access_get_corporate_booking_grid: 'Get Corporate Bookings Grid',
                  access_cancel_trip: 'Cancel Journey',
                  access_send_verification_code_to_passenger: 'Send Verification Code To Passenger',
                  access_get_booking_bill_details: 'Get Booking Bill Details',
                  access_add_driver_trip_emergency: 'Add Driver Journey Emergency',
                  access_add_passenger_trip_emergency: 'Add Passenger Journey Emergency',
                  access_get_reasons_by_type: 'Get Reasons By Type',
                  access_get_corporate_pending_approval_grid: 'Get Corporate Pending Booking Approvals Grid',
                  access_approve_booking: 'Approve Booking',
                  access_get_booking_estimation: 'Get Booking Estimation',
                  access_get_booking_details_by_trip_id: 'Get Booking Details By JourneyId',
                  access_extend_booking_package: 'Extend Booking Package',
                  access_udpate_rental_booking_security_checks: 'Update Rental Booking Security Checks',
                  access_end_rental_booking: 'End Rental Booking',
                  access_get_user_active_bookings: 'Get User Active Bookings',
                  access_bookings_export_to_excel: 'Bookings Export To Excel',
                  access_add_passenger_trip_rating: 'Add Passenger Journey Rating',
                  access_add_driver_trip_rating: 'Add Driver Journey Rating',
                  access_add_booking: 'Add Booking',
                  access_add_corporate_booking: 'Add Corporate Booking',
                  access_add_rental_booking: 'Add Rental Booking',
                  access_add_chauffer_booking: 'Add Chaueffer Booking',
                  access_get_passenger_bookings_list: 'Get Passenger Bookings List',
                  access_get_bookings_by_user_type: 'Get Bookings By User Type',
                  access_get_booking_grid: 'Get Booking Grid',
                  access_bookings_export_to_pdf: 'Bookings Export To pdf',
                  access_get_booking_details: 'Get Booking Details',
                  access_cancel_booking: 'Cancel Booking',
                  access_assign_driver: 'Assign Driver',
                  access_acknowledge_booking_by_driver: 'Acknowledge Booking By Driver',
                  access_accept_trip: 'Accept Journey',
                  access_start_trip: 'Start Journey',
                  access_end_trip: 'End Journey',
                  access_update_booking_bill_cash: 'Update Booking Bill Cash',
                  access_update_booking_bill_credit_note: 'Update Booking Bill Credit Note',
                  access_update_booking_driver_arrived: 'Update Booking Driver Arrived',
                  access_add_trip_comment_reply: 'Add Journey Comment Reply',
                  access_get_note_by_trip_id: 'Get Note By JourneyId',
                  resend_notification_message: 'Do you really want to resend the notification?',
                  delete_faq: 'Are you sure you want to delete the FAQ?',
                  delete_partner: 'Are you sure you want to delete this partner?',
                  action_note1: 'Are you sure you want to ',
                  delete_manual: 'Are you sure you want to delete this manual?',
                  corporate_action_note2: ' this Corporate?',
                  dispatcher_action_note2: '  this dispatcher ?',
                  invalid_corporate_message: 'Unable to load corporate details.',
                  edit_company_info: 'Edit Company Info',

                  // corporate booking approval
                  access_get_pending_approvals_grid: 'Get Pending Approvals Grid',
                  access_approva_reject_corp_booking: 'Approve/Reject Corporate Booking',
                  access_get_Corporate_booking_details: 'Get Corporate Booking Details',
                  access_get_charge_codes_for_corporate: 'Get Charge Codes For Corporate',
                  access_get_corporates_and_dept: 'Get Corporates And Departments',
                  // corporate dashboard
                  access_get_emergency_details: 'Get Emergency Details',
                  access_get_corp_dash_emer_grid: 'Corporate Dashboard Emergencies Grid',
                  access_get_corp_dash_graph: 'Get Corporate Dashboard Graph Data',
                  access_get_corporate_dash: 'Get Corporate Dashboard Data',
                  // corporate
                  access_corp_count: 'Corporate Count',
                  access_corp_search: 'Search Corporate',
                  access_corp_export_to_pdf: 'Corporate Export To PDF',
                  access_corp_export_to_xls: 'Corporate Export To Excel',
                  access_corp_activate: 'Activate Corporate',
                  access_corp_and_dept: 'Get Corporates And Departments',
                  access_corp_dept_by_code: 'Get Corporate Departments By Corporate Code',
                  access_corp_add: 'Add Corporate',
                  access_corp_details: 'Get Corporate Details',
                  access_corp_update: 'Update Corporate',
                  access_corp_grid: 'Get Corporate Grid',
                  access_corp_booking_history: 'Corporate Booking History',
                  // corporate settings
                  access_corp_setting_get_charge_code: 'Get Charge Codes For Corporate',
                  access_corp_setting_charge_code_grid: 'Charge Code Grid',
                  access_corp_setting_add_chage_code: 'Add Charge Code',
                  access_corp_setting_routes_grid: 'Routes Grid',
                  access_corp_setting_partner_packge_grid: 'Partner Packages Grid',
                  // dashboard
                  access_admin_dash_emer_and_booking_details: 'Emergency and booking details',
                  access_admin_dash_emer_grid: 'Emergency Grid',
                  access_admin_dash_get: 'Get Dashboard Data',
                  access_admin_dash_get_graph: 'Get Dashboard Graph Data',
                  label_manual_bookings: 'Manual Bookings',
                  label_manual_booking_count: 'Total Manual Bookings',
                  label_app_bookings: 'App Bookings',
                  label_count_app_bookings: 'Total App Bookings',
                  // dispatchers
                  access_disp_get_details: 'Get Dispatcher Details',
                  access_disp_update: 'Update Dispatcher',
                  access_disp_export_to_pdf: 'Dispatchers Data For Export To PDF',
                  access_disp_export_to_xls: 'Dispatchers Data For Export To Excel',
                  access_disp_get_count: 'Get Dispatchers Count',
                  access_disp_grid: 'Dispatchers Grid',
                  access_disp_search: 'Search Dispatchers',
                  access_disp_get_booking_history: 'Get Dispatcher Booking History',
                  access_disp_get_roles: 'Get Dispatcher Roles',
                  access_disp_activate_deactivate: 'Activate/Deactivate Dispatcher',
                  access_disp_add: 'Add Dispatcher',
                  // drivers
                  access_driver_get: 'Get Driver Info',
                  access_driver_update: 'Update Driver',
                  access_driver_search: 'Search Driver',
                  access_driver_activate_deactivate: 'Activate/Deactivate Driver',
                  access_driver_grid: 'Drivers Grid',
                  access_driver_export_to_xls: 'Drivers Export To Excel',
                  access_driver_export_to_pdf: 'Drivers Export To PDF',
                  access_driver_get_count: 'Get Drivers Count',
                  access_driver_get_vehicle_and_assigne: 'Get Vehicle Assigned To Driver',
                  access_driver_update_duty_status: 'Update Driver Duty Status',
                  access_driver_get_near_by: 'Get Near By Drivers',
                  access_driver_get_booking_history: 'Get Driver Booking History',
                  access_driver_get_location: 'Get Location Of Driver',
                  access_driver_get_docs_types: 'Get Driver Document Types',
                  access_driver_add: 'Add Driver',
                  label_service_cities: 'Service Cities',
                  label_dispatch_zones: 'Dispatch Zones',
                  label_service_city: 'Service City',
                  error_service_city_required: 'Service city is required',
                  // hotel bookings
                  access_hotel_bookings_grid: 'Hotel Bookings Grid',
                  access_hotel_bookings_add: 'Add Hotel Bookings',
                  access_hotel_bookings_export_to_xls: 'Hotel Bookings Export To Excel',
                  access_hotel_bookings_export_to_pdf: 'Hotel Bookings Export To PDF',
                  access_hotel_bookings_list: 'Get All Hotels List',
                  // hotels
                  access_hotels_activate: 'Activate/Deactivate Hotel',
                  access_hotels_booking_grid: 'Get Hotel Booking History Grid',
                  access_hotels_grid: 'Hotels Grid',
                  access_hotels_export_to_xls: 'Hotels Export To Excel',
                  access_hotels_export_to_pdf: 'Hotels Export To PDF',
                  access_hotels_get_count: 'Get Hotels Count',
                  access_hotels_search: 'Search Hotel',
                  access_hotels_add: 'Add Hotel',
                  access_hotels_details: 'Get Hotel Details',
                  access_hotels_update: 'Update Hotel',
                  // manage departments
                  access_dept_update: 'Update Department',
                  access_dept_add: 'Add Department',
                  access_dept_export_to_xls: 'Departments Grid Export To Excel',
                  access_dept_grid: 'Departments Grid',
                  access_dept_search: 'Search Employee',
                  // manage employee
                  access_emp_add: 'Add Employee',
                  access_emp_trip_history_grid: 'Employee Journey History Grid',
                  access_emp_set_vip: 'Set Employee As VIP',
                  access_emp_reject: 'Reject Employee',
                  access_emp_approve: 'Approve Employee',
                  access_emp_export_to_xls: 'Employees Grid Export To Excel',
                  access_emp_activate: 'Activate/Deactivate Employee',
                  access_emp_for_pending_approval: 'Get Employee For Pending Approval',
                  access_emp_details: 'Get Employee Details',
                  access_emp_udpate: 'Update Employee',
                  access_emp_grid: 'Employees Grid',
                  // notifications
                  access_noti_send: 'Send Notification',
                  access_noti_delete_of_user: 'Delete Notifications Of User',
                  access_noti_delete: 'Delete Notification',
                  access_noti_mark_read: 'Mark Notification Read',
                  access_noti_send_status: 'Send Notification Status',
                  access_noti_get_list: 'Get Notifications List',
                  // passengers
                  access_passengers_get_details: 'Get Passenger Details',
                  access_passengers_get_trip_history: 'Get Journey History For Passenger',
                  access_passengers_get_by_type: 'Get Passengers By Type',
                  access_passengers_search: 'Search Passengers',
                  access_passengers_grid: 'Passenger Grid',
                  access_passengers_get_count: 'Get Passenger Count',
                  access_passengers_export_to_xls: 'Passenger Data Export To Excel',
                  access_passengers_export_to_pdf: 'Passenger Data Export To PDF',
                  access_passengers_activate: 'Active/Deactive Passenger',
                  // sales quotations
                  access_sales_quo_accept_reject: 'Accept/Reject Sales Quotation',
                  access_sales_quo_upload: 'Upload Sales Quotation',
                  access_sales_quo_details: 'Sales Quotation Details',
                  access_sales_quo_grid: 'Sales Quotations Grid',
                  access_sales_quo_cancel: 'Cancel Sales Quotation',
                  access_sales_quo_export_to_xls: 'Sales Quotations Export To Excel',
                  access_sales_quo_export_to_pdf: 'Sales Quotations Export To PDF',
                  access_sales_quo_view_quotations_details: 'View Quotation Details',
                  // staff transport
                  access_staff_update: 'Update Staff Transport',
                  access_staff_activate: 'Activate/Deactivate Company',
                  access_staff_get_drop_points: 'Get Staff Transport Vehicle Drop Points',
                  access_staff_add: 'Add Staff Transport',
                  access_staff_grid: 'Staff Transport Grid',
                  access_staff_details: 'Get Staff Transport Details',
                  access_staff_activate_vehicle: 'Activate/Deactivate Staff Transport Vehicle',
                  access_staff_count: 'Get Staff Transport Count',
                  access_staff_add_booking: 'Add Booking For Staff Transport',
                  access_staff_export_to_xls: 'Staff Transport Export To Excel',
                  access_staff_export_to_pdf: 'Staff Transport Export To PDF',
                  access_staff_update_status: 'Update Staff Transport Status',
                  access_staff_renew_package: 'Renew Staff Transport Package',
                  // vehicles
                  access_vehicle_get_by_type: 'Get Vehicle By Vehicle Type',
                  access_vehicle_get_types: 'Get Vehicle Types',
                  access_vehicle_get_service_type: 'Get Service Types',
                  access_vehicle_activate: 'Activate/Deactivate Vehicle',
                  access_vehicle_grid: 'Vehicles Grid',
                  access_vehicle_get_docs_types: 'Get Vehicle Document Types',
                  access_vehicle_add: 'Add Vehicle',
                  access_vehicle_get_count: 'Get Vehicle Counts',
                  access_vehicle_update: 'Update Vehicle',
                  access_vehicle_get_details: 'Get Vehicle Details',
                  access_vehicle_export_to_xls: 'Vehicles Data Export To Excel',
                  access_vehicle_export_to_pdf: 'Vehicles Data Export To PDF',
                  access_vehicle_search: 'Search Vehicle',
                  access_vehicle_near_by_vehicles: 'Get Near By Vehicles',
                  access_vehicle_get_driver_info_by_vehicle: 'Get Driver Info By Vehicle',
                  access_vehicle_get_near_by_vehicles_by_area: 'Get Near By Vehicles By Area',
                  access_vehicle_booking_history_grid: 'Vehicle Booking History Grid',
                  access_vehicle_add_type: 'Add Vehicle Type',
                  access_vehicle_update_type: 'Update Vehicle Type',
                  access_vehicle_type_grid: 'Vehicle Types Grid',
                  access_vehicle_activate_type: 'Activate/Deactivate Vehicle Type',
                  cnf_deactivate_city_then_all_driver_not_receive_request:
                        'If you deactivate city then all drivers attached to this city will not receive any Journey requests.',
                  cnf_realy_want_to: 'Are you sure you want to ',
                  cnf_this_area: ' this area?',
                  cnf_this_dispatch_zone: ' this dispatch zone?',
                  cnf_this_company: ' this company?',
                  cnf_this_region: ' this region?',
                  // cities
                  label_city_name: 'City Name',
                  lable_alias_name: 'Alias Name',
                  lable_placeholder_full_name: 'Enter fullname',

                  label_add_city: 'Add City',
                  label_city_name_msg: 'City name is required.',
                  label_alias_msg: 'Alias name is required.',
                  label_last_hour: 'Last Hour',
                  label_last_4_hours: 'Last 4 Hours',
                  label_current_day: 'Current Day',
                  label_last_7_days: 'Last 7 Days',
                  label_last_15_days: 'Last 15 Days',
                  label_last_30_days: 'Last 30 Days',
                  label_today: 'Today',
                  label_yesterday: 'Yesterday',
                  label_this_month: 'This Month',
                  label_last_month: 'Last Month',
                  label_export: 'Export',
                  label_total_trips_cancelled_by_drivers: 'Total Journeys Cancelled By Drivers',
                  label_total_trips_cancelled_by_passengers: 'Total Journeys Cancelled By Passengers',
                  label_total_drivers_on_platform: 'Total Drivers On Platform',
                  label_drivers_received_trips: 'Drivers Received Journeys',
                  label_total_fares: 'Total Fare',
                  label_total_drivers_payout: 'Total Drivers Payout',
                  label_total_platform_commission: 'Total Platform Commission',
                  label_total_receivable_from_drivers: 'Total Receivable From Drivers',
                  label_total_promo_discount: 'Total Promo Discount',
                  label_total_refunded_amount: 'Total Refunded Amount',
                  label_view_report: 'View Report',
                  label_is_suprvisor: 'Is Supervisor',
                  error_select_date_range: 'Please select date range.',
                  error_valid_parameter_to_view_export: 'Please select any valid parameter to view exported file.',
                  error_getting_platform_summery_data: 'Error occured during getting platform summary data.',
                  error_getting_heat_map_data: 'Error occured during getting heat map data.',
                  label_role_name: 'Role Name',
                  error_driver_code_required: 'Driver code required.',
                  error_role_required: 'Role name is required',
                  role_name_pattern: 'Invalid role name',
                  label_role_code: 'Role Code',
                  label_user_code: 'User Code',
                  label_user_name: 'Username',
                  label_user_Name: 'User Name',
                  error_first_name_required: 'First name is required.',
                  error_last_name_required: 'Last name is required.',
                  label_tax_name: 'Tax Name',
                  error_tax_name_required: 'Tax name is required.',
                  label_tax_percentage: 'Tax Percentage',
                  error_tax_percentage_required: 'Tax percentage is required.',
                  error_invalid_tax_percentage: 'Invalid tax percentage.',
                  label_free_distance: 'Free Distance',
                  free_dist_req: 'Free distance is required',
                  label_per_distance_fare: 'Per km Fare',
                  label_per_Mile_fare: 'Per Mile Fare',
                  error_per_Mile_fare_required: 'Per mile fare is required.',
                  label_per_min_fare: 'Per min. Fare',
                  label_driver_percentage: 'Driver Percentage',
                  label_contactNumber: 'Contact Number',
                  label_supportEmail: 'Support Email',
                  label_example_vehicle_registration_num: 'Example : AB12CD345',
                  label_minimum_fare: 'Minimum Fare',
                  label_invalid_minimum_fare: 'Invalid minimum Fare',
                  minimum_fare_req: 'Minimum fare is required',
                  label_referrer_benefit: 'Referrer Benefit',
                  label_driver_referral: 'Driver Referral',
                  label_passenger_referral: 'Passenger Referral',
                  label_referee_benefit: 'Referee Benefit',
                  have_promo_code: 'Have A Promotional Code?',
                  label_manual_booking: 'Manual Booking',
                  label_book_now: 'Book Now',
                  label_book_ride_now: 'Book Ride Now',
                  label_book_ride_later: 'Book Ride Later',
                  label_book_ride_share: 'Book Ride Share',
                  label_ride_share: 'Ride Share',
                  label_car_details: 'Car Details',
                  label_trip_details: 'Journey Details',
                  label_car_type_with_colon: 'Car Type :-',
                  label_model_with_colon: 'Model :-',
                  label_color_with_colon: 'Color :-',
                  label_vehicle_type_with_colon: 'Vehicle Type :-',
                  label_plate_number_with_colon: 'Plate Number :-',
                  label_status_with_colon: 'Status : ',
                  label_dummy_filter: 'Dummy vehicle filter',
                  label_email_text: 'Email',
                  error_invalid_email: 'Email is invalid.',
                  error_invalid_phone_number: 'Phone number is invalid.',
                  error_invalid_country_code: 'Country code is invalid.',
                  label_refund: 'Refund',
                  label_rider_details: 'Rider Details',
                  label_vehicle_details: 'Vehicle Details',
                  label_vehicle_drivers: 'Vehicle Drivers',
                  label_rating_given_by_driver: 'Ratings given by driver',
                  label_rating_given_by_passenger: 'Ratings given by passenger',
                  label_fare_breakdown: 'Fare Breakdown',
                  label_trip_statistics: 'Journey Statistics',
                  label_distance_fare_text: 'Distance fare',
                  label_time_fare: 'Time fare',
                  label_total: 'Total',
                  label_duration: 'Duration',
                  label_total_time: 'Total time',
                  label_vehicle_number: 'Vehicle Number',
                  label_vehicle_number_plate: 'Vehicle Number Plate',
                  label_email_invoice: 'Email Invoice',
                  label_invoice_receipt: 'Invoice Receipt',
                  label_cash_collected: 'Cash Collected',
                  label_profile_password: 'Profile Password',
                  label_profile_password_required: 'Profile password is required.',
                  label_invalid_amount: 'Invalid amount',
                  refund_amount_required: 'Refund amount is required',
                  label_free_cars: 'Free Vehicles',
                  label_busy_cars: 'Busy Vehicles',
                  label_idle_cars: 'Idle Vehicles',
                  label_no_nearby_driver: 'It seems there are no nearby drivers available.',
                  label_tour_time_fare: 'Journey Time Fare',
                  label_enroute_waiting_fare: 'Enroute Waiting Fare',
                  error_enroute_waiting_fare_required: 'Enroute waiting fare is required.',
                  error_invalid_enroute_waiting_fare: 'Enroute waiting fare is invalid.',
                  label_manage_taxes: 'Manage Taxes',
                  label_add_tax: 'Add Tax',
                  label_update_tax: 'Update Tax',
                  error_free_distance_required: 'Free distance is required.',
                  error_invalid_free_istance: 'Invalid free distance.',
                  error_per_km_fare_required: 'Per km fare is required.',
                  error_invalid_per_km_fare: 'Invalid per km fare.',
                  error_invalid_per_mile_mile_fare: 'Invalid per mile fare.',
                  error_per_minute_fare_required: 'Per min fare is required.',
                  error_invalid_per_minute_fare: 'Invalid per min fare.',
                  error_wait_time_fare_required: 'Wait time fare is required.',
                  error_invalid_wait_time_fare: 'Invalid wait time fare.',
                  error_cancellation_charges_required: 'Passenger cancellation charges is required.',
                  error_invalid_cancellation_charges: 'Invalid passenger cancellation charges.',
                  error_driver_percentage_required: 'Driver percentage is required.',
                  error_invalid_driver_percentage: 'Invalid driver percentage.',
                  error_minimum_fare_required: 'Minimum fare is required.',
                  error_booking_fees_required: 'Booking fees is required.',
                  error_invalid_booking_fees: 'Invalid Booking fees.',
                  error_invalid_minimum_fare: 'Invalid minimum fare.',
                  error_tour_time_fare_required: 'Journey time fare is required.',
                  error_invalid_tour_time_fare: 'Invalid Journey time fare.',
                  error_vehicle_icon_required: 'Vehicle icon is required.',
                  error_barcode_required: 'Barcode is required.',
                  error_playercode_required: 'Player Code is required.',
                  error_badge_description_required: 'Badge description is required.',
                  error_accreditation_id_required: 'Accreditation id is required.',
                  error_group_id_required: 'Group id is required.',
                  error_passenger_urn_required: 'Passenger URN is required.',
                  error_document_name_required: 'Document name is required.',
                  error_passcode_required: 'Passcode is required',
                  error_passcode_invalid: 'Passcode is invalid',
                  error_passcode_length: 'Exactly 6 characters required. (eg. as32cd)',
                  label_app_store_url: 'App Store URL',
                  label_play_store_url: 'Play Store URL',
                  label_facebook_url: 'Facebook URL',
                  label_twitter_url: 'Twitter URL',
                  label_google_plus_url: 'Google Plus URL',
                  label_number_of_seats: 'Number Of Seats',
                  label_number: 'Number',
                  error_number_of_seats_required: 'Number of seats is required.',
                  error_invalid_number_of_seats: 'Invalid number of seats.',
                  label_additional_seat_fare: 'Additional Seat Fare',
                  error_additional_seat_fare_required: 'Additional seat fare is required.',
                  error_invalid_additional_seat_fare: 'Invalid additional seat fare.',
                  label_ride_share_service: 'Ride Share Service',
                  label_profile_updated_successfully: 'User profile is updated Successfully.',
                  label_load_more: 'Load More Notifications',
                  label_map: 'Map',
                  label_heat_map: 'Heat Map',
                  label_plateform_summary_report: 'Platform Summary Report',
                  label_driver_emergency: 'Driver Emergency',
                  label_passenger_emergency: 'Passenger Emergency',
                  label_new_request: 'New Request',
                  label_attened: 'Attended',
                  label_attend_it: 'Attend It',
                  label_closed: 'Closed',
                  label_close_it: 'Close It',
                  label_request_status: 'Request Status',
                  label_arrange_vehicles: 'Arrange Vehicles',
                  label_all_vehicle_type: 'All Vehicle Type',
                  label_selected_vehicle_type: 'Selected Vehicle Type',
                  error_mode_required: 'Mode is required.',
                  error_fare_mode_required: 'Fare mode is required.',

                  error_usage_required: 'Usage is required.',
                  error_limit_of_uses_required: 'No of uses is required.',
                  label_company: 'Company',
                  error_company_required: 'Company is required.',
                  label_company_name: 'Company Name',
                  error_company_name: 'Company name is required.',
                  company_pattern: 'Invalid company name',
                  label_add_company: 'Add Company',
                  label_edit_company: 'Edit Company',
                  label_old_password: 'Old Password',
                  label_current_password: 'Current Password',
                  label_new_password: 'New Password',
                  label_new_confirm_password: 'Confirm Password',
                  error_old_password_required: 'Old password is required.',
                  error_current_password_required: 'Current password is required.',
                  error_new_password_required: 'New password is required.',
                  error_new_confirm_password_required: 'Confirm new password is required.',
                  error_confirm_password_required: 'Confirm password is required.',
                  error_new_confirm_password_not_match: 'New and confirm password does not match.',
                  label_add_user: 'Add User',
                  label_edit_user: 'Edit User',
                  label_add_operator: 'Add Operator',
                  label_edit_operator: 'Edit Operator',
                  label_operator_name: 'Operator Name',
                  label_reset_password: 'Reset Password',
                  label_select_no_of_seats: 'Select Number Of Seat(s)',
                  label_vehicle_assigned: 'Vehicle Assigned',
                  label_assigned: 'Assigned',
                  label_non_assigned: 'Non-Assigned',
                  label_unassigned: 'Unassigned',
                  label_admin_settlement: 'Admin Settlement',
                  label_promo_discount: 'Promo Discount',
                  label_credits: 'Credits',
                  label_percentage: 'Percentage',
                  label_driver_amount: 'Driver Amount',
                  label_date: 'Date',
                  label_name_and_model: 'Name & Model',
                  label_data_before_action: 'Data Before Action',
                  label_data_after_action: 'Data After Action',
                  label_bid_amount: 'Bid Amount',
                  label_driver_status: 'Driver Status',
                  label_passenger_status: 'Passenger Status',
                  label_bidding_details: 'Bidding Details',
                  label_total_invoice: 'Total Invoice',
                  label_booking_fares: 'Booking Fare',
                  label_total_distance: 'Total Distance',
                  label_total_driver: 'Total Drivers',
                  label_online_driver: 'Online Drivers',
                  label_total_passengers: 'Total Passengers',
                  label_online_passengers: 'Online Passengers',
                  label_online_vehicles: 'Online Vehicles',
                  label_total_dispatchers: 'Total Dispatchers',
                  label_online_dispatchers: 'Online Dispatchers',
                  label_total_hotels: 'Total Hotels',
                  label_online_hotels: 'Online Hotels',
                  label_total_companies: 'Total Companies',
                  label_online_companies: 'Online Companies',
                  label_total_corporates: 'Total Corporates',
                  label_online_corporates: 'Online Corporates',
                  label_ride_share_name: 'Ride Share Name',
                  label_source_radius: 'Source Radius(miles)',
                  error_source_radius_required: 'Source radius is required.',
                  error_invalid_source_radius: 'Source radius is invalid.',
                  label_destination_radius: 'Destination Radius(miles)',
                  error_destination_radius_required: 'Destination radius is required.',
                  error_invalid_destination_radius: 'Destination radius is invalid.',
                  error_ride_share_name_required: 'Ride share name is required.',
                  label_per: 'Per',
                  label_source_radius_dynamic: 'Source Radius',
                  label_destination_radius_dynamic: 'Destination Radius',
                  label_no_of_drivers: 'No. of Drivers',
                  label_segment_name: 'Segment Name',
                  label_segment: 'Segment',
                  label_segments: 'Segments',
                  label_segment_details: 'Segment Details',
                  label_created_date_time: 'Created Date & Time',
                  label_contact_match: 'Contact match your condition',
                  label_role_type: 'Role Type',
                  error_role_type_required: 'Role type is required.',
                  error_status_required: 'Status is required',
                  label_near_by: 'Nearby Drivers',
                  label_user: 'User',
                  error_user_required: 'User is required',
                  label_segment_required: 'Segment Name is required.',
                  error_segment_list_required: 'Segment is required.',
                  error_message_type_required: 'Message type is required.',
                  label_send_notification: 'Send Notification',
                  label_message_type: 'Message Type',
                  label_message: 'Message',
                  label_message_title: 'Message Title',
                  label_segment_list: 'Segment List ',
                  label_add_new_segment: 'Add new Segment',
                  label_message_description: 'Message Description (Maximum characters limit: 150)',
                  label_message_email_description: 'Message Description (Maximum characters limit: 1000)',
                  error_message_title_required: 'Message type is required.',
                  error_message_description_required: 'Message description is required.',
                  label_add_segment: 'Add Segment',
                  mins_required: 'Minutes required',
                  secs_required: 'Seconds required',
                  label_promocode_pattern: 'Promocode should be in capital and numbers only.',
                  label_driver_information: 'Driver Information',
                  label_vehicle_information: 'Vehicle Information',
                  label_distance_travelled_miles: 'Distance Travelled',
                  label_fare_details: 'Fare Details',
                  label_current_driver_online: 'Current Driver Online',
                  label_valid_date_range: 'Please Select valid Date range for reports',
                  label_offline: 'Offline',
                  label_vehicle_make_model: 'Vehicle make & model',
                  label_driver_required: 'Driver required',
                  label_drop_trip: 'Drop Journey',
                  label_drop_time_required: 'Drop time required',
                  label_total_time_in_minuites: 'Journey Time',
                  label_no_route_available: 'No Route Available',
                  label_referral_code: 'Referral Code',
                  label_wallet_amount: 'Wallet Amount',
                  label_trip_note: 'Journey Note',
                  label_add_note: 'Add Note',
                  label_wallet_update: 'Wallet Update',
                  label_occasional_detatil: 'Occasional Journey Details',
                  label_amount_required: 'Amount required',
                  label_amount_limit_exceeded: 'Amount limit exceeded',
                  label_wallet: 'Wallet',
                  label_referral_credit: 'Referral Credit',
                  label_payment_type: 'Payment Type',
                  label_tour_start_date_time: 'Tout Start Date & Time',
                  label_tour_end_date_time: 'Tout End Date & Time',
                  label_tour_status: 'Journey Status',
                  label_service_type_code: 'Service Type Code',
                  lbl_credit_card_info: 'Card Info',
                  label_sub_total: 'Sub Total',
                  label_tax_amount: 'Tax Amount',
                  label_grand_total: 'Grand Total',
                  label_vehicle_condition: 'Vehicle condition',
                  label_company_percentage: 'Company Percentage',
                  label_total_completed_trips: 'Total completed Journeys',
                  label_total_invoice_amount: 'Total Invoice Amount',
                  label_company_share: 'Company Share',
                  label_admin_share: 'Admin Share',
                  label_add_vehicle: 'Add Vehicle',
                  label_add_new_vehicle: 'Add New Vehicle',
                  label_edit_vehicle: 'Edit Vehicle',
                  label_remove_vehicle: 'Remove Vehicle',
                  label_add_vehicle_description: 'Please select one or more vehicle(s).',
                  label_view_assign_vehicle: 'View Assigned Vehicle',
                  error_vehice_is_requied: 'Vehicle is required',
                  label_vehicle_not_found: 'No assigned vehicle found for this driver.',
                  label_driver_not_found: 'No assigned drivers found for this vehicle.',
                  label_vehicle_not_found_to_assign: 'No more available vehicles to be assign',
                  label_data_not_found: 'Data not found for this selection.',
                  label_packeges: 'Packeges',
                  label_duartion_type: 'Duration Type',
                  label_additional_duartion_type: 'Additional Duration Type',
                  label_duartion_type_required: 'Duration Type Required',
                  label_additional_distance: 'Additional Distance',
                  label_additional_distance_fare: 'Additional Distance Fare',
                  label_additional_duration: 'Additional Duration',
                  label_additional_duration_required: 'Additional Duration Required',
                  label_additional_duration_fare: 'Additional Duration Fare',
                  label_additional_duration_fare_required: 'Additional Duration Fare Required',
                  label_Invalid_additional_duration_fare_required: 'Invalid Additional Duration Fare Required',

                  label_additional_distance_required: 'Additional Distance Required',
                  label_additional_distance_fare_required: 'Additional Distance Fare Required',
                  label_waiting_time_required: 'Waiting Time Required',
                  label_waiting_time_fare_required: 'Waiting Time Fare Required',
                  label_invalid_waiting_time_required: 'Invalid Waiting Time',
                  label_invalid_waiting_time_fare_required: 'Invalid Waiting Time Fare',
                  label_waiting_time_fare: 'Waiting Time Fare',
                  label_trip_waiting: 'Journey Waiting Time',
                  label_trip_waiting_required: 'Journey Waiting Time required',
                  label_trip_waiting_invalid_required: 'Journey Waiting Time required',
                  label_trip_waiting_fare: 'Journey Waiting Time Fare',
                  label_trip_waiting_fare_required: 'Journey Waiting Time Fare required',
                  label_trip_waiting_fare_invalid_required: 'Journey Waiting Time Fare required',
                  label_cancellation_fare: 'Cancellation Fare',
                  label_cancellation_fare_required: 'Cancellation Fare Required',
                  label_cancellation_fare_invalid: 'Invalid Cancellation Fare',
                  label_package_distance: 'Package Distance',
                  label_package_duration: 'Package Duration',
                  label_enroute_waiting_time: 'Enroute Waiting Time',
                  label_enroute_waiting_time_fare: 'Enroute Waiting Time Fare',
                  label_per_min_waiting_time_fare: 'Per Min Wait Time Fare',
                  label_change_category_name: 'Change Category Name',
                  label_company_code: 'Company Code',
                  label_company_address: 'Company Address',
                  label_company_code_error: 'Company Code is required',
                  label_company_address_error: 'Company Address is required',
                  label_additional_charges: 'Additional Charges',
                  label_enter_amount: 'Enter Amount',
                  label_total_trips: 'Total Journeys',
                  label_surge: 'Surge / Discount',
                  label_to_time: 'To Time',
                  label_price: 'Price',
                  label_mon: 'Mon',
                  label_tue: 'Tue',
                  label_wed: 'Wed',
                  label_thu: 'Thu',
                  label_fri: 'Fri',
                  label_sat: 'Sat',
                  label_sun: 'Sun',
                  label_surge_discount: 'Surge / Discount (%)',
                  err_hours_required: 'Hours Required.',
                  err_minutes_required: 'Minutes Required.',
                  err_price_required: 'Surge / Discount required.',
                  err_invalid_amount: 'Invalid amount.',
                  err_from_time_to_time: 'From time should be less than To time.',
                  label_hrs: 'Hrs',
                  label_min: 'Min',
                  label_service_time: 'Service Time',
                  label_from_time: 'From Time',
                  label_promocode: 'Promocode',
                  label_from_custom_date: 'Custom Date',
                  label_search_dashboard: 'Search',
                  label_search_driver: 'Search Driver',
                  label_tip: 'Tip',
                  label_vehicle_registration: 'Vehicle Registration',
                  label_registration_vehicle: 'Registration Number',
                  error_registration_vehicle: 'Invalid registration number, accept only alphanumeric value.',
                  label_vin_number: 'Vin Number',
                  label_motor_number: 'Motor Number',
                  label_vehicle_class_type: 'Vehicle Class Type',
                  label_color_type: 'Color Type',
                  label_color: 'Color',
                  label_level_of_service: 'Level Of Service',
                  label_Expire_date_technical_revision: 'Expire Date Technical Revision',
                  label_line_type: 'Line Type',
                  label_technical_revision: 'Technical Revision',
                  label_card_number: 'Card Number',
                  label_abs_brake_type: 'ABS brake type',
                  label_gps: 'Global positioning system GPS',
                  label_number_of_frontal_airbags: 'Number Of Frontal Airbags',
                  label_has_headrest: 'Has headrest?',
                  label_number_of_side_doors: 'Number Of Side Doors',
                  label_cabin_type: 'Cabin Type',
                  label_passenger_capacity: 'Passenger Capacity',
                  label_minimum_space_per_passenger_width_height: 'Minimum Space Per Passenger Width, Height',
                  label_ashlar: 'ashlar (seats) module of 750 mm',
                  label_capacity_or_luggage_hold: 'Capacity or Luggage Hold',
                  label_car_body_type: 'Car Body Type',
                  label_displacement: 'Displacement',
                  label_power: 'Power',
                  label_home_number: 'Home Phone Number',
                  label_home_country_code: 'Home Country Code',
                  label_rh: 'RH',
                  label_arl: 'ARL',
                  label_eps: 'EPS',
                  label_pension: 'Pension',
                  label_refresh: 'Refresh',
                  label_all_dispatch_zone: 'All Dispatch Zones',
                  label_edit_dispatch_zone: 'Edit Dispatch Zone',
                  label_search_zone: 'Search Zone',
                  label_dispatch_zone: 'Dispatch Zone',
                  label_dispatch_zone_name: 'Dispatch Zone Name',
                  err_dispatch_zone_required: 'Dispatch zone is required.',
                  label_setup_dispatch_zone: 'Setup Dispatch Zone : ',
                  label_add_setup_dispatch_zone: 'Setup Dispatch Zone',
                  label_add_dispatch_zone: 'Add Dispatch Zone',
                  label_all_shift_groups: 'All Shift Groups',
                  label_shift_groups: 'Shift Groups',
                  label_max_50_characters_allowed: 'Max 50 characters are allowed',
                  label_max_25_characters_allowed: 'Max 25 characters are allowed',
                  label_estimated_distance: 'Approx. Distance Left',
                  label_estimated_time_of_travel: 'Approx. ETA',
                  label_average_travel_time: 'Average Travel Time',
                  label_approval_status: 'Approval Status',
                  label_fare_mode: 'Fare Mode',
                  label_zone_name: 'Zone Name',
                  label_zone_alias_name: 'Zone Alias Name',
                  label_booking_fees: 'Booking Fees',
                  label_driver_cancellation_charges: 'Driver Cancellation Charges',
                  label_cancellation_charges: 'Cancellation Charges',
                  error_driver_cancellation_charges: 'Driver cancellation charges is required',
                  error_invalid_driver_cancellation_charges: 'Invalid driver cancellation charges',
                  error_gender_required: 'Gender is required.',
                  error_date_of_birth_required: 'Date of birth required',
                  driver_wing_phone: 'Driver Wing Phone',
                  error_driver_wing_phone_required: 'Driver Wing Phone required',
                  label_referral_code_required: 'Referral code required',
                  label_referral_code_invalid: 'Invalid Referral code',
                  label_approval: 'Approve',
                  error_service_from_required: 'Service from required',
                  error_service_to_required: 'Service to required',
                  label_occasional_rides: 'Occasional Journeys',
                  label_from: 'From',
                  error_label_from_required: 'From is required',
                  label_to: 'To',
                  error_label_to_required: 'To is required',
                  label_fare_type: 'Fare Type',
                  error_label_fare_type_required: 'Fare type is required',
                  label_change_occasional_status: 'Change Occasional Status',
                  label_confirmation_occasional_status: 'Are you sure you want to update the status of this occasional Journey?',
                  label_service_from: 'Service From',
                  label_service_to: 'Service To',
                  error_label_same_service_area: 'Same from and to service cities',
                  label_occasional_status: 'Occasional Status',
                  label_logs: 'Logs',
                  label_action_by: 'Action By',


                  /* wimbeldon start */
                  lbl_top_activties: 'Activities',
                  lbl_journeys: 'Journeys',
                  label_done: 'Done',
                  lbl_journey: 'Journey',
                  lbl_vehicles: 'Vehicles',
                  lbl_all_vehicles: 'All Vehicles',
                  lbl_drivers: 'Drivers',
                  lbl_total_journeys: 'Total',
                  lbl_active_journeys: 'Active',
                  lbl_completed_journeys: 'Completed',
                  lbl_upcoming_journeys: 'Upcoming',
                  lbl_cancelled_journeys: 'Cancelled',
                  lbl_total_vehicles: 'Total',
                  lbl_active_dummy_vehicles: 'Active Dummy',
                  lbl_deactivated_vehicles: 'Deactivated',
                  lbl_active_vehicles: 'Active',
                  lbl_inactive_vehicles: 'Inactive',
                  lbl_idle_vehicles: 'Idle',
                  lbl_total_drivers: 'Total',
                  lbl_active_drivers: 'Active',
                  lbl_inactive_drivers: 'Inactive',
                  lbl_offline_drivers: 'Offline',
                  lbl_emergency: 'Emergency',
                  lbl_current_bookings: 'Current Bookings',
                  lbl_by_drivers: 'By Drivers',
                  lbl_by_passengers: 'By Passengers',
                  lbl_available: 'Available',
                  lbl_busy: 'Busy',
                  lbl_idle: 'Idle',
                  lbl_pickup_location: 'Pickup location',
                  lbl_drop_location: 'Drop off location',
                  lbl_passenger_Type: 'Passenger type',
                  lbl_dispatch_location: 'Dispatch location',
                  lbl_dispatch_zones: 'Dispatch zone',
                  lbl_inbound_journeys: 'Inbound',
                  lbl_inbound_other_journeys: 'Inbound & Other',
                  lbl_critical_journeys: 'Critical',
                  lbl_outbound_journeys: 'Outbound',
                  lbl_vehicle_transfer: 'Vehicle Transfer',
                  lbl_other_journeys: 'Other',
                  lbl_third_party_transfer: 'Third Party Transfer',
                  lbl_to_third_party: 'To Third Party',
                  label_total_rides: 'Total Journeys',
                  label_active_rides: 'Active Journeys',
                  label_completed_rides: 'Completed Journeys',
                  label_upcoming_rides: 'Upcoming Journeys',
                  label_canceled_rides: 'Cancelled Journeys',
                  label_expired_rides: 'Expired Journeys',
                  label_export_data_heading: 'Enter Security Code To Export Data',

                  label_assign_group: 'Assign Group',
                  label_select_dispatch_location: 'Select Dispatch Location',
                  label_select_parking_location: 'Select Parking Location',
                  label_Type: 'Type',
                  label_search_by_trip_id_or_name: 'Search by Journey Id, Request By, Phone Number',
                  label_search_by_address: 'Search by address',
                  label_search_by_driver_journey_id: 'Search by driver or journey id',
                  label_search_by_passenger_journey_id: 'Search by passenger or journey id',
                  // label_search_by_passenger_barcode_journey_id: 'Search by passenger or journey id or barcode',
                  label_search_by_passenger_barcode_journey_id: 'Search by passenger, driver, journey id, accreditation',
                  label_search_vehicle: 'Search by vehicle code, registration number',
                  label_booking_history: 'Booking History',
                  label_journey_details: 'Journey Details',
                  label_time: 'Time',
                  label_pick_up: 'Pick Up',
                  label_drop_off: 'Drop Off',
                  label_pickup_time: 'Pickup Time',
                  label_pickup_date: 'Pickup Date',
                  label_wheechairs_transported: 'Wheelchairs transported',
                  label_baby_seat: 'Baby Seat',
                  label_cihld_seat: 'Child Seat',
                  label_booking_note: 'Booking Note',
                  label_driver_duty_status: 'Duty Status',
                  label_driver_login_status: 'Login/Logout',
                  lavel_select_current_active_vehicle: 'Select Current Active Vehicle',
                  lavel_select_off_duty_reasons: 'Select Off Duty Reasons',
                  error_no_vehicles_are_assigned: 'Vehicle are not assigned to this driver.',

                  label_passenger_details: 'Passenger Details',
                  label_privilege: 'Privilege',
                  label_eligibility: 'Eligibility',
                  label_override: 'Override',
                  label_journey_status: 'Journey Status',
                  label_eta: 'ETA',
                  label_booking_by_name: 'Booking By Name',
                  label_booking_time_date: 'Booking Time & Date',
                  label_booking_created_time: 'Created Time',
                  label_alies_number: 'Alias number',
                  label_vehicle_code: 'Vehicle code',
                  label_checkin_status: 'Checkin Status',
                  label_registration_number: 'Registration number',
                  label_driver_dashboard: 'Driver Dashboard',
                  label_availability_status: 'Availability Status',
                  label_unavailable: 'Unavailable',
                  label_support_number_one: 'Support Number 1',
                  label_support_number_two: 'Support Number 2',
                  label_support_number_three: 'Support Number 3',
                  label_support_number_four: 'Support Number 4',
                  label_manufacturer_name_model: 'Manufacturer name & model',
                  label_manufacturer_name: 'Manufacturer name',
                  label_vehicle_manufacturer: 'Vehicle Manufacturer',
                  label_vehicle_manufacturer_detail: 'Vehicle Manufacturer',
                  label_booking_details: 'Booking Details',
                  label_radio_number: 'Radio Number',
                  label_device_number: 'Device number',
                  label_mobile_number: 'Mobile Number',
                  label_allocated_drivers: 'Allocated Drivers',
                  label_mobile_id: 'Mobile ID',
                  error_mobile_is_required: 'Mobile id is required.',
                  label_vehicle_image: 'Vehicle Image',
                  label_create_new_booking: 'Create New Booking',
                  label_add_location: 'Add New Location',
                  label_update_location: 'Edit Location',
                  label_total_num_of_passengers: 'Total Passenger(s)',
                  label_including_adult_wheel_child: 'Including adults and childs',
                  label_child_seat_required: 'Childseat(s)',
                  label_luggage_bags_required: 'Number of Bags(s)',
                  label_luggage_vehicle_required: 'Need a Luggage Vehicle?',
                  label_luggage_vehicle: 'Luggage Vehicle',
                  label_baby_seats: 'Babyseat(s)',
                  label_wheelchair_transported: 'Wheelchair(s)',
                  label_below_12years: 'Under 12 years or under 135cm height',
                  label_below_18months: 'Under 18 months or under 13Kg',
                  label_knockout_date_time: 'Knockout date & time',
                  label_void_date_time: 'Void date & time',
                  label_manufacture_year: 'Year of manufacture',
                  label_from_dispatch_location: 'From Dispatch Location',
                  label_to_dispatch_location: 'To Dispatch Location',
                  label_vehicle_to_transfer: 'Vehicle To Transfer',
                  label_acknowledge: 'Acknowledge',
                  label_sort_by: 'Sort by :',
                  label_sort_by_list: 'Sort by',
                  label_idle_time: 'Idle Time',
                  label_confirm: 'Confirm',
                  label_select_location: 'Select Location',
                  label_driver_confirmed: 'Driver confirmed',
                  label_driver_reconfirmed: 'Driver Reconfirm',
                  label_reconfirmed: 'Reconfirm',
                  label_admin_confirmed: 'Admin Confirmed',
                  label_location: 'Location',
                  label_remove_from_third_party: 'Remove from third party',
                  label_third_party_confirm: 'Please confirm to assign this journey to third party transport vendor',
                  label_autobroadcast_confirm: 'Please confirm to enable autobroadcast',
                  label_autobroadcast_reject: 'Please confirm to disable autobroadcast',
                  label_third_party_confirm_remove: 'Please confirm to reassign this journey to previous category',
                  label_booking_confirm_popup: 'Do you want to continue with this booking ?',
                  label_booking_replace_confirm_popup: `The original booking will be cancelled and replaced with this booking.
       Do you wish to continue?`,
                  label_booking_edit_confirm_popup: `Are you sure, you want to update this booking?`,
                  label_book_request: 'Book Request',
                  label_manual_override: 'Manual Override',
                  label_restricted_time_slot: 'Restricted Time Slot',
                  label_passcode: 'Passcode',
                  label_supervisor_passcode_text_message: 'Enter supervisor passcode to manual override',
                  label_booking_cancel_confirm: 'Are you sure want to cancel this booking ?',
                  label_emergency_ststus_confirm: 'Are you sure you want to change status ?',
                  label_raise_alert_confirm: 'Are you sure want to raise alert ?',
                  label_remove_alert_confirm: 'Are you sure want to remove alert ?',
                  label_remove_priority_confirm: 'Are you sure you want to remove the restrictions for the selected day?',
                  label_remove_driver_from_queue_confirm: 'Are you sure, you want to remove this driver from Queue?',

                  error_invalid_shift_group_name: 'Invalid shift group name',
                  error_color_type_required: 'Color type is required.',
                  error_color_required: 'Color is required.',
                  error_capacity_required: 'Capacity is required.',
                  error_vehicle_capacity_required: 'Vehicle capacity is required.',
                  error_invalid_vehicle_capacity_required: 'Invalid vehicle capacity.',
                  error_vehicle_code_required: 'Vehicle code is required.',
                  error_vehicle_reg_required: 'Vehicle is required.',
                  err_invalid_driver_code: 'Invalid driver code.',
                  error_vehicle_manufacturer_required: 'Vehicle manufacturer is required.',
                  error_invalid_vehicle_manufacturer: 'Invalid vehicle manufacturer.',
                  error_password_validation: `Password must contain 8 to 18 characters, including uppercase, lowercase letters,
       numbers and special characters.`,
                  invalid_old_password: 'Invalid Old password.',
                  error_invalid_short_code: 'Invalid short code.',
                  error_dispatch_zone_required: 'Dispatch zone is required.',
                  err_dispatch_zone_name_required: 'Dispatch zone name is required.',
                  err_invalid_dispatch_zone_name: 'Invalid dispatch zone name.',
                  err_shift_assign: 'Cannot assign shift, please activate the driver.',
                  error_child_age_required: 'Child age required',
                  error_booking_date_required: 'Please select valid time for ride later booking.',
                  error_same_pickup_drop: 'PickUp & drop location must be different.',
                  error_same_pickup_stop: 'PickUp & Stop location must be different.',
                  error_same_stop_drop: 'Stop & Drop location must be different.',
                  error_child_seat_exceed: 'Childseat + Babyseat count should be less than passenger count.',
                  error_select_passenger: 'Please select passenger.',
                  error_shutle_title_required: 'Shuttle title required.',
                  max_length_booking_note_150_char: 'Maximum 150 characters are allowed', // Max. 150 character allowed',
                  max_length_booking_note_250_char: 'Maximum 250 characters are allowed', // Max. 150 character allowed',
                  max_length_shuttle_title_note_50_char: 'Max. 50 character allowed',
                  error_status_change_fail: `Journey cannot be completed before reconfirmed.`,
                  error_security_code_required: `Security code required.`,
                  error_invalid_action_VT: `This operation is not allowed For Vehicle Transfer.`,

                  btn_create_new: 'Create New',

                  grid_status_dispatch_location: 'Status & Dispatch Location',
                  grid_status_queue_location: 'Status & Parking',
                  grid_pickup_time: 'Pickup time',
                  grid_drop_off_time: 'Drop Off Date & Time',
                  grid_passenger: 'Passenger',
                  grid_driver: 'Driver',
                  grid_pickup_drop_off: 'Pickup and drop off',
                  grid_info: 'Info',
                  grid_actions: 'Actions',
                  grid_status: 'Status',


                  msg_success_summary: 'Success Message',
                  msg_error_summary: 'Error Message',
                  msg_warning_summary: 'Warning Message',
                  msg_info_summary: 'Info Message',

                  label_parking_locations: 'Parking Location',

                  success_add_location: 'Location added successfully.',
                  success_update_location: 'Location updated successfully.',
                  success_shift_added: 'Shift group added successfully.',
                  success_shift_updated: 'Shift group updated successfully.',
                  success_duty_status_updated: 'Shift status updated successfully.',
                  success_driver_added_in_queue: 'Driver added in queue successfully.',
                  success_driver_removed_from_queue: 'Driver removed from queue successfully.',
                  success_passenger_add: 'Passenger added successfully.',
                  success_passenger_update: 'Passenger updated successfully.',

                  /* wimbeldon end */
                  title_user_manual: 'User Manual',
                  title_shift_management: 'Manage Shift',
                  title_driver_management: 'Driver Management',
                  title_vihicle_management: 'Manage Vehicles',
                  title_user_management: 'User Management',
                  title_content_management: 'Content Management',
                  title_settings: 'Settings',
                  title_reports: 'Reports',
                  title_queue_management: 'Queue Management',
                  title_manage_passengers: 'Manage Passengers',
                  title_manage_events: 'Manage Events',
                  title_broadcast_management: 'Broadcast Management',
                  title_manage_locations: 'Manage Locations',
                  title_manage_zones: 'Manage zones',
                  title_emergency_bookings: 'Emergency',
                  title_dashboard: 'Dashboard',
                  title_bookings: 'Bookings',
                  title_common: 'Mobisoft Event Transport',


                  label_all_groups: 'All Groups',
                  label_create_new_group: 'Create New Group',
                  label_zone_error: 'Please draw zone on map.',
                  label_zone_select_error: 'Please select region.',

                  label_all_passengers: 'All Passengers',
                  label_passenger_detail: 'Passenger Details',
                  label_from_to_todate: 'From Date -- To Date',
                  label_clear_search: 'Clear search',
                  label_shuttle_title: 'Shuttle title',
                  label_shuttle_Title: 'Shuttle Title',
                  label_prior_booking: 'View Prior Bookings',
                  label_prior_bookings_only: 'Prior Bookings',
                  label_view_days_bookings: 'View Prior Reservations History',//`View Day's Bookings `,
                  label_replace: 'Replace',
                  label_miles: 'Miles',
                  min_phone_length: 'Minimum 7 digits required.',
                  max_phone_length: 'Maximum 17 digits allowed.',
                  label_report_details: 'Report Details',
                  label_vehicle_mileage: 'Vehicle Mileage',
                  label_filters: 'Filters',

                  label_booking_time: 'Booking Time',
                  label_trip_confirmed_by_admin: 'Journey Confirmed by Admin',
                  label_trip_rejected_by_admin: 'Journey Rejected by Admin',
                  label_driver_assign: 'Driver Assign',
                  label_acknowledge_by_driver: 'Acknowledge By Driver',
                  label_driver_on_the_way: 'Driver On the Way',
                  label_driver_arrived: 'Driver Arrived',
                  label_trip_start: 'Journey Start',
                  label_trip_reassigned: 'Journey Reassigned',
                  label_trip_revoked: 'Journey Revoked',
                  label_trip_canceled_by_driver: 'Journey Canceled By Driver',
                  label_trip_canceled_by_passenger: 'Journey Canceled By Passenger',
                  label_passenger_unavailable: 'Passenger Unavailable',
                  label_trip_end: 'Journey End',
                  label_knockout_date: 'Knockout Date',
                  label_search_by_name_alias_name_phone: 'Search by Name or Login Id',
                  label_search_by_name_and_call_sign: 'Search by Name or Accreditation Id',
                  label_search_by_name: 'Search by name',
                  error_passenger_type_required: 'Passenger type is required.',
                  error_player_id_required: 'Player id is required.',
                  label_eligible_for_transport: 'Eligible for transport',
                  label_note_visible_for_drivers: '(This note is visible to driver)',
                  label_edit_booking_info: 'Edit booking Info',
                  label_edit_booking: 'Edit Booking',
                  label_on_duty: 'On Duty',
                  label_off_duty: 'Off Duty',
                  label_on_shift: 'On Shift',
                  label_off_shift: 'Off Shift',
                  label_change_password: 'Change Password',
                  msg_vehicle_with_driver_inactive_confirmation: `This vehicle is assigned to driver(s). Vehicle` +
                        ` deactivation will also deactivate assigned driver(s). Do you want to continue?`,
                  label_no_zone: 'No Zone',
                  label_driver_checkins: 'Driver Check In',
                  label_driver_call_sign: 'Driver Call Sign',
                  label_driver_user_id: 'User Id',
                  label_driver_login_id: 'Login Id',
                  label_shift: 'Shift',
                  label_checkin_datetime: 'Checkin Date and Time',
                  label_checkout_datetime: 'Checkout Date and Time',
                  // label_move_to_onduty_datetime: 'Time and Date moved to On Duty',
                  // label_move_to_offduty_datetime: 'Time and Date moved to Off Duty',
                  label_move_to_onduty_datetime: 'Duty Status On At',
                  label_move_to_offduty_datetime: 'Duty Status Off At',
                  label_off_duty_reason: 'Off Duty Reason',
                  label_shift_status_on_at: 'Shift Status On At',
                  label_shift_status_off_at: 'Shift Status Off At',
                  label_shift_status_on: 'Shift Status On',
                  label_duty_status_on: 'Duty Status On',
                  label_driver_duty_status_report: 'Driver Duty Status',
                  label_passengers_inbound_outbound_report: 'Passengers inbound outbound list',
                  label_segment_type: 'Segment Type',
                  label_status_parking: 'Status And Parking',
                  label_user_manual: 'User Manuals',
                  label_upload_document: 'Upload Document',
                  label_pdf_document: 'PDF Document',
                  label_document_name: 'Document Name',
                  // label_select_year: 'Select Year',
                  label_select_year: 'Select Year',
                  label_report_booking_summary: 'Journey Summary',
                  label_security_code: 'Security Code',
                  label_delete_user: 'Delete User',
                  label_export_data: 'Export Data',
                  label_trans_full: 'Trans Full',
                  error_transfull_required: 'Trans Full is required',
                  label_trans_airport: 'Trans Airport',
                  label_trans_spouse: 'Trans Spouse',
                  label_trans_notes: 'Trans Notes',
                  label_trans_startdate: 'Trans Start Date',
                  label_trans_startdate_required: 'Trans Start Date is required.',
                  label_trans_startdate_placeholder: 'Enter trans start date',


                  label_trans_enddate: 'Trans End Date',
                  label_trans_enddate_required: 'Trans End Date is required.',
                  label_trans_enddate_placeholder: 'Enter trans end date',


                  label_trans_startdate2: 'Trans Start Date2',
                  label_trans_startdate2_required: 'Trans Start Date2 is required.',
                  label_trans_startdate2_placeholder: 'Enter trans start date2',


                  label_trans_enddate2: 'Trans End Date2',
                  label_trans_enddate2_required: 'Trans End Date2 is required.',
                  label_trans_enddate2_placeholder: 'Enter trans end date2',

                  label_vip: 'VIP',
                  label_singles_seed: 'Singles Seed',
                  label_misc_attribute1: 'Misc  attribute 1',
                  label_misc_attribute2: 'Misc  attribute 2',
                  label_misc_attribute1_enter: 'Enter misc  attribute 1',
                  label_misc_attribute2_enter: 'Enter misc  attribute 2',
                  label_enter_hotel_name: 'Enter hotel name',
                  label_enter_trans_spouse: 'Enter trans spouse',
                  label_enter_trans_notes: 'Enter trans notes',
                  label_enter_played_id: 'Enter player id',
                  label_enter_badge_description: 'Enter badge description',
                  label_priority: 'Priority',
                  label_is_priority: 'Is Priority?',
                  label_is_accreditationActiveStatus: 'Is Accreditation Active Status?',
                  label_is_accreditationStatus: 'Accreditation Status',
                  label_registration: 'Registration Type',
                  label_single_seed: 'Single  Seed',
                  label_priority_restrictions: 'Priority Restrictions',
                  label_request_information: 'Request Information',
                  label_parking: 'Parking',
                  label_overridden_by_in_details: 'Restricted time slot overridden by',
                  label_requested_vehicle_type: 'Vehicle Type',
                  requested_vehicle_type: 'Requested Vehicle Type',
                  label_driver_info: 'Driver Info',
                  label_inbound: 'Inbound',
                  label_outbound: 'Outbound',
                  label_others: 'Others',
                  label_other: 'Other',
                  label_third_party: 'Third Party',
                  label_passenger_bookings_count: 'Passenger Bookings count',
                  label_startdate: 'Start Date',
                  label_enddate: 'End Date',
                  lavel_all_event: 'All Event',
                  lavel_all_incidents_complaince: 'All Incident & Complaint',

                  lavel_event_name_required: 'Event name is required.',
                  error_short_code_required: 'Short code is required.',
                  error_event_description_required: 'Event description is required.',
                  label_raised_by: 'Raised By',
                  label_raised_by_required: 'Raised By is required.',
                  label_journey_id_required: 'Journey Id is required.',
                  label_raised_by_type: 'Raised By Type',
                  label_import_incidents_complaints: 'Import Incidents & Complaints',
                  label_download_incidents_complaints: 'Download Incidents & Complaints',
                  label_bulk_import: 'Bulk Import',
                  label_download: 'Download Template',
                  label_upload: 'Upload',


                  label_all_shuttles: 'All Shuttles',
                  lable_add_new: 'Add New',
                  label_import_shuttles: 'Import Shuttles',
                  label_shuttle_code: 'Shuttle Code',
                  label_shuttle_type: 'Shuttle Type',
                  label_shuttle_image: 'Shuttle Image',
                  label_shuttle_color: 'Shuttle Color',
                  label_shuttle_capacity: 'Capacity',
                  label_vehicle_capacity: 'Capacity',
                  label_year_of_manufacture: 'Year of Manufacture',
                  label_shuttle_model: 'Model',
                  label_shuttle_image_size: 'Image size 100 x 100 px',
                  label_shuttle_details: 'Shuttle Details',
                  label_shuttle_drivers: 'Shuttle Drivers',
                  label_add_new_shuttle: 'Add New Shuttle',
                  label_edit_shuttle: 'Edit Shuttle',
                  lable_amenities: 'Amenities',
                  label_charging_point: 'Charging Point',
                  label_fridge: 'Fridge',
                  label_toilet: 'Toilet',
                  label_wheel_chair: 'Wheel Chair',
                  error_shuttle_type_required: 'Shuttle type is required.',
                  error_shuttle_code_required: 'Shuttle code is required.',
                  error_invalid_shuttle_code: 'Invalid shuttle code.',
                  error_shuttle_color_required: 'Shuttle color is required.',
                  error_invalid_shuttle_color: 'Invalid shuttle color.',
                  error_shuttle_model_required: 'Model is required.',
                  error_invalid_shuttle_model: 'Invalid model.',
                  error_shuttle_capacity_required: 'Capacity is required.',
                  error_invalid_shuttle_capacity: 'Invalid capacity.',
                  error_shuttle_manufacturer_year_required: 'Year of manufacturer is required.',
                  error_invalid_shuttle_manufacturer_year: 'Invalid year of manufacturer.',
                  error_year_digits: 'Year should be 4 digits',
                  error_shuttle_registration_number_required: 'Registration number is required.',
                  error_invalid_shuttle_registration_number: 'Invalid registration number.',

                  label_route_details: 'Route Details',
                  label_all_routes: 'All Routes',
                  label_add_new_route: 'Add New Route',
                  label_edit_route: 'Edit Route',
                  label_import_routes: 'Import Routes',
                  label_route_id: 'Route ID',
                  label_from_location: 'From Location',
                  label_from_location_start_point: 'From Location (Start Point)',
                  label_to_location: 'To Location',
                  label_to_location_destination_point: 'To Location (Destination Point)',
                  label_from_alias_name: 'From Alias Name',
                  label_to_alias_name: 'To Alias Name',
                  label_route_title: 'Route Title',
                  label_stop: 'Stop',
                  label_stop_alias_name: 'Stop alias name',
                  label_add_stop: 'Add Stop',

                  label_add_intermediate_stop: 'Add Intermediate Stop',
                  label_shuttle_information: 'Shuttle Information',
                  label_driver_not_found_shuttle: 'No assigned drivers found for this shuttle.',


                  error_route_title_required: 'Route title is required.',
                  error_from_location_required: 'From location is required.',
                  error_from_alias_name_required: 'From alias name is required.',
                  error_invalid_from_alias_name: 'Invalid from alias name.',
                  error_to_location_required: 'To location is required.',
                  error_invalid_to_alias_location: 'Inavalid to alias location.',
                  error_to_alias_name_required: 'To alias name is required.',
                  error_stop_required: 'Stop is required.',
                  error_stop_alias_required: 'Stop alias is required.',
                  error_invalid_stop_alias: 'Invalid stop alias.',

                  label_schedule_id: 'Schedule Id',
                  label_schedule_name: 'Schedule Name',
                  label_shuttle_schedules: 'Shuttle Schedules',
                  label_start_time: 'Start Time',
                  label_end_time: 'End Time',
                  label_import_schedules: 'Import Schedules',
                  label_update_schedules: 'Update Schedules',
                  label_import_shuttle_schedules: 'Import Shuttle Schedules',
                  label_update_shuttle_schedules: 'Update Shuttle Schedules',
                  label_view_schedule: 'View Schedule',
                  label_view_schedule_details: 'View Schedule Details',
                  label_schedule_details: 'Schedule Details',
                  label_bus_type: 'Bus Type',
                  label_active_days: 'Active Days',
                  label_monday_full: 'Monday',
                  label_tuesday_full: 'Tuesday',
                  label_wednesday_full: 'Wednesday',
                  label_thursday_full: 'Thursday',
                  label_friday_full: 'Friday',
                  label_saturday_full: 'Saturday',
                  label_sunday_full: 'Sunday',
                  label_monday: 'Mon',
                  label_tuesday: 'Tue',
                  label_wednesday: 'Wed',
                  label_thursday: 'Thu',
                  label_friday: 'Fri',
                  label_saturday: 'Sat',
                  label_sunday: 'Sun',
                  label_search_by_route_name_id: 'Search by route id or route name',
                  label_search_by_date: 'Search by date',
                  label_search_by_vehicle_code_regi_num: 'Search by Vehicle code or registration num…',

                  label_manage_operators: 'Manage Operators',
                  label_add_new_operator: 'Add New Operator',
                  lable_name: 'Name',
                  lable_mobile_no: 'Mobile No.',
                  label_advanced_reservation: 'Advanced Reservation',
                  label_total_reservations: 'Total Reservations',
                  label_search_by_operator_name: 'Search by operator name',
                  lable_advance_reservation_duration_days: 'Advance Reservation Duration (Days)',
                  label_address: 'Address',
                  label_manage_shuttle_bookings: 'Manage Shuttle Bookings',
                  label_ticket_no: 'Ticket No.',
                  label_ticket_number: 'Ticket Number',
                  label_journey_date: 'Journey Date',
                  label_journey_from_date: 'Journey From Date',
                  label_journey_to_date: 'Journey To Date',
                  label_reservation_date: 'Reservation Date',
                  label_reservation_status: 'Reservation Status',
                  label_view_reservation: 'View Reservation',
                  label_travel_information: 'Travel Information',
                  lable_departure_time: 'Departure Time',
                  label_drop_off_point: 'Drop Off Point',
                  label_boarding_point: 'Boarding Point',
                  label_total_seat: 'Total Seat',
                  label_opted_facilities: 'Opted Facilities',
                  label_passenger_information: 'Passenger Information',
                  label_seat: 'Seat',
                  label_member_id: 'Member ID',
                  label_open_till: 'Open Till',
                  label_checked_in: 'Checked In',
                  label_seat_details: 'Shuttle Seat details',
                  label_view_layouts: 'View Layouts',
                  label_manage_shuttle_layouts: 'Manage Shuttle Layouts',
                  label_layout_id: 'Layout Id',
                  label_layout_name: 'Layout Name',
                  label_deck: 'Deck',
                  label_seat_capacity: 'Seat Capacity',
                  label_search_by_layout_id_and_name: 'Search by layout name / layout id',
                  label_booking_id: 'Booking Id',
                  label_manage_shuttle_tours: 'Manage Shuttle Tours',
                  label_shuttle_no: 'Shuttle No',
                  label_reserved_seat: 'Reserved Seat',
                  label_view_tour_details: 'View Tour Details',
                  label_view_tour_list: 'View Tour List',
                  label_view_tour: 'View Tour',
                  label_tour_date: 'Tour Date',
                  label_deatils: 'Details',
                  label_assign_shuttle_tour: 'Assign Shuttle Tour',
                  label_assign_shuttle: 'Assign Shuttle',
                  label_luggage_request: 'Luggage Request',
                  label_special_request: 'Special Request',
                  label_accept_luggage: 'Accept Luggage',
                  label_decline_luggage: 'Decline Luggage',
                  // lavel_event_name_required:'Event name is required.',
                  // error_short_code_required:'Short code is required.',
                  // error_event_description_required:'Event description is required.',
                  // label_raised_by:'Raised By',
                  // label_raised_by_required:'Raised By is required.',
                  // label_journey_id_required:'Journey Id is required.',
                  // label_raised_by_type:'Raised By Type',
                  // label_import_incidents_complaints:'Import Incidents & Complaints',
                  // label_download_incidents_complaints:'Download Incidents & Complaints',
                  // label_bulk_import:'Bulk Import',
                  // label_download:'Download',
                  // label_upload:'Upload',
                  // label_luggage_request:'Luggage Request',
                  // label_accept_luggage:'Accept Luggage',
                  // label_decline_luggage:'Decline Luggage',

                  label_search_by_google: 'Search by Google',
                  label_transport_mode: 'Transport Mode',
                  error_transport_mode: 'Transport Mode is required.',
                  label_event_list: 'Event List',
                  error_event_list: 'Event list is required.',
                  error_accreditationId_required: 'Accreditation Id is required.',
                  label_shuttle_mobile_no: 'Mobile No.',
                  label_shuttle_operator: 'Operator',
                  label_shuttle_select_operator: 'Select Operator',
                  label_edit_driver: 'Edit Driver',
                  error_shuttle_operator_required: 'Operator is required.',
                  error_operator_name_required: 'Operator name is required.',
                  error_invalid_operator_name: 'Invalid operator name.',
                  error_advances_reservation_duration_required: 'Advance reservation duration is required.',
                  error_advances_reservation_duration_digits: 'Advance reservation duration must be in digits.',
                  error_name_is_required: 'Name is required.',
                  error_mobile_no_is_required: 'Mobile no is required.',
                  error_email_is_required: 'Email is required.',
                  error_address_is_required: 'Address is required.',
                  error_invalid_address: 'Invalid Address.',
                  error_first_name_is_required: 'First Name is required.',
                  error_last_name_is_required: 'Last Name is required.',
                  label_seats_allowed_to_book: 'Shuttle Seats Allowed To Book',
                  error_events_is_required: 'Events is required.',
                  label_transport_privilege: 'Transport Privilege',
                  label_transport_privilege_required: 'Transport Privilege is required.',
                  label_passenger_category: 'Passenger Category',
                  error_passenger_category_required: 'Passenger Category is required.',

                  error_radio_number_required: 'Radio Number is required.',
                  error_invalid_radio_number: 'Invalid radio number.',
                  event_change_confirmation: 'Changing event will re-load the admin portal. Are you sure you want to continue?',
                  event_change_confirmation_add_update: 'Creating or Modifying event will re-load the admin portal. Are you sure you want to continue?',
                  event_change_confirmation_without_sure: 'Creating or Modifying event will re-load the admin portal.',

                  error_seats_allowed_to_book_is_required: 'Shuttle Seats Allowed To Book is required.',
                  error_country_is_required: 'Country is required.',
                  error_city_is_required: 'City is required.',
                  label_first_name: 'First Name',
                  label_last_name: 'Last Name',
                  label_postal_code: 'Postal Code',
                  error_postal_code_is_required: 'Postal Code is required.',
                  label_download_sample: 'Download Sample',
                  label_delete_schedule_msg: 'Are you sure do you really want to delete this schedule?',
                  label_save_assign_tour_info: 'Are you sure do you really want to save the changes?',
                  label_start_tour_info: 'Are you sure do you really want to start the tour?',
                  label_cancel_tour_info: 'Are you sure do you really want to cancel the tour?',
                  label_end_tour_info: 'Are you sure do you really want to end the tour?',
                  label_confirm_cancel_booking: 'Are you sure do you really want to cancel this booking?',
                  label_tour_reason_for_cancel: 'Enter reason for trip cancellation. This reason will be notified to the passengers (required)',
                  label_events: 'Events',
                  label_cancellation_note: 'Note: Cancellations must be made at least one hour before your scheduled departure to receive a refund.',
                  label_driver_details: 'Driver Details',
                  label_shuttle_driver_trip_history: 'Trip History',
                  label_transport_privilage: 'Transport Privilege',
                  label_passenger_Category: 'Passenger Category',
                  label_pritority_user: 'Priority User',
                  label_accredation_active_status: 'Accreditation Active Status',
                  label_invalid_journey_id: 'Journey Id',
                  error_label_user_code_required: 'User Code is required.',
                  error_invalid_label_user_code: 'Invalid User Code.',
                  emenrgency_number: 'Emergency Number',
                  invalid_emenrgency_number: 'Invalid Emergency Number',
                  emenrgency_number_require: 'Emergency Number is required.',
                  label_transport_superwisor_number: 'Transport Supervisor Number',
                  label_transport_superwisor_number_require: 'Transport Supervisor Number is required.',
                  error_invalid_transport_superwisor_number: 'Transport Supervisor Number is invalid.',
                  min_length_transport_number: 'Transport supervisor number should contain minimum 7 digit.',
                  max_length_transport_number: 'Transport supervisor number  should contain maximum 13 digit.',
                  min_length_emergency_number: 'Emergency number should contain minimum 7 digit.',
                  max_length_emergency_number: 'Emergency number  should contain maximum 13 digit.',
                  label_request_broadcast_radius: 'Request Broadcast Radius',
                  label_request_broadcast_radius_require: 'Request Broadcast Radius is required.',
                  invalid_request_broadcast_radius_number: 'Invalid Request Broadcast Radius.',
                  label_service_area_radius: 'Service Area Radius',
                  label_service_area_radius_require: 'Service Area Radius is required.',
                  invalid_service_area_radius_number: 'Invalid Service Area Radius.',
                  label_accomodation_radius: 'Accomodation Radius',
                  label_accomodation_radius_require: 'Accomodation Radius is required.',
                  invalid_accomodation_radius_number: 'Invalid Accomodation Radius.',
                  label_shuttle_search_by_driver_name_operator: 'Search by driver name and operator name',
                  label_number_of_passenger_allowed: 'Number of passenger allowed',
                  error_number_of_passenger_allowed_is_required: 'Number of passenger allowed is required.',
                  label_is_wheelchair_required: ' Is wheelchair required (Yes/No)',
                  label_search_by_schedule_name: 'Search by schedule name',
                  label_search_by_booking_id: 'Search by from and to location',
                  label_privilage: 'Privilege',
                  label_search_by_shuttle_code_and_registration_number: 'Search by shuttle code and Registration Number',
                  label_shuttle_tour_details: 'Tour Details',
                  error_max_length_capacity_allow_passenger: 'Number of allowed passenger should be less than or equal to shuttle capacity',
                  error_max_reservation_duration: 'Maximum reservation duration should be 365 day.',
                  error_min_reservation_duration: 'Minimum reservation duration should be 1 day.',
                  error_enter_only_number: 'Only number allowed',
                  error_invalid_title: 'Inavlid Title',
                  reason_required: 'Reason is required.',
                  label_auto_broadcast: 'Autobroadcast',
                  label_add_pickup_stop: '+ Add Stop',
                  label_remove_pickup_stop: '- Remove Stop',
                  label_stop_location: 'Stop Location',
                  label_booked_on: 'Booked On',
                  label_boarding_details: 'Boarding Details',
                  label_arrival_details: 'Arrival Details',
                  label_arrival_point: 'Arrival Point',
                  label_check_in :'Check-In Status',
                  label_check_in_time :'Check-In Time',
                  label_onboard_status: 'Onboard Status',
                  label_set:'Set',
                  label_shuttle_booking_allowed_for_passenger:'Shuttle Bookings',
                  label_vehicle_model:'Vehicle Model',
                  label_processing:'Processing...'
                  


            });
      }_
}
