import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';

import { AppSettings } from '../../../shared/app.settings';
import { UserCommonService } from './../../services/user-common.service';
import { ErrorBindingService } from '../../services/error-binding.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  @Output() onPopClose: EventEmitter<any> = new EventEmitter();
  @Output() onPopSave: EventEmitter<any> = new EventEmitter();
  forgotPasswordErrors = [
    {
      username: false
    }
  ];
  message: Message[] = [];
  editforgotForm = new FormGroup({
    username: new FormControl()
  });

  constructor(
    private formBuilder: FormBuilder,
    private commonServices: UserCommonService,
    private errorBindingService: ErrorBindingService
  ) { }

  ngOnInit() {
    this.editforgotForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
    });
  }

  closeEditForgot(event) {
    this.editforgotForm.reset();
    this.onPopClose.emit();
  }

  changePassword(event) {
    this.message = [];
    let input;
    let otpEmail;
    let otpPhone;

    const userName = this.editforgotForm.controls.username.value;
    if (userName.match(AppSettings.EMAIL_PATTERN)) {
      otpEmail = userName;
      input = {
        email: userName,
        tenantId: AppSettings.TENANT
      };
    } else if (userName.match(AppSettings.PHONE_PATTERN)) {
      otpPhone = userName;
      input = {
        phoneNum: userName,
        tenantId: AppSettings.TENANT
      };
    } else {
      input = {
        username: userName,
        tenantId: AppSettings.TENANT
      };
    }
    this.commonServices.forgotPassword(JSON.stringify(input)).subscribe(results => {
      results.email = otpEmail;
      results.phone = otpPhone;
      this.onPopSave.emit(results);
      this.editforgotForm.reset();
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.forgotPasswordErrors);
    });
  }
}
