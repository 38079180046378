<div class="page-header-menu">
  <div class="company-logo left-panel">
    <img style="max-width: 170px;" src="/assets/images/temp/fifa_header.png">
  </div>
  <div class="right-panel">
    <div class="app-header ui-g-4 ui-md-4 ui-lg-4 ui-g-nopad">
      <div class="top-bar-item">
        <a (click)="onSideMenuClick($event)" [title]="'label_toggle_menu' | translate" class="hamburger-menu">
          <i class="fa fa-bars"></i>
        </a>
      </div>
      <div class="top-bar-item ausHeader" style="width: 70%;padding-top: 4px;">
        <p-dropdown [options]="eventListOptions" class="transperentDropdown" [(ngModel)]="selectedEvent"
          (onChange)="changeFilter($event)">
          <ng-template let-item pTemplate="selectedItem">
            <span><img [src]="uploadedImagePath + item.imageId" style="width:26px;vertical-align:middle" /></span>
            <span style="vertical-align:middle; margin-left: .5em;font-size: 14px;font-weight: bold;">{{item.label}}</span>
          </ng-template>
          <ng-template let-event pTemplate="eventListOptions">
            <div class="country-item" style="display:-webkit-inline-flex;">
              <img [src]="uploadedImagePath+event.imageId" [class]="'flag flag-'" style="max-width: 30px;" />
              <div style="padding: 8px;font-size: 16px;font-weight: 500;">{{event.label}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="ui-g-8 ui-md-8 ui-lg-8 ui-g-nopad profile-panel">

      <div class="nav-right-menu">

        <!-- bell with count -->
        <!-- <div class="top-bar-item notifications-bell" [title]="'label_notifications' | translate" (click)="getNotificationListAndToggle($event)">
          <a class="nav-notifications-icon">
            <i class="fa fa-bell-o" aria-hidden="true"></i>
            <div class="new-notification-count" *ngIf="unReadNotificatonsCount">
              {{unReadNotificatonsCount}}
            </div>
          </a>
        </div> -->

        <div class="top-bar-item notifications-bell" [title]="'label_notifications' | translate"
          (click)="getNotificationListAndToggle($event)">
          <a [ngClass]="unReadNotificatonsCount?'nav-notifications-icon-with-dot':'nav-notifications-icon'">
          </a>
        </div>

        <div class="profile-menu-button top-bar-item">
          <p-menu #menu popup="popup" [model]="items"></p-menu>
          <a (click)="menu.toggle($event)">
            <img class="user-photo" src="{{profileUrl}}" onerror="src='/assets/images/temp/default_profile.png'">
            {{userFullName}}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="hasShowNotificationBar" class="notification-sidebar" position="right">
  <div class="notification-container">
    <div class="header">{{notificationLabel}}</div>
    <div class="content">
      <app-notification-mg1 [notifications]="notifications" (loadMoreNotification)="loadMoreNotification()">
      </app-notification-mg1>
    </div>
  </div>
</p-sidebar>

<p-dialog modal="true" styleClass="add-terms-popup" [(visible)]="changePasswordPopup" [closable]="false"
  [contentStyle]="{'width':'600px'}">
  <p-header translate>
    label_change_password
  </p-header>
  <app-change-password [(changePasswordPopup)]="changePasswordPopup"
    (isShowChangePassword)="onCancelChangePassword($event)"></app-change-password>
</p-dialog>

<p-dialog modal="false" styleClass="edit-profile-popup" position="center center" responsive="true"
  [(visible)]="editProfilePopup" [contentStyle]="{'overflow':'visible'}">
  <p-header>
    Edit Profile
  </p-header>
  <app-edit-profile [(editProfilePopup)]="editProfilePopup" (showProfilePopup)="onCancelEditProfile($event)">
  </app-edit-profile>
</p-dialog>
<p-growl [value]="message"></p-growl>
<!-- <p-confirmDialog header="Confirmation" icon="fa fa-question-circle">
</p-confirmDialog> -->