<div class="signin-box-container scroll-bar-css export-page ui-g">
 
  <!-- New Sign In Page -->
  <div class="signin-box-inner-container" style="display: block;">
    <div class="ui-g-12 ui-g-nopad sign-in-text">
      <div class="ui-lg-6 ui-lg-offset-3 ui-md-8 ui-md-offset-2 ui-sm-12 ui-sm-offset-0 sign-in-content">
        Export Data</div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-12 ui-md-4 ui-lg-4 ui-sm-12 ui-lg-offset-4 ui-md-offset-4 ui-sm-offset-0 ui-g-nopad">
        <p-panel>
          <div class="form-field ui-g-12 ui-g-nopad">
            <div class="text-center"><img src="/assets/images/temp/wim-logo.png"></div>
            <div class="form-field ui-g-12 switch-btn" style="padding-left: 0;" translate>label_export_data_heading</div>
          </div>
        
          <div class="form-field ui-g-12">
            <div class="form-errors alert alert-danger">
              <!-- <div *ngFor="let ErrorMessage of loginErrors['general']">
                {{ErrorMessage.message}}
              </div> -->
            </div>
          </div>
          <div class="form-field ui-g-12">
            <div class="form-errors alert alert-danger">
              <!-- <div *ngFor="let ErrorMessage of loginErrors['email']">
                {{ErrorMessage.message}}
              </div> -->
            </div>
          </div>
         
          <form [formGroup]='signInForm'>
           
            <div class="form-field  ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="email-label" translate>label_security_code</label>
              </div>
              <div class="form-control">
                <div class="form-control password-show">
                  <input pInputText [type]="passwordType" id="password" formControlName="securityCode" class="full-width-transperent password-field"
                    (keypress)="eventHandler($event)" />
                  <span class="eye-icon sign-in-eye" (click)="tooglePasswordType()">
                    <i *ngIf="passwordType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                    <i *ngIf="passwordType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
                  </span>
                </div>
                <div class="form-errors" *ngIf="signInForm.controls.securityCode.errors  && (signInForm.controls.securityCode.dirty || signInForm.controls.securityCode.touched)">
                  <div [hidden]="!signInForm.controls.securityCode.errors.required" translate>
                    error_security_code_required
                  </div>
                  <div [hidden]="!signInForm.controls.securityCode.errors.minlength" translate>
                    securityCode_too_short
                  </div>
                  <div [hidden]="!signInForm.controls.securityCode.errors.maxlength" translate>
                    exceed_max_length
                  </div>
                </div>
              </div>
            </div>
          
            <div class="form-field ui-g-12">
              <div class="form-field ui-g-12 ui-g-nopad">
                <img src=''>
              </div>
              <div class="form-field ui-g-12 ui-g-nopad">
                <button pButton appRipple label="{{signInLabel}}" class='sign-in-buttons' type="button" [disabled]="!signInForm.valid"
                  (click)="export()"></button>
              </div>
          
            </div>
          </form>

        </p-panel>

      </div>
    </div>

  </div>
  <!-- End Sign In Page -->
</div>

<p-growl [value]="message"></p-growl>