<div class="track-my-location-container ui-g">
  <div class="track-my-location">

    <div class="header ui-g-12 ui-md-12 ui-lg-12 ui-sm-12">
      <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12">
        <a href="{{locationDetails.tenantInfo.siteName}}" target="_blank">
          <img width="200" src="/assets/images/temp/header_logo.png">
        </a>
      </div>
      <div *ngIf="showTrackMyLocationPage" class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12 contact-info">
        <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-6">
          <strong translate>label_contactNumber</strong>:&nbsp;
          <span>{{locationDetails.tenantInfo.tenantPhoneNum}}</span>
        </div>
        <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-6">
          <strong translate>label_supportEmail</strong>:&nbsp;
          <span translate>{{locationDetails.tenantInfo.tenantEmail}}</span>
        </div>
      </div>
    </div>

    <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
      <div class="seperator"></div>
    </div>
    <div>
      <div *ngIf="showTrackMyLocationPage" class="location-info ui-g-12 ui-md-12 ui-lg-12">

        <div class="ui-g-2 ui-md-12 ui-lg-2 ui-sm-12 content">
          <img class="driver-logo" src="{{imageUrl}}">
        </div>

        <div *ngIf="locationDetails.tourBookingDetailsDto !== undefined && locationDetails.tourBookingDetailsDto !== null">
          <div *ngIf="locationDetails.tourBookingDetailsDto.driverInfo !== undefined && locationDetails.tourBookingDetailsDto.driverInfo !== null"
            class="ui-g-2 ui-md-7 ui-lg-2 ui-sm-6 content">
            <div class="content-header" translate>label_driver</div>
            <div class="content-info">
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.driverInfo.firstName}}
                {{locationDetails.tourBookingDetailsDto.driverInfo.lastName}}</div>
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.driverInfo.numCountryCode}}-{{locationDetails.tourBookingDetailsDto.driverInfo.phoneNum}}</div>
              <p class="data-info">{{locationDetails.tourBookingDetailsDto.driverInfo.email}}</p>
              <div class="star">
                <span *ngFor="let star of maxRating;let idx = index">
                  <i *ngIf="idx < locationDetails.tourBookingDetailsDto.driverInfo.driverRating" class="fa fa-star active"></i>
                  <i *ngIf="idx >= locationDetails.tourBookingDetailsDto.driverInfo.driverRating" class="fa fa-star"></i>
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="locationDetails.tourBookingDetailsDto.passengerInfo !== undefined && locationDetails.tourBookingDetailsDto.passengerInfo !== null"
            class="ui-g-2 ui-md-5 ui-lg-2 ui-sm-6 content">
            <div class="content-header" translate>label_passenger</div>
            <div class="content-info">
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.passengerInfo.firstName}}
                {{locationDetails.tourBookingDetailsDto.passengerInfo.lastName}}</div>
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.passengerInfo.numCountryCode}}-{{locationDetails.tourBookingDetailsDto.passengerInfo.phoneNum}}</div>
              <p class="data-info">{{locationDetails.tourBookingDetailsDto.passengerInfo.email}}</p>
            </div>
          </div>
          <div *ngIf="locationDetails.tourBookingDetailsDto.vehicleInfo!== undefined && locationDetails.tourBookingDetailsDto.vehicleInfo !== null"
            class="ui-g-3 ui-md-12 ui-lg-3 ui-sm-12 content" style="margin-bottom: 11px;">
            <div class="content-header" translate>label_car_details</div>
            <div class="content-info">
              <table>
                <tbody>
                  <tr>
                    <td translate>label_car_type_with_colon </td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.vehicleType}} </td>
                  </tr>
                  <tr>
                    <td translate>label_model_with_colon</td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.makeModel}}</td>
                  </tr>
                  <tr>
                    <td translate>label_vehicle_type_with_colon</td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.vehicleType}} </td>
                  </tr>
                  <tr>
                    <td translate>label_plate_number_with_colon </td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.vehicleNumber}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="locationDetails.tourBookingDetailsDto.tourBookingInfo !== undefined && locationDetails.tourBookingDetailsDto.tourBookingInfo !== null"
            class="ui-g-3 ui-md-12 ui-lg-3 ui-sm-12 content">
            <div class="content-header" translate>label_trip_details</div>
            <div class="content-info">
              <p>
                <i class="fa fa-circle start" style="margin-right: 5px;"></i>{{locationDetails.tourBookingDetailsDto.tourBookingInfo.pickupLocationName}}</p>
              <p>
                <i class="fa fa-circle end" style="margin-right: 5px;"></i>{{locationDetails.tourBookingDetailsDto.tourBookingInfo.dropLocationName}}</p>
              <div>
                <span translate>label_status_with_colon</span>
                <span class="data-info" style="font-weight: bold;color:green;">{{tourStatus}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Map -->
      <div class="ui-g-12 ui-md-12 ui-lg-12 ui-sm-12 map-container">
        <div id="trackLocationMap" class="map"></div>
      </div>

    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-sm-12">
      <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12" style="text-align: center;">
        <p>
          We offer Android and iOS Application
          <br> to book taxi at your doorsteps.
        </p>
        <p>
          It is easy to use and book as your requirement
        </p>
      </div>
      <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12">
        <div class="ui-g-1 ui-md-1 ui-lg-1"></div>
        <div class="ui-g-5 ui-md-5 ui-lg-5 ui-sm-12">
          <a href="{{locationDetails.tenantInfo.appStoreUrl}}" target="_blank">
            <img src="/assets/images/temp/app_store.png">
          </a>
        </div>

        <div class="ui-g-5 ui-md-5 ui-lg-5 ui-sm-12">
          <a href="{{locationDetails.tenantInfo.playStoreUrl}}" target="_blank">
            <img src="/assets/images/temp/play_store.png">
          </a>
        </div>
        <div class="ui-g-1 ui-md-1 ui-lg-1 ui-sm-12"></div>
      </div>
    </div>

    <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
      <div class="seperator"></div>
    </div>

    <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12 ui-sm-12">
      <!-- <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12" style="text-align: center;margin-top: 17px;">
        &#9400; Copyright 2019 by Mobisoft. All Rights Reserved.
      </div> -->
      <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12" style="text-align: center;">
        <div class="ui-g-4 ui-md-4 ui-lg-4 ui-sm-12"></div>
        <div class="ui-g-1 ui-md-1 ui-lg-1 ui-sm-2">
          <a href="{{locationDetails.tenantInfo.facebookUrl}}" target="_blank">
            <img src="/assets/images/temp/footer_fb_icon.png">
          </a>
        </div>

        <div class="ui-g-1 ui-md-1 ui-lg-1 ui-sm-2">
          <a href="{{locationDetails.tenantInfo.twitterUrl}}" target="_blank">
            <img src="/assets/images/temp/footer_twitter_icon.png">
          </a>
        </div>

        <div class="ui-g-5 ui-md-5 ui-lg-5 ui-sm-12"></div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!showTrackMyLocationPage" style="position: absolute; top: 50%; left: 30%;text-align: center; font-size: 16px;">
  {{errorMessage}}
</div>