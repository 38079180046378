import { Injectable } from '@angular/core';

import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { StorageService } from './../../shared/services/storage.service';
import { AppSettings } from './../../shared/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class CommonBindingDataService {
  previousScrollTop;
  accesses: any;
  manualBookingData: any = {};
  rideType: any;
  carddetails: any = {};
  secretKey = AppSettings.SECRET_KEY;

  public redrawSidebar: Subject<any> = new Subject<any>();
  public bookingTabCountChange = new Subject<any>();
  public eventId = new Subject<any>();
  public checkLoginUser = new Subject<any>();

  public changedEventId;
  public bookingGridRefresh = new BehaviorSubject<any>(null);

  constructor(private restApiService: RestApiService,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) {

  }

  getServiceType(): Observable<any> {
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      return of(this.storageService.getItem(AppSettings.SERVICE_TYPE));
    } else {
      return this.restApiService.get('/secure/service-types?clientType=WEB');
    }
  }

  toGMT(now) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  toGMTTime(time) {
    const offset = new Date().getTimezoneOffset() * 60000;
    const gmtTime = time + offset
    if (gmtTime >= 0) {
      return gmtTime;
    } else {
      return ((24 * 3600000) + gmtTime - 1);
    }
  }

  toGMTTime1(time) {
    const offset = new Date().getTimezoneOffset() * 60000;
    const gmtTime = time + offset
    if (gmtTime >= 0) {
      return gmtTime;
    } else {
      return ((24 * 3600000) + gmtTime);
    }
  }


  toGMTTimeOnly(time) {
    const offset = new Date().getTimezoneOffset() * 60000;
    let gmtTime = time + offset;
    if (gmtTime >= 24 * 3600000) {
      gmtTime = gmtTime - 24 * 3600000;
    } if (gmtTime < 0) {
      gmtTime = 24 * 3600000 + gmtTime;
    }

    if (gmtTime > 0) {
      return gmtTime;
    } else if (gmtTime === 0) {
      return ((24 * 3600000) + gmtTime);
    } else {
      return ((24 * 3600000) + gmtTime - 1);
    }
  }



  toCurrentTime(time) {
    const offset = new Date().getTimezoneOffset() * 60000;
    const gmtTime = time - offset
    if (gmtTime >= 24 * 3600000) {
      return (gmtTime - 24 * 3600000);
    } else {
      return gmtTime;
    }
  }

  toLocalTime(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  toLocalDate(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }


  unitConversionMToKm(m) {
    const results = m / 1000;
    if (results > 0) {
      return results.toFixed(2) + ' ';
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km) {
    const results = km * 1000;
    if (results > 0) {
      return results.toFixed(2);
    } else {
      return 0;
    }
  }

  unitConversionKMToM1(km) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionmillisToTime(ms) {
    let milliseconds = +((ms % 1000) / 100);
    let seconds = (Math.floor((ms / 1000) % 60)),
      minutes = Math.floor((ms / (1000 * 60)) % 60),
      hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    let convertedHours = (hours < 10) ? "0" + hours : hours;
    let convertedMinutes = (minutes < 10) ? minutes : minutes;
    let convertedSeconds = (seconds < 10) ? "0" + seconds : seconds;

    if (hours >= 1) {
      return convertedHours + ' Hour(s) :' + convertedMinutes + ' Min(s)';
    }
    if (minutes < 60) {
      if (minutes < 1) {
        return 1 + ' Min(s)';
      }
      return convertedMinutes + ' Min(s)';
    }
    // return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  }


  getBookLaterMinTime(serviceType, subcode) {
    const extraTime = this.getSpecificTimeout(serviceType, subcode);
    return new Date((new Date).getTime() + extraTime);
  }

  unitConversionMilisecondsToMin(mili) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  meterToDefaultDistanceType(meter: any) {
    if (meter !== null) {
      return meter / 1000;
    }

    return 0;
  }

  kilometerToDefaultDistanceType(km: any) {
    if (km !== null) {
      return km * 1000;
    }

    return 0;
  }

  addLapseTimeInEndDate(endDate) {
    return endDate + AppSettings.twentyThreeFiftyNineLapse;
  }

  centerLatLng(dropPoints) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray, latArray) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type, data) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }

  getServiceTypeKey(id) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          serviceTypeId = element.serviceType;
        }
      });
    }
    return serviceTypeId;
  }

  getTenantConfigValue(configKey) {
    let tenantConfig: any;
    tenantConfig = this.storageService.getItem(AppSettings.TENANT_CONFIG);
    if(tenantConfig) {
      for (let index = 0; index < tenantConfig.length; index++) {
        if (tenantConfig[index].configKey === configKey) {
          return tenantConfig[index].configValue;
        }
      }
    }
    return null;
  }


  getServiceTypeCodeBy(id) {
    let serviceType, code;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          code = element.code;
        }
      });
    }
    return code;
  }

  getServiceTypeByCode(code) {
    let serviceType;
    const serviceTypeByCode = [];
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.code === code) {
          serviceTypeByCode.push(element);
        }
      });
    }
    return serviceTypeByCode;
  }

  getServiceTypeId(key) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.serviceType === key) {
          serviceTypeId = element.id;
        }
      });
    }
    return serviceTypeId;
  }


  removeGivenServiceTypefromList(key, serviceTypeList?) {
    let serviceTypes;
    if (serviceTypeList) {
      serviceTypes = serviceTypeList;
    } else {
      serviceTypes = this.storageService.getItem(AppSettings.SERVICE_TYPE);
    }
    serviceTypes.forEach((item, index) => {
      if (key === item.subCode) {
        serviceTypes.splice(index, 1);
      }
    });
    return serviceTypes;
  }

  getSpecificTimeout(code, subcode) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }


  getServiceTypeOnLogin(): Observable<any> {
    return this.restApiService.get('/secure/service-types?clientType=WEB', 'page-center');
  }

  getDocumentType(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/driver-document-types?eventId=${eventId ? eventId : ''}`, 'page-center');
  }

  getVehicleType(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/vehicle-types?eventId=${eventId}`, 'page-center');
  }

  getDurationType(): Observable<any> {
    return this.restApiService.get('/secure/package/duration-types', 'page-center');
  }

  getVehicleTypeByServiceId(serviceId?, eventId?): Observable<any> {
    return this.restApiService.get('/secure/vehicle-types?serviceTypeIds=' + serviceId + `&eventId=${eventId ? eventId : ''}`, 'page-center');
    // return this.restApiService.get('/secure/v1/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');

  }
  getVehicleTypeByServiceIdV1(serviceId?, eventId?): Observable<any> {
    // return this.restApiService.get('/secure/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
    return this.restApiService.get('/secure/v1/vehicle-types?serviceTypeIds=' + serviceId + '&eventId=' + (eventId ? eventId : ''), 'page-center');

  }

  getSupportData(): Observable<any> {
    return this.restApiService.get('/tenants/contact-support', 'page-center');
  }

  getVehiclesByCompanyAndVehicleType(vehicleTypeId, companyId?: any, serviceCities?, serviceId?, eventId?): Observable<any> {
    if (companyId !== null && companyId !== undefined) {
      return this.restApiService.get('/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=' + companyId, 'page-center');
    } else {
      return this.restApiService.get('/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=' + '&serviceAreaIds=' + serviceCities + '&serviceTypeIds=' + serviceId + '&eventId=' + (eventId ? eventId : ''), 'page-center');
    }
  }

  getVehicleDocumentType(): Observable<any> {
    return this.restApiService.get('/secure/vehicle-document-types', 'page-center');
  }

  getCountries(): Observable<any> {
    return this.restApiService.get('/secure/countries', 'page-center');
  }

  getState(id): Observable<any> {
    return this.restApiService.get(`/secure/countries/${id}/states`, 'page-center');
  }
  getCitybyCountries(code): Observable<any> {
    return this.restApiService.get('/secure/countries/' + code + '/citiesbycountry', 'page-center');
  }

  getCitybyState(code): Observable<any> {
    return this.restApiService.get('/secure/countries/' + code + '/citiesbystate', 'page-center');
  }

  getCitybyZipCode(zipCode, countryCode?: any): Observable<any> {
    return this.restApiService.get('/secure/countries-city?zipCode=' + zipCode + '&countryCode=' + countryCode, 'page-center');
  }

  getDispatcher(data, code): Observable<any> {
    return this.restApiService.get(`/secure/dispatchers/data/search?searchText=${data}&roleCode=${code}`, 'page-center');
  }

  getPackageData(packageId): Observable<any> {
    return this.restApiService.get('/secure/package/' + packageId, 'page-center');
  }

  getVehiclePackageData(packageId): Observable<any> {
    return this.restApiService.get('/secure/vehicle-types/default-package/' + packageId, 'page-center');
  }


  updatePackage(packageId, data): Observable<any> {
    return this.restApiService.put('/secure/vehicle-types/default-package/' + packageId, data, 'page-center');
  }


  commentReply(data, id): Observable<any> {
    return this.restApiService.post(`/secure/booking/trip/${id}/comment-reply`, data, 'page-center');
  }

  searchPassengers(url: string): Observable<any> {
    return this.restApiService.get(url, 'page-center');
  }

  readNotifications(notificationId, readStatus): Observable<any> {
    return this.restApiService.put(`/secure/notification/${notificationId}/status`, readStatus, 'page-center');
  }

  addCorporateBooking(data, serviceType): Observable<any> {
    return this.restApiService.post(`/secure/booking/${serviceType}/corporate`, data, 'page-center');
  }

  addPackage(data): Observable<any> {
    return this.restApiService.post(`/secure/vehicle-types/default-package`, data, 'page-center');
  }


  addHotelBooking(data, serviceType, hotelId): Observable<any> {
    return this.restApiService.post(`/secure/hotels/${hotelId}/booking/${serviceType}`, data, 'page-center');
  }

  getLabel(string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  getTiming(eventId?) {
    return this.restApiService.get(`/secure/settings/timeouts?eventId=${eventId}`, 'page-center');
  }

  packageBind(serviceType, passengerId?, vehicleTypeId?): Observable<any> {
    if (vehicleTypeId !== null && vehicleTypeId !== undefined) {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' +
        serviceType + '?vehicleTypeId=' + vehicleTypeId + '&passengerId=' + passengerId;
      return this.restApiService.get(url);
    } else {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType;
      return this.restApiService.get(url);
    }
  }

  getPricingDetails(data) {
    return this.restApiService.get('/secure/packages/' + data, 'page-center');
  }


  setPackages(data, id?, corporateId?): Observable<any> {
    return this.restApiService.put(`/secure/packages/${id}/partners/${corporateId}`, data, 'page-center');
  }

  getRentalPricingDetails(params) {
    return this.restApiService.get('/secure/packages/rental/partners/' + params, 'page-center');
  }

  updateRentalPackage(data, corporateId) {
    return this.restApiService.post(`/secure/packages/rental/partners/${corporateId}`, data, 'page-center');
  }

  updateRentalPackageById(id, data) {
    return this.restApiService.put(`/secure/package/${id}`, data, 'page-center');
  }

  addPricingPackages(data, id?: null) {
    if (id !== undefined && id !== null && id !== '') {
      return this.restApiService.put('/secure/packages/' + id + '/chauffeur', data, 'page-center');
    } else {
      return this.restApiService.post('/secure/packages', data, 'page-center');
    }
  }

  updateCorporatePricingPackages(data, id: null, corporateId) {
    return this.restApiService.put('/secure/packages/' + id + '/partners/' + corporateId, data, 'page-center');

  }

  setRoute(data, corporateId?): Observable<any> {
    return this.restApiService.put(`/secure/packages/routes/destinations/rates/partners/${corporateId}`, data, 'page-center');
  }


  getRoutingDetails(routeId, partnerId) {
    return this.restApiService.get(`/secure/packages/routes/${routeId}/partners/${partnerId}`, 'page-center');
  }

  searchDriver(url: string): Observable<any> {
    return this.restApiService.get(url);
  }

  addUpdateBooking(data, serviceType, id?): Observable<any> {
    return this.restApiService.post(`/secure/booking/${serviceType}/taxi`, data, 'page-center');
  }

  getHotels(): Observable<any> {
    return this.restApiService.get(`/secure/hotels`, 'page-center');
  }

  assignDriver(bookingId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + bookingId + '/assign-driver', data);
  }

  resendCode(bookingId, passengerId): Observable<any> {
    return this.restApiService.put('/secure/booking/' + bookingId + '/verification-code');
  }

  cancelBooking(id, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + id + '/cancel', data);
  }

  getBookingActualRoot(id): Observable<any> {
    return this.restApiService.get(`/secure/tour/actual-path/${id}`);
  }

  bookingDetails(id): Observable<any> {
    return this.restApiService.get('/secure/booking/' + id);
  }

  startTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripid + '/start-trip', data);
  }

  securityCheck(data, tripid): Observable<any> {
    return this.restApiService.post('/secure/bookings/' + tripid + '/security-check', data);
  }

  importBulkFile(data): Observable<any> {
    return this.restApiService.post('/secure/file/import-file', data);
  }

  checkBulkIportStatus(eventid, screenCode?): Observable<any> {
    return this.restApiService.get(`/secure/file-import/inprogress-jobs/${eventid}?screenCode=${screenCode}`, 'page-center');
  }


  endTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripid + '/end-trip', data);
  }

  rentalEndTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/bookings/' + tripid + '/end-rental-booking', data);
  }

  extendPackage(tripid): Observable<any> {
    return this.restApiService.put('/secure/bookings/' + tripid + '/extend-package');
  }

  getDriverCurrentLocation(driverId): Observable<any> {
    return this.restApiService.get('/secure/drivers/' + driverId + '/location', 'page-center');
  }

  hasAccessPresent(accessCode) {
    const menuList = this.getSelectedMenuAccessesList();
    const hasAccess = menuList.find((elm) => {
      return elm.accessCode === accessCode;
    });

    if (hasAccess !== undefined && hasAccess !== null) {
      return true;
    }
    return false;
  }

  /**
   *
   * @param accessCode : accessCode to check access
   * @param accessGroupCode : accessGroupCode to find access
   */

  hasLogicalAccessPresent(accessCode, accessGroupCode) {
    const menuList = this.checkLogicalGroupAccess(accessGroupCode);
    const hasAccess = menuList.find((elm) => {
      return elm.accessCode === accessCode;
    });

    if (hasAccess !== undefined && hasAccess !== null) {
      return true;
    }
    return false;
  }

  getSubmenuListByMenuName(menuName) {
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (menu.name === menuName) {
        return menu.subMenuList;
      }
    }
    return [];
  }

  getSelectedMenuAccessesList(returnObj?) {
    // const menuName = this.storageService.getItem('selectedMenu');
    const location = window.location.href;
    const urlArray = location.split('#');
    const displayUrls = urlArray[urlArray.length - 1];
    const displayUrl = displayUrls.split('?')[0];
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (displayUrl.indexOf(menu.displayUrl) >= 0 && menu.displayUrl !== '') {
        // if (menu.accessGroupCode === menuName) {
        if (returnObj) {
          return menu;
        }
        return menu.accesses ? menu.accesses : [];
      } else {
        if (menu.subMenuList && menu.subMenuList.length) {
          const subMenuAccess = this.checkSubmenus(menu.subMenuList, displayUrl, returnObj);
          // const subMenuAccess = this.checkSubmenus(menu.subMenuList, menuName, returnObj);
          if (subMenuAccess !== undefined && subMenuAccess !== null) {
            return subMenuAccess;
          }
        }
      }
    }
    return [];
  }

  checkSubmenus(subMenuList, menuName, returnObj?) {
    if (subMenuList !== undefined && subMenuList !== null) {
      for (let menuIndex = 0; menuIndex < subMenuList.length; menuIndex++) {
        const menu = subMenuList[menuIndex];
        if (menuName.indexOf(menu.displayUrl) >= 0 && menu.displayUrl !== '') {
          // if (menu.accessGroupCode === menuName) {
          if (returnObj) {
            return menu;
          }
          return menu.accesses ? menu.accesses : [];
        } else {
          if (menu.subMenuList && menu.subMenuList.length) {
            const subMenuAccess = this.checkSubmenus(menu.subMenuList, menuName, returnObj);
            if (subMenuAccess !== undefined && subMenuAccess !== null) {
              return subMenuAccess;
            }
          }
        }
      }
      return null;
    }
  }

  checkLogicalGroupAccess(accessGroupCode) {
    // if (!this.accesses) {
    this.accesses = this.storageService.getItem(AppSettings.ACCESS_MENU);
    // }
    for (let i = 0; i < this.accesses.length; i++) {
      if (this.compareAccressGroupCode(this.accesses[i].accessGroupCode, accessGroupCode)) {
        return this.accesses[i].accesses ? this.accesses[i].accesses : [];
      } else {
        if (this.accesses[i].subMenuList.length) {
          for (let j = 0; j < this.accesses[i].subMenuList.length; j++) {
            if (this.compareAccressGroupCode(this.accesses[i].subMenuList[j].accessGroupCode, accessGroupCode)) {
              return this.accesses[i].subMenuList[j].accesses ? this.accesses[i].subMenuList[j].accesses : [];
            }
          }
        }
      }
    }
    return [];
  }

  compareAccressGroupCode(groupCode, accessGroupCode) {
    return groupCode ? groupCode.toLowerCase().indexOf(accessGroupCode.toLowerCase()) >= 0 : false;
  }

  compareUrl(srcUrl, routeUrl) {
    return srcUrl ? srcUrl.toLowerCase().indexOf(routeUrl.toLowerCase()) >= 0 : false;
  }

  getCities(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/service-area/cities?eventId=${eventId ? eventId : ''}`);
  }

  getCitiesForBooking(serviceTypeId?, vehicleTypeId?, sourceCityId?, eventId?): Observable<any> {
    return this.restApiService.get('/secure/service-area/cities?serviceTypeId=' +
      serviceTypeId + '&vehicleTypeId=' + vehicleTypeId + '&sourceCityId=' + sourceCityId + '&eventId=' + (eventId ? eventId : ''));
  }


  getCitiesForOccasional(): Observable<any> {
    return this.restApiService.get('/secure/service-area/tenant-city/list');
  }

  getServiceAreaByCompanyId(companyId, eventId?): Observable<any> {
    return this.restApiService.get('/secure/companies-service-area/list/' + companyId + '?eventId=' + (eventId ? eventId : ''), 'page-center');
  }

  getServiceAreaByUserId(userId): Observable<any> {
    return this.restApiService.get('/secure/service-area/' + userId + '/city', 'page-center');
  }

  getCompaniesList(serviceArea?, eventId?): Observable<any> {
    return this.restApiService.get('/secure/companies/list?tenantServiceAreaId=' + serviceArea + '&eventId=' + (eventId ? eventId : ''), 'page-center');
  }

  getEventList(): Observable<any> {
    return this.restApiService.get('/secure/events/list?activated=true&searchText=', 'page-center');
  }

  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('/secure/tour/' + tourId);
  }
  getDropDownData(list, key, value) {
    return (list !== null && list !== undefined) ?
      list.map(item => ({ label: item[key], value: item[value] })) : [];
  }

  getUsers(roleId, activated): Observable<any> {
    return this.restApiService.get(`/secure/users-list?roleId=${roleId}&activated=${activated}`, 'page-center');
  }

  getdocumentDownload(documentId: string) {
    return this.restApiService.image(`${AppSettings.BASE_URL}/${AppSettings.TENANT}/file/${documentId}`, documentId);
  }

  getDrivers(vehicleTypeId, tourId?, search?, shiftStatus?, selectedLocation?, requestedSortKey?): Observable<any> {
    const searchData = search ? search : '';
    const shiftGroup = shiftStatus ? shiftStatus : '';
    return this.restApiService.get(`/secure/drivers/assign-trip/list/` + vehicleTypeId + '?tourId=' +
      tourId + '&searchText=' + searchData + '&shiftStatus=' + shiftStatus +
      '&parkingLocationId=' + selectedLocation + '&limit=10&offset=0&listSortKey=' + requestedSortKey + '&listSortBy=desc', 'page-center');
  }

  getDriversWithoutlimit(vehicleTypeId, tourId?, search?, shiftStatus?, selectedLocation?, requestedSortKey?,
    selectedQueueLocation?, selecteDriverType?): Observable<any> {
    const searchData = search ? search : '';
    const shiftGroup = shiftStatus ? shiftStatus : '';
    return this.restApiService.get(`/secure/drivers/assign-trip/list/` + vehicleTypeId + '?tourId=' +
      tourId + '&searchText=' + searchData + '&shiftStatus=' + shiftStatus +
      '&parkingLocationId=' + selectedLocation + '&queueLocationId=' + selectedQueueLocation + '&selectDriverFrom=' + selecteDriverType +
      '&limit=99999&offset=0&listSortKey=' + requestedSortKey + '&listSortBy=desc', 'page-center');
  }


  getShiftGroupList(): Observable<any> {
    return this.restApiService.get(`/secure/shift-management/shifts?projection=non-grid`, 'page-center');
  }

  // driverAssigned(): Observable<any> {
  //   return this.restApiService.put(`/secure/tour/assign-driver/${id}/partners/${corporateId}`, data, 'page-center');
  // }
  driverAssigned(tourId, driverId, isAssigned?): Observable<any> {
    if (isAssigned) {
      return this.restApiService.put('/secure/tour/reassign-driver/' + tourId + '/' + driverId, 'page-center');
    } else {
      return this.restApiService.put('/secure/tour/assign-driver/' + tourId + '/' + driverId, 'page-center');
    }
  }

  driverOntheWay(tourId, data): Observable<any> {
    return this.restApiService.put('/secure/tour/driver/ontheway/' + tourId, data, 'page-center');
  }

  updateLuggage(tourId, actionType) {
    return this.restApiService.put(`/secure/luggage-tour/approve-reject/${tourId}/${actionType}`, 'page-center');
  }

  driverArrived(tripId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + tripId + '/arrived', data, 'page-center');
  }

  OnTrip(tripId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripId + '/start-trip', data, 'page-center');
  }

  dropTrip(data): Observable<any> {
    return this.restApiService.post('/secure/tour/drop', data, 'page-center');
  }

  rideShareDriverOntheWay(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/driver/on-the-way', 'page-center');
  }

  rideShareDriverArrived(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/driver/arrived', 'page-center');
  }

  rideShareOnTrip(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/start-trip', 'page-center');
  }

  rideShareDropTrip(tourId, data): Observable<any> {
    return this.restApiService.post('/secure/ride-share/' + tourId + '/end-trip', data, 'page-center');
  }

  acknowledgeTrip(tourId, data): Observable<any> {
    return this.restApiService.put('/secure/tour/' + tourId + '/acknowledge', data, 'page-center');
  }

  acknowledgeRideShareTrip(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/acknowledge', 'page-center');
  }

  doPayment(data): Observable<any> {
    return this.restApiService.post('/secure/manual-booking/stripe', data, 'page-center');
  }

  getCardDetails(userId): Observable<any> {
    return this.restApiService.get(`/secure/manual-booking/web-stripe/` + userId, 'page-center');
  }

  bookRideNowAndRideLater(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/secure/booking/manual/${servicetypeid}`, data, 'page-center');
  }

  bookRideShare(data): Observable<any> {
    return this.restApiService.post(`/secure/ride-share/booking/manual`, data, 'page-center');
  }

  getDateFormat() {

    return 'dd M yy'

    let dateFormatRecord = this.getTenantConfigValue(AppSettings.DATE_FORMAT).toLowerCase();
    const check = dateFormatRecord.includes('yyyy');
    if (check) {
      dateFormatRecord = dateFormatRecord.replace('yyyy', 'yy');
      dateFormatRecord = dateFormatRecord.replace('mmm', 'mm');
    }
    return dateFormatRecord;
  }

  getPrivacyPolicy(): Observable<any> {
    return this.restApiService.get('/tenants/privacy-policy', 'page-center');
  }

  cashCollecte(tripid, data): Observable<any> {
    // return this.restApiService.put('/secure/booking/trip/' + tripid + '/start-trip', data);
    return this.restApiService.put('/secure/tour/collect-cash/' + tripid, data);
  }

  updateOccasionalStatus(tripid, data): Observable<any> {
    return this.restApiService.put('/secure/occasional-tour-document/' + tripid + '/status', data);
  }

  /*AEL start **/
  getAssignDriverList(): Observable<any> {
    return this.restApiService.get(`/secure/drivers/list/data?limit=500`, 'page-center');
  }

  getPickupDropAddress(searchText?, addressType?, userType?, passengerId?, journeyTypetoSend?, eventId?): Observable<any> {
    return this.restApiService.get(`/secure/location/search-places?searchText=${searchText}` +
      `&addressType=${addressType}&userType=${userType}&passengerId=${passengerId}&journeyType=${journeyTypetoSend}&eventId=${eventId ? eventId : ''}`, 'page-center');
    // return this.restApiService.get('/secure/v1/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
  }

  getDispatchQueueLocations(projection?: string, locationCategoryId?, tourId?, etaDistance?, eventId?): Observable<any> {
    // projection = projection?projection:'non-grid';
    return this.restApiService.get(`/secure/locations?projection=${projection}&locationCategoryId=${locationCategoryId}` +
      `&tourId=${tourId}&etaDistance=${etaDistance}&eventId=${eventId ? eventId : ''}` +
      `&activated=&searchText=&offset=&limit=&sortColumn=&sortType=`, 'page-center');
    // return this.restApiService.get(`/secure/locations?projection=${projection}&locationCategoryId=`+
    // `${locationCategoryId}=&tourId=${tourId}&etaDistance=${etaDistance}`, 'page-center');
  }

  setDispatchLocation(data): Observable<any> {
    return this.restApiService.post('/secure/tour/dispatch-location', data, 'page-center');
  }

  boookingsTabcount(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/current-bookings/tabs-count?eventId=${eventId}`, 'page-center');
  }

  gerPrivilegeData(id, userid?, fromDate?, toDate?): Observable<any> {
    if (fromDate && toDate) {
      return this.restApiService.get(`/secure/privilege-settings/passenger-type-permissions?passengerTypeId=` +
        `${id}&passengerId=${userid}&fromDate=${fromDate}&toDate=${toDate}`, 'page-center');
    } else {
      return this.restApiService.get(`/secure/privilege-settings/passenger-type-permissions?passengerTypeId=` +
        `${id}&passengerId=${userid}`, 'page-center');
    }
  }

  AssigneToAndFromThirdParty(tourId, data,): Observable<any> {
    return this.restApiService.put('/secure/booking/' + tourId + '/assign-third-party', data, 'page-center');
  }

  AssigneToAutobroadcast(tourId, data,): Observable<any> {
    return this.restApiService.put('/secure/tour-autobroadcast/' + tourId + '/status', data, 'page-center');
  }

  // for confirmed by admin
  changeBackToNewRequest(tourId, data): Observable<any> {
    return this.restApiService.put(`/secure/tour-status/random-update/${tourId}`, data, 'page-center');
  }

  // for confirmed by admin
  adminConfirmed(tourId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + tourId + '/admin-confirm', data, 'page-center');
  }

  // used for assign driver
  getParkingLocationsList(locationCategoryId?, dispatchZoneId?, eventId?): Observable<any> {
    return this.restApiService.get(`/secure/locations?projection=grid&locationCategoryId=${locationCategoryId}&` +
      `dispatchZoneId=${dispatchZoneId}&isLimit=false&activated=true&eventId=${eventId}`,
      'page-center');
  }

  // used for assign driver Quelocations
  getAllLocations(eventId?): Observable<any> {
    return this.restApiService.get('/secure/locations?projection=non-grid&eventId=' + eventId, 'page-center');
  }

  // for first acknowledge by driver
  driverFirstConfirmed(tourId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + tourId + '/driver-confirm', data, 'page-center');
  }

  // used for grid filter
  getPassengerAllocatedLocationData(queueLocaationcatId, eventId?): Observable<any> {
    return this.restApiService.get(`/secure/locations?projection=grid&isLimit=false&` +
      `locationCategoryId=${queueLocaationcatId}` + `&eventId=${eventId}`, 'page-center');
  }

  // used for get ETA on grid
  getETA(bookingId): Observable<any> {
    return this.restApiService.get(`/secure/tour/eta/${bookingId}`, 'page-center');
  }

  getPassengerType(eventId?): Observable<any> {
    return this.restApiService.get('/secure/privilege-settings/data-grid?offset=0&limit=&eventId=' + (eventId ? eventId : ''), 'page-center');
  }

  getVehiclesToTransfer(locationId = '', vehicleTypeId?): Observable<any> {
    return this.restApiService.get(`/secure/drivers/vehicle-transfer/list?locationId=${locationId}&vehicleTypeId=${vehicleTypeId}`, 'page-center');
  }

  convertDistanceMeterToMiles(meters) {
    return (meters * 0.000621371192).toFixed(3);
  }

  convertTimeToMinuts(miliseconds) {
    // return moment(miliseconds).format(AppSettings.DEFAULT_TIME_FORMATE);
    // return (miliseconds / 60).toFixed(2);
    // return miliseconds;
    const minutes = Math.floor(miliseconds / 60000);
    const seconds = ((miliseconds % 60000) / 1000).toFixed(0);
    return minutes + ':' + (+seconds < 10 ? '0' : '') + seconds + ' ' + this.getLabel('Mins');
  }

  getDriverPosition(tripId: any): Observable<any> {
    return this.restApiService.get(`/secure/tour-driver/current-location/${tripId}`, 'page-center');
  }

  checkIsEditable(bookingStatus) {
    return (AppSettings.DRIVER_ASSIGN_CONDITIONS.indexOf(bookingStatus) > -1);
  }

  getsummaryReportDetails(url): Observable<any> {
    return this.restApiService.get(url, 'page-center');
  }

  sendResetPasswordLink(data): Observable<any> {
    return this.restApiService.put(`/secure/users/reset-password/send-token`, data, 'page-center');
  }

  getBusOperator(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/bus-operators?eventId=${(eventId ? eventId : '')}`, 'page-center');
  }

  getLayoutType(): Observable<any> {
    return this.restApiService.get('/secure/bus-floor-layouts', 'page-center');
  }

  getBusDestinationPoints(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/bus-destinations?eventId=${(eventId ? eventId : '')}`, 'page-center');
  }

  encrypt(value: string): string {
    let g = this.secretKey.trim();
    if (value) {
      return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    }
  }

  decrypt(textToDecrypt: string) {
    if (textToDecrypt) {
      return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(
        CryptoJS.enc.Utf8
      );
      // return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim());
    }
  }

  /* AEl End **/

}
