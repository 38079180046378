<div class="no-content" *ngIf="notifications.length==0">
  {{noNotificationFoundLabel}}
</div>

<div class="scroll-container" *ngIf="notifications.length > 0"> <!-- -->
  <div class="ui-g notification-card ui-g-nopad" *ngFor="let notification of notifications">
    <div class="ui-g-12 notification-time ">
      <div class="ui-g-6 date ui-g-nopad">
        {{notification.createdAt| dateformat}}
      </div>
      <div class="ui-g-6 ago ui-g-nopad">
        {{notification.createdAt| ago }}
      </div>

      <div class="ui-g-12 message">
        {{notification.message}}
      </div>
    </div>
  </div>

  <div class="ui-g-12" *ngIf="notifications.length > 0">
    <button pButton appRipple class="assign-btn load-more-btn" type="button" [label]="labelLoadMore" (click)="onLoadMore()"></button>
  </div>

</div>

<div *ngIf="notifications.length === 0">
  <div class="ui-lg-12 no no-records" translate>
    label_no_notification_list
  </div><!-- label_no_notification_found-->
</div>
