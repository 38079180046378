import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppSettings {
  public static BASE_URL = environment.basePath;
  public static TENANT = environment.tenant;
  public static SUPPORTED_COUNTRY = environment.supportedCountry;
  public static VERSION = environment.version;
  public static TENANT_INFO = 'tenantInfo';
  public static FRONTEND_BASE_URL = 'front_end_base_url';

  public static HEADER_CONTENT_TYPE = ' ';
  public static HEADER_AUTHORIZATION = 'Authorization';
  public static HEADER_TIMEZONE = 'Timezone';
  public static HEADER_TIMEZONE_VALUE = '';
  public static HEADER_ACCEPT_LANGUAGE = 'en';
  public static DEFAULT_LANGUAGE = 'en';
  public static SECRET_KEY = environment.passwordSecretKey;

  // public static HEADER_ACCEPT_LANGUAGE = 'en-US';
  public static HEADER_AUTHORIZATION_VALUE = '';
  public static USER: any = null;
  public static TOKEN_KEY = 'Token';
  public static SAVE_FILTERS = 'Filters';
  public static USER_DETAILS = 'userDetails';
  public static FILE_UPLOAD_ID = '';
  public static FILE_UPLOAD_NAME = '';
  public static PROFILE_UPLOAD_ID = ''; tenantInfo
  public static PROFILE_UPLOAD_NAME = '';
  public static DISTANCE_UNIT = 'distance_unit';
  public static REFERRAL_CODE = 'driver_referral';
  public static REFERRAL_MANDATORY = 'referral_mandatory';

  public static ADMIN_TRIP_ACTION = 'admin_trip_action';
  public static PASSENGER_WALLET = 'passenger_wallet';
  public static TRIP_NOTE = 'trip_note';
  public static DEFAULT_COUNTRY_CODE = '+44';
  public static DEFAULT_COUNTRY = 'default_country';
  public static DEFAULT_CURRENCY = 'default_currency';
  public static ADDITIONAL_STOPS = 'additional_stops';
  public static IS_MANAGE_CORPORATES = 'is_corporate_company';
  public static BOOKING_FEES_APPLICABLE = 'booking_fees_applicable';
  public static PASSENGER_LIST_TYPE = 'passengerTypeList';
  public static ADDITIONAL_CHARGES = 'additional_charges';
  public static ADDITIONAL_CHARGES_REASON_LIST = 'additionalChargesReasonsList';
  public static POSITIVE_NEGATIVE_NUMBER = /^-?[0-9]\d*(\.\d+)?$/;
  public static NAME = /[a-zA-Z0-9][\\sa-zA-Z0-9]{0,19}/;


  public static TENANT_CONFIG = 'TENANT_CONFIG';
  public static IS_RIDE_SHARE_APPLICABLE = 'is_ride_share_applicable';
  public static IS_AIRPORT_ZONES_APPLICABLE = 'airport_zones_applicable';

  public static IS_DRIVER_EXTRA_DOCUMENT_UPLOAD = 'driver_extra_document_upload';
  public static IS_MANAGE_COMPANY = 'is_bidding_applicable';
  public static IS_SEARCH_DRIVER = 'is_dashboard_driver_search';
  public static IS_MULTIPLE_VEHICLE_TO_DRIVER = 'is_multiple_vehicle_to_driver';
  public static IS_LANGUAGE_SUPPORT = 'is_language_support';
  public static IS_RENTAL_APPLICABLE = 'is_rental_service_applicable';
  public static IS_TIME_BASED = 'time_based_surge';
  public static IS_DATE_BASED = 'date_based_surge';
  public static IS_GDPR_SHOW = 'gdpr_compliance';
  public static IS_SELECT_ADDRESS_FROM_MAP = 'select_address_from_map';


  public static SUPPORTED_LANGUAGES = 'supported_languages';
  public static EMAIL_OPTIONAL = 'email_optional_for_role';


  public static FAQ: any = null;
  public static ACCESS_LIST = 'ACCESS_LIST';
  public static ACCESS_MENU = 'ACCESS_MENU';
  public static SERVICE_AREA = 'SERVICE_AREA';
  public static SERVICE_AREA_ALIAS = 'SERVICE_AREA_ALIAS';
  public static SERVICE_CITIES_MAP = 'SERVICE_CITIES_MAP';
  public static MUTLTI_SELECT_SERVICE_CITIES = 'MUTLTI_SELECT_SERVICE_CITIES';
  public static SERVICE_AREA_ID = 'SERVICE_AREA_ID';
  public static CURRENT_USER_CORPORATE_ID = 'CURRENT_USER_CORPORATE_ID';
  public static TIME_SETTINGS = 'TIME_SETTINGS';
  public static DEFAULT_SERVICE_TYPE = 'tenantServiceTypes00000000000001';
  public static DEFAULT_SERVICE_TYPE_00000000000002 = 'tenantServiceTypes00000000000002';
  public static DEFAULT_SERVICE_TYPE_00000000000003 = 'tenantServiceTypes00000000000003';
  public static DEFAULT_PASSENGER_TYPE_00000000000003 = 'taxiPulsePassenger00000000000003';
  public static DEFAULT_DRIVER_TYPE_00000000000004 = 'taxiPulseDriver00000000000000004';
  public static INCIDENT_TYPE_ID = 'it00001';
  public static COMPLAINT_TYPE_ID = 'it00002';

  public static IS_BIDDING_ENABLED = 'is_bidding_applicable';
  public static IS_COMPANY_CATEGORY_APPLICABLE = 'vehicle_type_categories_applicable';
  public static DATE_FORMAT = 'date_format';
  public static DEFAULT_TIME_FORMATE = 'HH:mm';
  public static DEFAULT_MIN_TIME_FORMATE = 'M';


  public static ADD_BOOKING_DATE_FORMAT_PRIMENG = 'dd M yy';
  public static DATE_TIME_FORMAT_MOMENT = 'HH:mm, DD MMM YYYY';
  public static DATE_ONLY_FORMATE = 'DD MMM YYYY';
  public static DATE_MMDDYYYY_FORMATE = 'MM/DD/YYYY';

  public static SETTING_SERVICE_ID = '';
  public static SERVICE_TYPE = 'ServiceType';
  public static USER_TYPE_LIST = 'userTypeList';

  public static SETTING_RENTAL_WITHOUT_SERVICE = '';
  public static SETTING_RENTAL_WITH_SERVICE = '';
  public static SETTING_TAXI_SERVICE = '';
  public static SETTING_CHAUFFEUR_SERVICE = '';
  public static SETTING_RENTAL_WITHOUT_SERVICE_KEY = 'Rental without driver';
  public static SETTING_RENTAL_WITH_SERVICE_KEY = 'Rental with driver';
  public static SERVICE_CODE_RENTAL = 'Rental';
  public static SETTING_TAXI_SERVICE_KEY = 'Taxi';
  public static SETTING_STAFF_TRANSPORT_SERVICE_KEY = 'StaffTransport';
  public static SETTING_CHAUFFEUR_SERVICE_KEY = 'Chauffeur';
  public static TAXI_BOOK_MAX_TIME = 'book_later_maximum_time';
  public static TAXI_BOOK_MIN_TIME = 'book_later_minimum_time';
  public static RENTAL_BOOK_MIN_TIME = 'book_later_minimum_time_rental';
  public static CHAUFFEUR_BOOK_MIN_TIME = 'book_later_minimum_time_chauffeur';
  public static AUTOMATIC = 'AUTO';

  public static DRIVER_KEY = 'DRIVER';
  public static PASSENGER_KEY = 'PASSENGER';
  public static MANUAL = 'MANL';
  public static MIN_DISTANCE = 0.1;
  public static MAX_DISTANCE = 1;
  public static BOOKING_DEFAULT_PAGINATION = 50;
  public static UPLOAD_FILE_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/secure/file';
  public static GET_FILE_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/file';
  public static GET_FILE_THUMB_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/file/thumbnail_';
  // public static GET_FILE_THUMB_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/secure/file/thumbnail_';
  public static GET_FILE_GRID_IMG_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/file/';
  public static EXPORT_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT;
  public static PRINT_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT;
  public static DEFAULT_PROFILE_IMAGE_URL = '/assets/images/temp/default_profile.png';
  public static DEFAULT_VEHICLE_IMAGE_URL = '/assets/images/temp/default_vehicle.png';
  public static DEFAULT_POWERED_BY_REDIRECT_URL = 'https://mobisoftinfotech.com/solutions/event-transportation-management-app';
  public static DEFAULT_POWERED_BY_LOGO_URL = '/assets/images/temp/powered-by-logo.png';
  public static CLEAR_NOTIFICATION_INTERVAL: any;
  public static WEBSITE_PATTERN = new RegExp(['^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?',
    '[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})',
    '?(\/.*)?$'].join(''));
  // public static NAME_PATTERN = new RegExp(['^[A-Za-z\\d\-_\\s]*$'].join(''));
  // public static NAME_PATTERN = new RegExp(/^([a-zA-Z]+[a-zA-Z0-9]+\s?)*$/);
  // public static SHORT_CODE_PATTERN = new RegExp(['.*[^ ].*'].join(''));
  public static SHORT_CODE_PATTERN = new RegExp(/^([a-zA-Z0-9]+\s?)*$/);

  public static ONLY_SPACE_NOT_ALLOWED_PATTERN = new RegExp(['.*[^ ].*'].join(''));
  // public static NAME_PATTERN = new RegExp(['.*[^ ].*'].join(''));
  // public static NAME_PATTERN = new RegExp(['^[A-Za-zầáéíóúñüÁÉÍÓÚÑÜ\']+[0-9A-Za-zầáéíóúñüÁÉÍÓÚÑÜ]'].join(''));
  public static TEXT_AREA_PATTERN = new RegExp(['^[A-Za-zầáéíóúñüÁÉÍÓÚÑÜ\']+[0-9A-Za-zầáéíóúñüÁÉÍÓÚÑÜ]'].join(''));
  public static NAME_PATTERN = new RegExp(/^([a-zA-Z0-9']+\s?)*$/);
  // public static TEXT_AREA_PATTERN = new RegExp(['.*[^=].*'].join(''));;
  public static ROLE_PATTERN = new RegExp(['^[A-Za-z\\d\-_\\s/\\\\\]*$'].join(''));
  public static PHONE_PATTERN: any = '^[0-9][0-9]*$';
  public static FLOAT_NUMBER_PATTERN = /^\d+(\.\d+)?$/;
  public static FLOAT_NUMBER_PATTERN_ABOVE_ZERO = /^[0-9]+(\.[1-9]+)?$/;
  public static NUMBER_PATTERN = /^\+?[0-9\-]+$/;
  public static AMOUNT_PATTERN = new RegExp(['^[0-9\\d\.]*$'].join(''));
  public static PERCENT_PATTERN = new RegExp(['^[0-9][0-9]?$|^100$'].join(''));
  public static WALLET_AMOUNT_PATTERN = new RegExp(['^[-|0-9\\d\.]*$'].join(''));
  public static PHONE_CODE = /([0-9]{4,})|[1-9]/;
  public static EMAIL_PATTERN = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,5})+$/;
  // public static EMAIL_PATTERN = new RegExp(['[a-z|A-Z|0-9]+[@]+[a-z|A-Z|0-9|-]+[.]+[a-z|A-Z|0-9]+'].join(''));
  // public static EMAIL_PATTERN = new RegExp(['/^\S+@\S+\.\S+$/'].join(''));
  // public static EMAIL_PATTERN = new RegExp(/^\w+([//+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/);
  public static SPACE_NOT_ALLOWED_PATTERN = '^[0-9a-zA-Z][a-zA-Z0-9\\.\-\_]+$';
  public static ZIPCODE_PATTERN = new RegExp(['^[0-9]'].join(''));
  public static DIGIT_PATTERN = new RegExp(['^[\\d]*$'].join(''));
  public static RADIUS_DIGIT_PATTERN = /^[0-9|.|0-9]+$/;
  public static NUMBER_NOT_ZERO = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static FEE_PATTERN = new RegExp(['^\\d+(\.\\d{1,2})?$'].join(''));
  public static ALPHA_NUMERIC = new RegExp(['^[A-Za-z0-9]'].join(''));
  public static VALUE_ONE_TO_HUNDRED = new RegExp(['^[1-9][0-9]?$|^100$'].join(''));
  public static NON_ZERO_VALUES = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static HOTEL_PROFILE_UPLOAD_ID = '';
  public static HOTEL_PROFILE_UPLOAD_NAME = '';
  public static PERSON_NAME_PATTERN = '^[a-zA-Z][a-zA-Z\\s\-\_]+$';
  public static COMPANY_AND_PERSON_NAME_PATTERN = '^[a-zA-Z0-9][a-zA-Z0-9\\s\-\_]+$';
  public static PASSWORD_VALIDATIONS = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,18}$/;
  public static FAX_PATTERN = /^\+?[0-9\-]+$/;
  public static TIME_ZONE_FIRST_STRING = /[(]/gi;
  public static TIME_ZONE_SECOND_STRING = /[)]/gi;
  public static NO_WHITESPACE = '^[a-zA-Z0-9]*$';
  public static ALPHA_NUMERIC_NEW = '^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$';
  public static DEFAULT_NULL_STATUS = 'NA';
  public static TABLE_REFRESH_TIME_INTERVAL = 30000; //20000;
  public static MAP_DRIVERS_REFRESH_TIME_INTERVAL = 61000;
  public static BOOKING_DETAILS_MAP_REFRESH_TIME_INTERVAL = 10000;
  public static REPORTS_YEAR_RANGE_START_YEAR = 2020;
  public static PRORITY_DATE_FORMAT = { month: "short", year: "numeric" };


  public static STATUS_FILTER = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' }
  ];

  public static STATUS_OPTIONS = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ];

  public static WHEELCHAIR_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' }
  ];

  public static STATUS_FILTER_VEHICLES = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'true' },
    { label: 'Deactivated', value: 'false' }
  ];

  public static PRIVILEGE_COLDE = {
    LOCATIONS: 'locations',
    SOCIAL_JOURNEYS: 'social_journeys',
    DEPARTURE_JOURNEYS: 'departure_journeys',
    MAX_PASSENGER_PER_TRIP: 'max_passenger_per_trip',
    MAX_TRIP_PER_DAY: 'max_trip_per_day',
    MAX_WHEELCHAIR_PER_TRIP: 'max_wheelchair_per_trip',
    MAX_CHILDSEAT_PER_TRIP: 'max_childseat_per_trip',
    MAX_BABYSEAT_PER_TRIP: 'max_babyseat_per_trip',
    PASSENGER_APP: 'passenger_app',
  };

  public static PRIVILEGE_PERMISSION_ID = {
    PASSENGER_APP: 'permevnvnjv00001',
    MAX_PASSENGER_PER_TRIP: 'permevnvnjv00002',
    MAX_TRIP_PER_DAY: 'permevnvnjv00003',
    MAX_WHEELCHAIR_PER_TRIP: 'permevnvnjv00004',
    MAX_CHILDSEAT_PER_TRIP: 'permevnvnjv00005',
    MAX_BABYSEAT_PER_TRIP: 'permevnvnjv00009',
    DEPARTURE_JOURNEYS: 'permevnvnjv00006',
    SOCIAL_JOURNEYS: 'permevnvnjv00007',
    LOCATIONS: 'permevnvnjv00008'
  };


  public static MESSAGE_TYPE = { SUCCESS: 'success', ERROR: 'error', WARNING: 'warning', INFO: 'info' };

  public static LOCATION_TYPES = {
    PARKING: 'locCatId00001',
    QUEUE: 'locCatId00002',
    STAY_LOCATION: 'locCatId00003',
  };

  public static All_LOCATION_TYPES = {
    PARKING: 'locCatId00001',
    QUEUE: 'locCatId00002',
    STAY_LOCATION: 'locCatId00003',
    OTHER_LOCATION: 'locCatId00004',
  };

  public static CHILDREN_SEAT = {
    CHILD_SEAT: 'C',
    BABY_SEAT: 'B'
  };

  // public static NO_SPACE = '^[a-zA-Z0-9]*$';
  public static DAYS_COLLECTION = [
    { label: 'Sun', value: '1' },
    { label: 'Mon', value: '2' },
    { label: 'Tue', value: '3' },
    { label: 'Wed', value: '4' },
    { label: 'Thu', value: '5' },
    { label: 'Fri', value: '6' },
    { label: 'Sat', value: '7' }
  ];

  public static COUNTRY_CODE = [
    // { label: 'Code', value: '' },
    { label: '+1', value: '+1' },
    { label: '+7', value: '+7' },
    { label: '+20', value: '+20' },
    { label: '+27', value: '+27' },
    { label: '+30', value: '+30' },
    { label: '+31', value: '+31' },
    { label: '+32', value: '+32' },
    { label: '+33', value: '+33' },
    { label: '+34', value: '+34' },
    { label: '+36', value: '+36' },
    { label: '+39', value: '+39' },
    { label: '+40', value: '+40' },
    { label: '+41', value: '+41' },
    { label: '+43', value: '+43' },
    { label: '+44', value: '+44' },
    { label: '+45', value: '+45' },
    { label: '+46', value: '+46' },
    { label: '+47', value: '+47' },
    { label: '+48', value: '+48' },
    { label: '+49', value: '+49' },
    { label: '+51', value: '+51' },
    { label: '+52', value: '+52' },
    { label: '+53', value: '+53' },
    { label: '+54', value: '+54' },
    { label: '+55', value: '+55' },
    { label: '+56', value: '+56' },
    { label: '+57', value: '+57' },
    { label: '+58', value: '+58' },
    { label: '+60', value: '+60' },
    { label: '+61', value: '+61' },
    { label: '+62', value: '+62' },
    { label: '+63', value: '+63' },
    { label: '+64', value: '+64' },
    { label: '+65', value: '+65' },
    { label: '+66', value: '+66' },
    { label: '+81', value: '+81' },
    { label: '+82', value: '+82' },
    { label: '+84', value: '+84' },
    { label: '+86', value: '+86' },
    { label: '+90', value: '+90' },
    { label: '+91', value: '+91' },
    { label: '+92', value: '+92' },
    { label: '+93', value: '+93' },
    { label: '+94', value: '+94' },
    { label: '+95', value: '+95' },
    { label: '+98', value: '+98' },
    { label: '+212', value: '+212' },
    { label: '+213', value: '+213' },
    { label: '+216', value: '+216' },
    { label: '+218', value: '+218' },
    { label: '+220', value: '+220' },
    { label: '+221', value: '+221' },
    { label: '+222', value: '+222' },
    { label: '+223', value: '+223' },
    { label: '+224', value: '+224' },
    { label: '+225', value: '+225' },
    { label: '+226', value: '+226' },
    { label: '+227', value: '+227' },
    { label: '+228', value: '+228' },
    { label: '+229', value: '+229' },
    { label: '+230', value: '+230' },
    { label: '+231', value: '+231' },
    { label: '+232', value: '+232' },
    { label: '+233', value: '+233' },
    { label: '+234', value: '+234' },
    { label: '+235', value: '+235' },
    { label: '+236', value: '+236' },
    { label: '+237', value: '+237' },
    { label: '+238', value: '+238' },
    { label: '+239', value: '+239' },
    { label: '+240', value: '+240' },
    { label: '+241', value: '+241' },
    { label: '+242', value: '+242' },
    { label: '+243', value: '+243' },
    { label: '+244', value: '+244' },
    { label: '+245', value: '+245' },
    { label: '+248', value: '+248' },
    { label: '+249', value: '+249' },
    { label: '+250', value: '+250' },
    { label: '+251', value: '+251' },
    { label: '+252', value: '+252' },
    { label: '+253', value: '+253' },
    { label: '+254', value: '+254' },
    { label: '+255', value: '+255' },
    { label: '+256', value: '+256' },
    { label: '+257', value: '+257' },
    { label: '+258', value: '+258' },
    { label: '+260', value: '+260' },
    { label: '+261', value: '+261' },
    { label: '+262', value: '+262' },
    { label: '+263', value: '+263' },
    { label: '+264', value: '+264' },
    { label: '+265', value: '+265' },
    { label: '+266', value: '+266' },
    { label: '+267', value: '+267' },
    { label: '+268', value: '+268' },
    { label: '+269', value: '+269' },
    { label: '+290', value: '+290' },
    { label: '+291', value: '+291' },
    { label: '+297', value: '+297' },
    { label: '+298', value: '+298' },
    { label: '+299', value: '+299' },
    { label: '+350', value: '+350' },
    { label: '+351', value: '+351' },
    { label: '+352', value: '+352' },
    { label: '+353', value: '+353' },
    { label: '+355', value: '+355' },
    { label: '+356', value: '+356' },
    { label: '+357', value: '+357' },
    { label: '+358', value: '+358' },
    { label: '+359', value: '+359' },
    { label: '+370', value: '+370' },
    { label: '+371', value: '+371' },
    { label: '+372', value: '+372' },
    { label: '+373', value: '+373' },
    { label: '+374', value: '+374' },
    { label: '+375', value: '+375' },
    { label: '+376', value: '+376' },
    { label: '+377', value: '+377' },
    { label: '+378', value: '+378' },
    { label: '+380', value: '+380' },
    { label: '+381', value: '+381' },
    { label: '+382', value: '+382' },
    { label: '+385', value: '+385' },
    { label: '+386', value: '+386' },
    { label: '+387', value: '+387' },
    { label: '+389', value: '+389' },
    { label: '+420', value: '+420' },
    { label: '+421', value: '+421' },
    { label: '+423', value: '+423' },
    { label: '+500', value: '+500' },
    { label: '+501', value: '+501' },
    { label: '+502', value: '+502' },
    { label: '+503', value: '+503' },
    { label: '+504', value: '+504' },
    { label: '+505', value: '+505' },
    { label: '+506', value: '+506' },
    { label: '+507', value: '+507' },
    { label: '+508', value: '+508' },
    { label: '+509', value: '+509' },
    { label: '+590', value: '+590' },
    { label: '+591', value: '+591' },
    { label: '+592', value: '+592' },
    { label: '+593', value: '+593' },
    { label: '+595', value: '+595' },
    { label: '+597', value: '+597' },
    { label: '+598', value: '+598' },
    { label: '+670', value: '+670' },
    { label: '+672', value: '+672' },
    { label: '+673', value: '+673' },
    { label: '+674', value: '+674' },
    { label: '+675', value: '+675' },
    { label: '+676', value: '+676' },
    { label: '+677', value: '+677' },
    { label: '+678', value: '+678' },
    { label: '+679', value: '+679' },
    { label: '+680', value: '+680' },
    { label: '+681', value: '+681' },
    { label: '+682', value: '+682' },
    { label: '+683', value: '+683' },
    { label: '+685', value: '+685' },
    { label: '+686', value: '+686' },
    { label: '+687', value: '+687' },
    { label: '+688', value: '+688' },
    { label: '+689', value: '+689' },
    { label: '+690', value: '+690' },
    { label: '+691', value: '+691' },
    { label: '+692', value: '+692' },
    { label: '+850', value: '+850' },
    { label: '+852', value: '+852' },
    { label: '+853', value: '+853' },
    { label: '+855', value: '+855' },
    { label: '+856', value: '+856' },
    { label: '+870', value: '+870' },
    { label: '+880', value: '+880' },
    { label: '+886', value: '+886' },
    { label: '+960', value: '+960' },
    { label: '+961', value: '+961' },
    { label: '+962', value: '+962' },
    { label: '+963', value: '+963' },
    { label: '+964', value: '+964' },
    { label: '+965', value: '+965' },
    { label: '+966', value: '+966' },
    { label: '+967', value: '+967' },
    { label: '+968', value: '+968' },
    { label: '+971', value: '+971' },
    { label: '+972', value: '+972' },
    { label: '+973', value: '+973' },
    { label: '+974', value: '+974' },
    { label: '+975', value: '+975' },
    { label: '+976', value: '+976' },
    { label: '+977', value: '+977' },
    { label: '+992', value: '+992' },
    { label: '+993', value: '+993' },
    { label: '+994', value: '+994' },
    { label: '+995', value: '+995' },
    { label: '+996', value: '+996' },
    { label: '+998', value: '+998' }
  ];

  public static COUNTRY_CODE_SHUTTLE = [
    // { label: 'Code', value: '' },
    { label: '+1', value: '1' },
    { label: '+7', value: '7' },
    { label: '+20', value: '20' },
    { label: '+27', value: '27' },
    { label: '+30', value: '30' },
    { label: '+31', value: '31' },
    { label: '+32', value: '32' },
    { label: '+33', value: '33' },
    { label: '+34', value: '34' },
    { label: '+36', value: '36' },
    { label: '+39', value: '39' },
    { label: '+40', value: '40' },
    { label: '+41', value: '41' },
    { label: '+43', value: '43' },
    { label: '+44', value: '44' },
    { label: '+45', value: '45' },
    { label: '+46', value: '46' },
    { label: '+47', value: '47' },
    { label: '+48', value: '48' },
    { label: '+49', value: '49' },
    { label: '+51', value: '51' },
    { label: '+52', value: '52' },
    { label: '+53', value: '53' },
    { label: '+54', value: '54' },
    { label: '+55', value: '55' },
    { label: '+56', value: '56' },
    { label: '+57', value: '57' },
    { label: '+58', value: '58' },
    { label: '+60', value: '60' },
    { label: '+61', value: '+61' },
    { label: '+61', value: '61' },
    { label: '+62', value: '62' },
    { label: '+63', value: '63' },
    { label: '+64', value: '64' },
    { label: '+65', value: '65' },
    { label: '+66', value: '66' },
    { label: '+81', value: '81' },
    { label: '+82', value: '82' },
    { label: '+84', value: '84' },
    { label: '+86', value: '86' },
    { label: '+90', value: '90' },
    { label: '+91', value: '91' },
    { label: '+92', value: '92' },
    { label: '+93', value: '93' },
    { label: '+94', value: '94' },
    { label: '+95', value: '95' },
    { label: '+98', value: '98' },
    { label: '+212', value: '212' },
    { label: '+213', value: '213' },
    { label: '+216', value: '216' },
    { label: '+218', value: '218' },
    { label: '+220', value: '220' },
    { label: '+221', value: '221' },
    { label: '+222', value: '222' },
    { label: '+223', value: '223' },
    { label: '+224', value: '224' },
    { label: '+225', value: '225' },
    { label: '+226', value: '226' },
    { label: '+227', value: '227' },
    { label: '+228', value: '228' },
    { label: '+229', value: '229' },
    { label: '+230', value: '230' },
    { label: '+231', value: '231' },
    { label: '+232', value: '232' },
    { label: '+233', value: '233' },
    { label: '+234', value: '234' },
    { label: '+235', value: '235' },
    { label: '+236', value: '236' },
    { label: '+237', value: '237' },
    { label: '+238', value: '238' },
    { label: '+239', value: '239' },
    { label: '+240', value: '240' },
    { label: '+241', value: '241' },
    { label: '+242', value: '242' },
    { label: '+243', value: '243' },
    { label: '+244', value: '244' },
    { label: '+245', value: '245' },
    { label: '+248', value: '248' },
    { label: '+249', value: '249' },
    { label: '+250', value: '250' },
    { label: '+251', value: '251' },
    { label: '+252', value: '252' },
    { label: '+253', value: '253' },
    { label: '+254', value: '254' },
    { label: '+255', value: '255' },
    { label: '+256', value: '256' },
    { label: '+257', value: '257' },
    { label: '+258', value: '258' },
    { label: '+260', value: '260' },
    { label: '+261', value: '261' },
    { label: '+262', value: '262' },
    { label: '+263', value: '263' },
    { label: '+264', value: '264' },
    { label: '+265', value: '265' },
    { label: '+266', value: '266' },
    { label: '+267', value: '267' },
    { label: '+268', value: '268' },
    { label: '+269', value: '269' },
    { label: '+290', value: '290' },
    { label: '+291', value: '291' },
    { label: '+297', value: '297' },
    { label: '+298', value: '298' },
    { label: '+299', value: '299' },
    { label: '+350', value: '350' },
    { label: '+351', value: '351' },
    { label: '+352', value: '352' },
    { label: '+353', value: '353' },
    { label: '+355', value: '355' },
    { label: '+356', value: '356' },
    { label: '+357', value: '357' },
    { label: '+358', value: '358' },
    { label: '+359', value: '359' },
    { label: '+370', value: '370' },
    { label: '+371', value: '371' },
    { label: '+372', value: '372' },
    { label: '+373', value: '373' },
    { label: '+374', value: '374' },
    { label: '+375', value: '375' },
    { label: '+376', value: '376' },
    { label: '+377', value: '377' },
    { label: '+378', value: '378' },
    { label: '+380', value: '380' },
    { label: '+381', value: '381' },
    { label: '+382', value: '382' },
    { label: '+385', value: '385' },
    { label: '+386', value: '386' },
    { label: '+387', value: '387' },
    { label: '+389', value: '389' },
    { label: '+420', value: '420' },
    { label: '+421', value: '421' },
    { label: '+423', value: '423' },
    { label: '+500', value: '500' },
    { label: '+501', value: '501' },
    { label: '+502', value: '502' },
    { label: '+503', value: '503' },
    { label: '+504', value: '504' },
    { label: '+505', value: '505' },
    { label: '+506', value: '506' },
    { label: '+507', value: '507' },
    { label: '+508', value: '508' },
    { label: '+509', value: '509' },
    { label: '+590', value: '590' },
    { label: '+591', value: '591' },
    { label: '+592', value: '592' },
    { label: '+593', value: '593' },
    { label: '+595', value: '595' },
    { label: '+597', value: '597' },
    { label: '+598', value: '598' },
    { label: '+670', value: '670' },
    { label: '+672', value: '672' },
    { label: '+673', value: '673' },
    { label: '+674', value: '674' },
    { label: '+675', value: '675' },
    { label: '+676', value: '676' },
    { label: '+677', value: '677' },
    { label: '+678', value: '678' },
    { label: '+679', value: '679' },
    { label: '+680', value: '680' },
    { label: '+681', value: '681' },
    { label: '+682', value: '682' },
    { label: '+683', value: '683' },
    { label: '+685', value: '685' },
    { label: '+686', value: '686' },
    { label: '+687', value: '687' },
    { label: '+688', value: '688' },
    { label: '+689', value: '689' },
    { label: '+690', value: '690' },
    { label: '+691', value: '691' },
    { label: '+692', value: '692' },
    { label: '+850', value: '850' },
    { label: '+852', value: '852' },
    { label: '+853', value: '853' },
    { label: '+855', value: '855' },
    { label: '+856', value: '856' },
    { label: '+870', value: '870' },
    { label: '+880', value: '880' },
    { label: '+886', value: '886' },
    { label: '+960', value: '960' },
    { label: '+961', value: '961' },
    { label: '+962', value: '962' },
    { label: '+963', value: '963' },
    { label: '+964', value: '964' },
    { label: '+965', value: '965' },
    { label: '+966', value: '966' },
    { label: '+967', value: '967' },
    { label: '+968', value: '968' },
    { label: '+971', value: '971' },
    { label: '+972', value: '972' },
    { label: '+973', value: '973' },
    { label: '+974', value: '974' },
    { label: '+975', value: '975' },
    { label: '+976', value: '976' },
    { label: '+977', value: '977' },
    { label: '+992', value: '992' },
    { label: '+993', value: '993' },
    { label: '+994', value: '994' },
    { label: '+995', value: '995' },
    { label: '+996', value: '996' },
    { label: '+998', value: '998' }
  ];
  public static CARS_ICONS = [
    { class: 'car-icon-0', value: '' },
    { class: 'car-icon-1', value: '1' },
    { class: 'car-icon-2', value: '2' },
    { class: 'car-icon-3', value: '3' },
    { class: 'car-icon-4', value: '4' },
    { class: 'car-icon-5', value: '5' },
    { class: 'car-icon-6', value: '6' },
    { class: 'car-icon-7', value: '7' },
    { class: 'car-icon-8', value: '8' },
    { class: 'car-icon-9', value: '9' },
    { class: 'car-icon-10', value: '10' },
    { class: 'car-icon-11', value: '11' },
    { class: 'car-icon-12', value: '12' },
    { class: 'car-icon-13', value: '13' },
    { class: 'car-icon-14', value: '14' },
    { class: 'car-icon-15', value: '15' },
    { class: 'car-icon-16', value: '16' },
    { class: 'car-icon-17', value: '17' },
    { class: 'car-icon-18', value: '18' },
    { class: 'car-icon-19', value: '19' },
    { class: 'car-icon-20', value: '20' },
    { class: 'car-icon-21', value: '21' },
    { class: 'car-icon-22', value: '22' },
    { class: 'car-icon-23', value: '23' },
    { class: 'car-icon-24', value: '24' },
    { class: 'car-icon-25', value: '25' },
    { class: 'car-icon-26', value: '26' },

  ];


  public static LOGGED_IN_ROLE = '';
  public static ADMINROLEID = 'Tenant Admin';
  public static DISPATCHERROLEID = 'Dispatcher Admin';
  public static CORPORATEROLEID = 'Corporate Admin';
  public static HOTELBOOKINGROLECODE = 'Hotel Booking';
  public static CORPORATEBOOKINGROLECODE = 'Corporate Booking';
  public static EMPLOYEE_ID = '';
  public static DEPARTMENT_ID = '';

  public static SALES_QUOTATION_TRIP_STATUS = [
    {
      label: 'Select',
      value: ''
    }, {
      label: 'New Request',
      value: 'NEW_REQUEST'
    }, {
      label: 'Quote Sent',
      value: 'QUOTE_SENT'
    }, {
      label: 'Quote Rejected',
      value: 'QUOTE_REJECTED'
    }, {
      label: 'Quote Accepted',
      value: 'QUOTE_ACCEPTED'
    }, {
      label: 'Cancelled',
      value: 'CANCELED'
    }
  ];

  public static BOOKING_DRIVER_ASSIGNED_STATUS = [20, 90, 70, 80, 40, 130, 120, 170, 50, 140, 150, 160, 100];



  public static RENTAL_SERVICE_TYPE = [
    'Rental with driver with fuel',
    'Rental without driver without fuel',
    'Rental with driver without fuel',
    'Rental without driver with fuel',
    'Chauffeur'
  ];

  public static ASSIGN_BOOKING_STATUS_ARRAY = [10];
  public static DRIVER_IS_ARRIVED = [90];
  public static BOOOKING_QUOTATION_ACCEPTED = [30];
  public static REASSIGN_BOOKING_STATUS_ARRAY = [80, 40, 41];
  public static COMPLETE_BOOKING_STATUS_ARRAY = [130, 120, 170, 50];
  public static DRIVER_ON_THE_WAY = [80];
  public static SECURITY_CHECK = [100];
  public static END_TRIP = [110];
  public static CAR_RETURN_CHECK = [140];
  public static RETURN_SECURITY_CHECK = [150];
  public static ON_TRIP = [130];

  public static BOOKING_DETAILS_STATUS_DISABLE_ARRAY = [
    120, 170, 50, 30
  ];

  public static BOOKING_STATUS = {
    END_TRIP: 140,
  }




  public static TRANSMISSION_TYPE = [
    {
      label: 'Please select',
      value: ''
    },
    {
      label: 'Both (Automatic/Manual)',
      value: 'BOTH'
    },
    {
      label: 'Automatic',
      value: 'AUTO'
    },
    {
      label: 'Manual',
      value: 'MANL'
    }
  ];

  public static HOTEL_PRICING_LINK = 'pricing-packages-details';
  public static HOTEL_ROUTE_LINK = 'route-pricing-details';
  public static HOTEL_Profile_image = '/assets/images/temp/default_hotel.png';
  public static NOTIFICATION_INTERVAL = 60 * 1000; // 1 min


  public static MAP_OPTION = {
    center: {
      lat: 27.7676, lng: 82.6403
    },
    streetViewControl: false,
    zoom: 11,
    zoomControl: false
  };
  // public static MAP_OPTIONS_POSITION = { lat: 27.7676, lng: 82.6403 };
  public static MAP_OPTIONS_POSITION = { lat: 40.713050, lng: -74.007230 };

  public static SERVICE_TYPE_RENTAL = 'Rental';
  public static SERVICE_TYPE_TAXI = 'Taxi';
  public static SERVICE_TYPE_OCCASIONAL = 'Occasional Ride';

  public static DATE_FORMATTER = 'MMM DD, YYYY hh:mm A';

  public static PASSENGER_TYPES = [
    {
      label: 'Private',
      value: 'Private'
    }, {
      label: 'Corporate',
      value: 'corporate'
    }
  ];

  public static PASSENGER_TYPES_MANUAL_BOOKING = [
    { label: 'Please Select', value: '' },
    { label: 'Guest Users', value: 'guestPassenger' },
    { label: 'App Users', value: 'appPassenger' }
  ];

  public static DRIVER_TYPES_MANUAL_BOOKING = [
    { label: 'Please Select', value: '' },
    { label: 'Select Manually', value: 'selectDriver' },
    { label: 'Auto Dispatch', value: 'automatic' },
  ];

  public static twentyThreeFiftyNineLapse = 86399000;

  public static IGNORE_LINK = ['edit-corporate', 'corporate-details', 'hotel-details', 'reports', 'settings'];

  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE = 10000000;

  public static SECURITY_CHECK_TYPE = {
    security: 'SECURITY_CHECK',
    delivery: 'DELIVERY_CHECK',
    carReturn: 'CAR_RETURN_CHECK',
    returnSecurity: 'RETURN_SECURITY_CHECK',
  };

  public static PAYMENT_MODE = [{
    label: 'Cash',
    value: 'CASH'
  }];
  public static DRIVER_DUTY_STATUS_OPTIONS = [
    { label: 'ON', value: true },
    { label: 'OFF', value: false },
  ];

  public static DRIVER_STATUS_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' }
  ];

  public static DRIVER_AVAILABLE_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' }
  ];
  public static PAYMENT_GATEWAY = 'payment_gateway'
  public static PAYMENT_GATEWAY_WING = 'WING'
  public static PAYMENT_TYPES = 'payment_types'
  public static DEFAULT_MAP_ROUTE_COLOR = '#06A0FF';
  public static DEFAULT_ROUTE_COLOR = '#000000';
  public static IS_OCCASIONAL_TRIP = 'occasional_trip';
  public static IS_CAPTURE_ACTIVITY_LOGS = 'capture_activity_logs';
  public static HEADER_NOT_REQUIRED = ['/signin', '/trackmylocation', '/'];
  public static GENDER_OPTION = [
    { label: 'Please select', value: '' },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
  ];

  public static SUB_BOOKINMG_SERVICE_TYPES = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
    OTHER: 'OTHER',
    ALL:'ALL'
  };

  public static SUB_BOOKINMG_SERVICE_TYPES_LIST = {
    INBOUNDOTHER: 'INBOUNDOTHER',
    ALL: 'ALL',
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
    CRITICAL: 'CRITICAL',
    VEHICLETRANSFER: 'VEHICLETRANSFER',
    THIRDPARTY: 'THIRDPARTY',
    LUGGAGEREQUEST: 'LUGGAGEREQUEST',
    HISTORY: 'HISTORY',
    OTHER: 'OTHER'

  };

  public static SUB_SERVICE_TYPES_OPTIONS_LIST_FOR_REPORTS = [
    { label: 'Inbound', value: 'INBOUND' },
    { label: 'Outbound', value: 'OUTBOUND' },
    // { label: 'Critical', value: 'CRITICAL' },
    { label: 'Vehicle Transfer', value: 'VEHICLE_TRANSFER' },
    { label: 'Third Party', value: 'THIRD_PARTY' }
  ];

  public static LOCATION_MAP_STYLE = [
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e9e9e9'
        },
        {
          'lightness': 17
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        },
        {
          'lightness': 20
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 17
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 29
        },
        {
          'weight': 0.2
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 18
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 16
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        },
        {
          'lightness': 21
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#dedede'
        },
        {
          'lightness': 21
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'visibility': 'on'
        },
        {
          'color': '#ffffff'
        },
        {
          'lightness': 16
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'saturation': 36
        },
        {
          'color': '#333333'
        },
        {
          'lightness': 40
        }
      ]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f2f2f2'
        },
        {
          'lightness': 19
        }
      ]
    },
    {
      'featureType': 'administrative',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#fefefe'
        },
        {
          'lightness': 20
        }
      ]
    },
    {
      'featureType': 'administrative',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'color': '#fefefe'
        },
        {
          'lightness': 17
        },
        {
          'weight': 1.2
        }
      ]
    }
  ];

  public static YEAR_RANGE_SELECTION = '2000:2035'

  public static JOURNEY_TYPES = [
    'GENERAL',
    'VEHICLE_TRANSFER',
    'SHUTTLE',
    'SPECIAL_EVENT',
    'THIRD_PARTY'
  ];

  public static JOURNEY_TYPE_VALUES = {
    GENERAL: 'GENERAL',
    VEHICLE_TRANSFER: 'VEHICLE_TRANSFER',
    SHUTTLE: 'SHUTTLE',
    SPECIAL_EVENT: 'SPECIAL_EVENT',
    THIRD_PARTY: 'THIRD_PARTY'
  };

  public static PREFERED_FOR_USERS_TYPES = {
    PLAYER_COACH: 'PLAYER_COACH',
    NON_PLAYER_COACH: 'NON_PLAYER_COACH',
    ALL: 'ALL',
  };

  public static DUMMY_VEHICLES_FILTER = [
    { label: 'All', value: '' },
    { label: 'Actual', value: 'ACTUAL' },
    { label: 'Dummy', value: 'DUMMY' },
  ];

  // old
  // public static BOOKING_STATUS_CODES_OPTIONS = [
  //   { label: 'New Request', value: 10, disabled: true },
  //   { label: 'Driver Assigned', value: 40, disabled: true },
  //   { label: 'Accepted', value: 41 },
  //   { label: 'Driver On The Way', value: 80 },
  //   { label: 'Driver Arrived', value: 90 },
  //   { label: 'On Trip', value: 130 },
  //   { label: 'Completed', value: 140 },
  //   { label: 'Expired', value: 71, disabled: true },
  //   { label: 'Cancelled', value: 120 },
  // ];

  // public static BOOKING_STATUS_CODES_FILTER_OPTIONS = [
  //   { label: 'All', value: '' },
  //   { label: 'New Request', value: 10 },
  //   { label: 'Driver Assigned', value: 40 },
  //   { label: 'Accepted', value: 41 },
  //   { label: 'Driver On The Way', value: 80 },
  //   { label: 'Driver Arrived', value: 90 },
  //   { label: 'On Trip', value: 130 },
  //   { label: 'Completed', value: 140 },
  //   { label: 'Expired', value: 71 },
  //   { label: 'Cancelled', value: 120 },
  // ];

  // old
  // public static BOOKING_STATUS_CODES = {
  //   NEW_REQUEST: 10,
  //   ADMIN_CONFIRMED: 25,
  //   DRIVER_ASSIGNED: 40,
  //   DRIVER_CONFIRMED: 43,
  //   DRIVER_RECONFIRM: 45,
  //   ACCEPTED: 47,
  //   DRIVER_ON_THE_WAY: 80,
  //   DRIVER_ARRIVED: 90,
  //   ON_TRIP: 130,
  //   COMPLETED: 140,
  //   EXPIRED: 75,
  //   CANCELED: 120,
  // };

  public static BOOKING_STATUS_CODES = {
    NEW_REQUEST: 10,
    ADMIN_CONFIRMED: 25,
    DRIVER_ASSIGNED: 40,
    DRIVER_CONFIRMED: 43,
    DRIVER_STATUS_45: 45,
    DRIVER_RECONFIRM: 47,
    DRIVER_ON_THE_WAY: 80,
    DRIVER_ARRIVED: 90,
    ON_TRIP: 130,
    COMPLETED: 140,
    EXPIRED: 75,
    CANCELED: 120,
  };

  public static DRIVER_ASSIGN_CONDITIONS = [
    AppSettings.BOOKING_STATUS_CODES.ADMIN_CONFIRMED,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_ASSIGNED,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_CONFIRMED,

    AppSettings.BOOKING_STATUS_CODES.DRIVER_RECONFIRM,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_ON_THE_WAY,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_ARRIVED,
    AppSettings.BOOKING_STATUS_CODES.ON_TRIP,
  ];

  // test line added to check

  // used in AELTC
  // public static BOOKING_STATUS_CODES_OPTIONS = [
  //   { label: 'New Request', value: 10, disabled: true },
  //   { label: 'Admin Confirmed', value: 25 },
  //   { label: 'Driver Assigned', value: 40, disabled: true },
  //   { label: 'Acknowledged', value: 43 },
  //   // { label: 'Driver Reconfirm', value: 45, disabled: true },
  //   { label: 'Reconfirmed', value: 47 },
  //   { label: 'Driver On The Way', value: 80 },
  //   { label: 'Driver Arrived', value: 90 },
  //   { label: 'On Trip', value: 130 },
  //   { label: 'Completed', value: 140 },
  //   { label: 'Expired', value: 75, disabled: true },
  //   { label: 'Cancelled', value: 120 },
  // ];

  // us open usefull
  public static BOOKING_STATUS_CODES_OPTIONS = [
    { label: 'New Request', value: 10 },
    { label: 'Admin Confirmed', value: 25 },
    // { label: 'Driver Assigned', value: 40, disabled: true },
    { label: 'Driver Assigned', value: 43 }, // prevouusly Acknowledge
    // { label: 'Driver Reconfirm', value: 45, disabled: true },
    { label: 'Acknowledged', value: 47 }, // prevouusly reconfirmed
    { label: 'Driver On The Way', value: 80 },
    { label: 'Driver Arrived', value: 90 },
    { label: 'On Trip', value: 130 },
    { label: 'Completed', value: 140 },
    { label: 'Expired', value: 75, disabled: true },
    { label: 'Cancelled', value: 120 },
  ];

  public static BOOKING_STATUS_CODES_FILTER_OPTIONS = [
    // { label: 'All', value: '' },
    { label: 'New Request', value: 10 },
    { label: 'Admin Confirmed', value: 25 },
    // { label: 'Driver Assigned', value: 40 },
    { label: 'Driver Assigned', value: 43 }, // prevouusly Acknowledge
    // { label: 'Driver Reconfirm', value: 45 },
    { label: 'Acknowledged', value: 47 }, // prevouusly reconfirmed
    { label: 'Driver On The Way', value: 80 },
    { label: 'Driver Arrived', value: 90 },
    { label: 'On Trip', value: 130 },
  ];

  public static THIRD_PARTY_BOOKING_STATUS_CODES_OPTIONS = [
    { label: 'Admin Confirmed', value: 25, disabled: false },
    { label: 'Acknowledged', value: 47, disabled: false },
    { label: 'Completed', value: 140, disabled: false },
    { label: 'Cancelled', value: 120, disabled: false },
  ];

  // USED FOR HISTORY
  public static BOOKING_HISTORY_STATUS_CODES_FILTER_OPTIONS = [
    { label: 'Completed', value: 140 },
    { label: 'Expired', value: 75 },
    { label: 'Cancelled', value: 120 },
  ];

  // USED FOR SUMMARY REPORTS 
  public static BOOKING_STATUS_CODES_FILTER_OPTIONS_SUMMARY_REPORT = [
    // { label: 'All', value: '' },
    { label: 'New Request', value: 10 },
    { label: 'Admin Confirmed', value: 25 },
    // { label: 'Driver Assigned', value: 40 },
    // { label: 'Acknowledged', value: 43 },
    { label: 'Driver Assigned', value: 43 },

    // { label: 'Driver Reconfirm', value: 45 },
    // { label: 'Reconfirmed', value: 47 },
    { label: 'Acknowledged', value: 47 },
    { label: 'Driver On The Way', value: 80 },
    { label: 'Driver Arrived', value: 90 },
    { label: 'On Trip', value: 130 },
    { label: 'Completed', value: 140 },
    { label: 'Expired', value: 75 },
    { label: 'Cancelled', value: 120 },
  ];

  // USED ON BOOKING DETAILS & ON FILTER
  public static JOURNEY_TYPES_TO_SHOW = [
    { label: 'Passenger', value: 'GENERAL' },
    { label: 'Vehicle Transfer', value: 'VEHICLE_TRANSFER' },
    { label: 'Shuttle', value: 'SHUTTLE' },
    { label: 'Special Event', value: 'SPECIAL_EVENT' },
    { label: 'Third Party', value: 'THIRD_PARTY' },
  ];

   // USED ON BOOKING DETAILS & ON FILTER
   public static JOURNEY_TYPES_OF_TAB= [
    { label: 'Inbound', value: 'INBOUND' },
    { label: 'Outbound', value: 'OUTBOUND' },
    { label: 'Other', value: 'OTHER' },
    { label: 'Vehicle Transfer', value: 'VEHICLE_TRANSFER' },
    { label: 'Special Request', value: 'LUGGAGEBOOKINGS' },
  ];

  // bookingHistory list page
  public static HISTORY_JOURNEY_TYPES = [
    { label: 'Passenger', value: 'GENERAL' },
    { label: 'Vehicle Transfer', value: 'VEHICLE_TRANSFER' },
    // { label: 'Shuttle', value: 'SHUTTLE' },
    // { label: 'Special Event', value: 'SPECIAL_EVENT' },
    // { label: 'Third Party', value: 'THIRD_PARTY' },
  ];

  public static EMERGENCY_STATUS_CODES_OPTIONS = [
    { label: 'New', value: 1 },
    { label: 'Attended', value: 2 },
    { label: 'Closed', value: 3 },
  ];

  public static EMERGENCY_STATUS_CODES_OPTIONS1 = [
    { label: 'New', value: 'NEW' },
    { label: 'Attended', value: 'ATTENDED' },
    { label: 'Closed', value: 'CLOSED' },
  ];

  public static EMERGENCY_STATUS_CODES_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'New', value: 1 },
    { label: 'Attended', value: 2 },
    { label: 'Closed', value: 3 },
  ];

  public static EMERGENCY_USER_TYPES_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Passenger', value: 'Passenger' },
    { label: 'Driver', value: 'Driver' },
  ];

  public static SHIFT_STATUS_OPTIONS = [
    { label: 'ALL', value: 'ALL' },
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' },
  ];

  public static DRIVER_TYPE_OPTIONS = [
    { label: 'ALL', value: 'all' },
    { label: 'Available', value: 'available' },
    { label: 'Busy', value: 'busy' },
    { label: 'check-In', value: 'checkedin' },
  ];

  public static SHIFT_STATUS_FILTER_OPTIONS = [
    { label: 'ALL', value: '' },
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' },
  ];
  public static DRIVER_ASSIGN_SORT_KEYS = [
    'idleTime',
    'nearby'
  ];

  public static LOCATION_CATEGORY_OPTIONS = [
    { label: 'QUEUE', value: 'locCatId00002' },
    { label: 'PARKING', value: 'locCatId00001' },
  ];
  public static PARKING_LOCATION_ID = 'locCatId00001';
  public static MAX_PWC_COUNT = 100;

  public static EDIT_BOOKING_AVAILABLE_ON = [
    AppSettings.BOOKING_STATUS_CODES.NEW_REQUEST,
    AppSettings.BOOKING_STATUS_CODES.ADMIN_CONFIRMED,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_ASSIGNED,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_CONFIRMED,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_STATUS_45,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_RECONFIRM,
  ];


  public static FILTER_SCREEN_CODE = {
    INBOUND_OTHER_SCREEN: 'inbound_and_other_bookings_screen',
    INBOUND_SCREEN: 'inbound_screen',
    OUTBOUND_SCREEN: 'outbound_screen',
    CRITICAL_SCREEN: 'critical_screen',
    OTHER_SCREEN: 'other_journey_screen',
    VEHICLE_TRANSFER_SCREEN: 'vehicle_transfer_screen',
    THIRD_PARTY_TRANSFER_SCREEN: 'third_party_transfer_screen',
    BOOKING_HISTORY_SCREEN: 'booking_history_screen',
    EMERGENCY_SCREEN: 'emergency_screen',
    SHIFT_GROUPS_SCREEN: 'shift_groups_screen',
    DRIVERS_SCREEN: 'drivers_screen',
    DRIVER_DASHBOARD_SCREEN: 'driver_dashboard_screen',
    PASSENGERS_SCREEN: 'passengers_screen',
    VEHICLES_SCREEN: 'vehicles_screen',
    EVENT_SCREEN: 'manage_events',
    INCIDENT_COMPLAINT_SCREEN: 'manage_incidents_complaints',
    LOCATIONS_SCREEN: 'locations_screen',
    OUTBOUND_QUEUE_SCREEN: 'manage_queue_drivers_screen',
    ADD_DRIVER_IN_QUEUE_SCREEN: 'add_drivers_in_queue_screen',
    DRIVER_LOGS_FROM_TO_DATE_SCREEN: 'driver_activity_logs_screen',
    PASSENGER_LOGS_FROM_TO_DATE_SCREEN: 'passenger_activity_logs_screen',
    VEHICLE_LOGS_FROM_TO_DATE_SCREEN: 'vehicle_activity_logs_screen',
    REPORT_DRIVER_LOGIN_LOGOUT_DETAILS_SCREEN: 'report_driver_login_logout_details_screen',
    REPORT_DRIVER_ON_DUTY_DETAILS_SCREEN: 'report_driver_on_duty_details_screen',
    REPORT_DRIVER_PERFORMANCE_DETAILS_SCREEN: 'report_driver_performance_details_screen',
    REPORT_CAR_MILEAGE_DETAILS_SCREEN: 'report_car_mileage_details_screen',
    REPORT_PASSENGER_FEEDBACK_DETAILS_SCREEN: 'report_passenger_feedback_details_screen',
    REPORT_PASSENGER_BOOKING_DETAILS_SCREEN: 'report_passenger_bookings_details_screen',
    REPORT_PASSENGER_FEEDBACK_SCREEN: 'report_passenger_feedback_screen',
    REPORT_PASSENGER_BOOKING_SCREEN: 'report_passenger_bookings_screen',
    CRITICAL_SCREEN_OPEN_CLOSE_FILTER_PERSIST: 'openCloseCritacalFilter',
    REPORT_DRIVER_CHECKIN_SCREEN: 'report_driver_checkin_screen',
    REPORT_DRIVER_DUTY_STATUS_SCREEN: 'report_driver_duty_status_screen',
    REPORT_SHIFT_STATUS_SCREEN: 'report_shift_status_screen',
    REPORT_BOOKING_SUMMARY_SCREEN: 'report_booking_summary_screen',
    SHUTTLES_LIST_SCREEN: 'shuttle_list_screen',
    SHUTTLE_DRIVER_LIST_SCREEN: 'shuttle_driver_list_screen',
    SHUTTLE_ROUTES_SCREEN: 'shuttle_routes_screen',
    SHUTTLE_SCHEDULES_SCREEN: 'shuttle_schedules_screen',
    SHUTTLE_TOURS_LIST_SCREEN: 'shuttle_tours_list_screen',
    SHUTTLE_BOOKINGS_SCREEN: 'shuttle_bookings_screen',
  };

  public static BOOKING_SORT_BY_OPTIONS = [
    // s{ label: '', value: '' },
    { label: 'Pickup date time', value: 'pickupdatetime' },
    { label: 'Departure time', value: 'dispatchtime' },
  ];

  public static BOOKING_SORT_BY_TIME_OPTIONS = [
    // s{ label: '', value: '' },
    { label: 'Pickup Time Ascending', value: 'pickup_time_acsending' },
    { label: 'Pickup Time Descending', value: 'pickup_time_descending' },
  ];


  public static OPEN_CLOSE_FILTERS = {  
    OPEN_CLOSE_INBOUND_FILTER: 'openCloseInboundFilter',
    OPEN_CLOSE_CRITICAL_FILTER: 'openCloseCritacalFilter',
    OPEN_CLOSE_OUTBOUND_FILTER: 'openCloseOutboundFilter',
    OPEN_CLOSE_VEHICLE_TRANSFER_FILTER: 'openCloseVehicleTransferFilter',
    OPEN_CLOSE_THIRD_PARTY_FILTER: 'openCloseThirdPartyFilter',
  };

  public static PRIORITY_OPTIONS = [
    { priority: 'VIP', inboundStartTime: 0, inboundEndTime: 0, outboundStartTime: 0, outboundEndTime: 0 },
    { priority: '1', inboundStartTime: 0, inboundEndTime: 0, outboundStartTime: 0, outboundEndTime: 0 },
    { priority: '2', inboundStartTime: 0, inboundEndTime: 0, outboundStartTime: 0, outboundEndTime: 0 },
    { priority: '3', inboundStartTime: 0, inboundEndTime: 0, outboundStartTime: 0, outboundEndTime: 0 },
    { priority: '4', inboundStartTime: 0, inboundEndTime: 0, outboundStartTime: 0, outboundEndTime: 0 },
  ];

  //vehicle types for add assign driver screen 
  public static VEHICLE_TYPE_IDS = {
    SUV: 'vehicleType0001',
    SPRINTER: 'vehicleType0002',
    SEDAN: 'vehicleType0003'
  };

  public static PREFFERED_FOR_USER_TYPE_OPTIONS = [
    { label: 'All', value: 'ALL' },
    { label: 'Player and Coach', value: 'PLAYER_COACH' },
    { label: 'Non Player and Coach', value: 'NON_PLAYER_COACH' },
  ];

  public static AMENITIES_LIST = [
    {
      "id": "1",
      "busId": 0,
      "busAmenityTypeId": 0,
      "amenityName": "Charging Point",
      "activeFile": "assets/images/temp/charging_point_active.svg",
      "inactiveFile": "assets/images/temp/charging_point_deactive.svg",
      "isActive": false,
    },
    {
      "id": "2",
      "busId": 0,
      "busAmenityTypeId": 0,
      "amenityName": "Fridge",
      "activeFile": "assets/images/temp/fridge_active.svg",
      "inactiveFile": "assets/images/temp/fridge_deactive.svg",
      "isActive": false,
    },
    {
      "id": "3",
      "busId": 0,
      "busAmenityTypeId": 0,
      "amenityName": "Toilet",
      "activeFile": "assets/images/temp/toilet_active.svg",
      "inactiveFile": "assets/images/temp/toilet_deactive.svg",
      "isActive": false,
    },
    {
      "id": "4",
      "busId": 0,
      "busAmenityTypeId": 0,
      "amenityName": "Wheel Chair",
      "activeFile": "assets/images/temp/wheel_chair_active.svg",
      "inactiveFile": "assets/images/temp/wheel_chair_deactive.svg",
      "isActive": false,
    }
  ];
  static DESTINATION_POINT_KEY = 'destinations';
  static SHUTTLE_VEHICLE_TYPE_ID = 'vehicleType0002';
  static VEHICLE_CONDITION = '3';
  static SHUTTLE_OWNERSHIPID = '2';
  static BUS_TYPE_ID = '3';
  static HAS_AC = 'yes';
  static SAMPLE_SCHEDULE_FILE = '4726a62d69424e7cae55a04593d6c7e8.xlsx';
  static SCREEN_CODE_SHUTTLE_SCHEDULE = 'shuttle_schedules';
  static SCREEN_CODE_SHUTTLE_SCHEDULE_UPDATE = 'shuttle_schedules_update';


  static SHUTTLE_STATUS_FILTER = [
    { label: 'All', value: '' },
    { label: 'Activated', value: 'true' },
    { label: 'Deactivated', value: 'false' }
  ];

  static SHUTTLE_TOUR_STATUS_FILTER = [
    { label: 'All', value: '' },
    { label: 'New', value: 'New' },
    { label: 'Started', value: 'Started' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Expired', value: 'Expired' },
    { label: 'Ended', value: 'Ended' }
  ];

}
