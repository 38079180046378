<div class="signin-box-container scroll-bar-css ui-g">
  <!-- New Sign In Page -->
  <div class="signin-box-inner-container">
    <div class="ui-g-12 ui-g-nopad sign-in-text">
      <div class="ui-lg-6 ui-lg-offset-3 ui-md-8 ui-md-offset-2 ui-sm-12 ui-sm-offset-0 sign-in-content">
        {{signInDescription}}</div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-12 ui-md-4 ui-lg-4 ui-sm-12 ui-lg-offset-4 ui-md-offset-4 ui-sm-offset-0 ui-g-nopad">
        <p-panel>
          <div class="form-field ui-g-12 ui-g-nopad">
            <div class="text-center"><img src="/assets/images/temp/MI_black_logo.png"></div>
            <div class="form-field ui-g-12 switch-btn">Login</div>
          </div>
          <div class="form-field ui-g-12" style="padding: 0;">
            <div class="form-errors alert alert-danger" [hidden]='!loginErrors[0]["general"]'>
              <div *ngFor="let ErrorMessage of loginErrors['general']">
                {{ErrorMessage.message}}
              </div>
            </div>
          </div>
          <div class="form-field ui-g-12" style="padding: 0;">
            <div class="form-errors alert alert-danger" [hidden]='!loginErrors[0]["email"]'>
              <div *ngFor="let ErrorMessage of loginErrors['email']">
                {{ErrorMessage.message}}
              </div>
            </div>
          </div>

          <app-login (onSignInEmitter)="doSignIn($event)" (onForgotPasswordEmitter)="forgotPassword()"></app-login>
          <div style="clear: both;"></div>

        </p-panel>

      </div>
    </div>
    <div  class="ui-g-12 sign-in-text" style="text-align: center;position: fixed; bottom:0;padding-bottom:0;">
      <a [href]="signInService.poweredByRedirectBeforeLoginUrl" target="_blank" class="sign-in-content">  
        <img [src]="signInService.poweredByBeforeLogoUrl" style="width: 3em;">
      </a>
    </div>
  </div>
  <!-- <div  class="ui-g-12">
    <a href="https://mobisoftinfotech.com/" target="_blank">  
      <img src="/assets/images/temp/powered-by-logo.png">
    </a>
  </div> -->
  <!-- End Sign In Page -->

</div>



<p-dialog modal="false" styleClass="edit-popup" position="center center" responsive="true" [(visible)]="forgotPopup"
  [closable]="false">
  <p-header>{{labelResetPassword}} </p-header>
  <app-forgot-password (onPopClose)="onPopClose($event)" (onPopSave)="onPopSave($event)"></app-forgot-password>
</p-dialog>

<p-dialog modal="false" styleClass="edit-popup" position="center center" responsive="true"
  [(visible)]="otpPopup" [closable]="false" [contentStyle] = "{'width': '500px'}">
  <p-header>{{labelValidateOtp}}</p-header>
  <app-otp-verifiy [email]="otpEmail" [phone]="otpPhone" (onOtpPopClose)="onOtpPopClose($event)"
    (onOtpPopSave)="onOtpPopSave($event)"></app-otp-verifiy>
</p-dialog>

<p-dialog modal="false" styleClass="edit-popup" position="center center" responsive="true"
  [(visible)]="setPasswordPopup" [closable]="false">
  <p-header>{{labelResetPassword}}</p-header>
  <app-set-password [otpId]="otpId" (onSetPasswordPopClose)="onSetPasswordPopClose($event)"></app-set-password>
</p-dialog>

<p-growl [value]="message"></p-growl>