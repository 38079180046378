import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService, TranslatePipe, TranslateDirective } from '@ngx-translate/core';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { Message } from 'primeng/components/common/api';

import { AppSettings } from '../../../shared/app.settings';
import { StorageService } from './../../services/storage.service';
import { UiService } from './../../services/ui.service';
import { UserCommonService } from './../../services/user-common.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent implements OnInit {

  @Input() editProfilePopup: boolean;
  @Output() showProfilePopup: EventEmitter<any> = new EventEmitter();
  editProfileForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl(),
    username: new FormControl(),
    phone: new FormControl(),
    phoneCode: new FormControl()
  });
  countryCodeList = AppSettings.COUNTRY_CODE;
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  uploadedImgId = '';

  fileUploadUrl: string = AppSettings.UPLOAD_FILE_URL;
  chooseLabel;
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private translate: TranslateService,
    private commonServices: UserCommonService,
    private uiSerice: UiService,
    private commonBindingDataService: CommonBindingDataService,
  ) { }

  ngOnInit() {
    this.chooseLabel = this.commonBindingDataService.getLabel('label_choose');
    this.editProfileForm = this.formBuilder.group({
      firstName: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern(AppSettings.NAME_PATTERN)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern(AppSettings.NAME_PATTERN)
      ]],
      email: ['', [
        Validators.pattern(AppSettings.EMAIL_PATTERN)
      ]],
      phone: ['', [
        Validators.pattern(AppSettings.NUMBER_NOT_ZERO) // PHONE_PATTERN
      ]],
      phoneCode: ['', [Validators.required]]
    });
  }

  setData() {
    this.countryCodeList = AppSettings.COUNTRY_CODE;
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.editProfileForm.controls.firstName.setValue(userObj.firstName);
      this.editProfileForm.controls.lastName.setValue(userObj.lastName);
      this.editProfileForm.controls.email.setValue(userObj.email);
      this.editProfileForm.controls.phone.setValue(userObj.phoneNum);

      if (userObj.numCountryCode.indexOf('+') === -1) {
        this.editProfileForm.controls.phoneCode.setValue('+' + userObj.numCountryCode);
      } else {
        this.editProfileForm.controls.phoneCode.setValue(userObj.numCountryCode);
      }
      if (!userObj.numCountryCode) {
        this.editProfileForm.controls.phoneCode.setValue(AppSettings.DEFAULT_COUNTRY_CODE);
      }

      if (userObj.userDetails !== undefined && userObj.userDetails !== null && userObj.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      }
    }
  }

  closeEditProfile(event: any) {
    this.showProfilePopup.emit(false);
  }

  onUploadProfileImage(response) {
    this.profileUrl = AppSettings.GET_FILE_THUMB_URL + response.fileId;
    this.uploadedImgId = response.fileId;
  }

  updateProfile(event: any) {
    const editProfileData = {
      firstName: this.editProfileForm.controls.firstName.value,
      lastName: this.editProfileForm.controls.lastName.value,
      email: this.editProfileForm.controls.email.value,
      phoneNum: this.editProfileForm.controls.phone.value,
      profileImage: this.uploadedImgId,
      numCountryCode: this.editProfileForm.controls.phone.value ?
        this.editProfileForm.controls.phoneCode.value : '',
    };

    this.commonServices.updateUserProfile(editProfileData).subscribe(results => {
      if (results.user !== null && results.user !== undefined) {
        this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
        this.showMesssage('Success', this.commonBindingDataService.getLabel('label_profile_updated_successfully'));
        setTimeout(() => {
          this.showProfilePopup.emit(false);
          this.uiSerice.profileUpdated();
        }, 1000);
      }
    }, (error) => {

      this.showMesssage('Error', error.general[0].message);
    });
  }

  showMesssage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

  checkCountryCode(e) {
    if (this.editProfileForm.controls.phone.value && this.editProfileForm.controls.phone.valid) {
      if (!this.editProfileForm.controls.phoneCode.value) {
        this.editProfileForm.controls.phoneCode.setValidators([Validators.required]);
      }
      this.editProfileForm.controls.phoneCode.updateValueAndValidity();
    }
    if (!this.editProfileForm.controls.phone.value) {
      this.editProfileForm.controls.phoneCode.setValidators(null);
      this.editProfileForm.controls.phoneCode.updateValueAndValidity();
    }
  }

}
