import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from './../components/loader/loader.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Message } from 'primeng/api';

@Injectable()
export class RestApiService {
  shoeErrorMessage = new Subject<any>();
  message: Message[] = [];

  constructor(private http: HttpClient, private zone: NgZone, private router: Router,
    private storageService: StorageService, private loaderService: LoaderService) {
  }

  private prependApiUrl(url: string): string {
    return AppSettings.BASE_URL + '/' + AppSettings.TENANT + url;
  }

  get(url: string, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.http.get(this.prependApiUrl(url), { headers: this.getHeaders() }).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  post(url: string, body: any, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.post(this.prependApiUrl(url), body, options).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  // postExcel(url: string, body: any) {
  //   this.showLoader();
  //   return this.http.post(this.prependApiUrl(url), body, { headers: this.getHeaders(), responseType: 'blob', observe: 'response' }).subscribe
  //     (data => {
  //       this.downloadFile(data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'User Details');
  //     }),
  //     error => this.handleError(error);
  // }

  postExcel(url: string, body: any, loader = 'page-center'): Observable<{}> {
    this.showLoader(loader);
    return this.http.post(this.prependApiUrl(url), body, { headers: this.getHeaders(), responseType: 'blob', observe: 'response' })
      .pipe(
        catchError((error) => {
          this.hideLoader();
          return throwError(error.error)
          // this.handleError(error)
        })
      ).pipe(
        map((r: Response) => {
          this.hideLoader();
          this.downloadFile(r.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'User Details');
          return r;
        })
      );
  }

  put(url: string, body?: any, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.put(this.prependApiUrl(url), body, options).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  delete(url: string, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.delete(this.prependApiUrl(url), options).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }


  patch(url: string, body: any, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.patch(this.prependApiUrl(url), body, options);
  }

  head(url: string, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.head(this.prependApiUrl(url), options);
  }

  options(url: string, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.options(this.prependApiUrl(url), options);
  }


  excel(url: string, fileName: string, loader?: string) {
    this.showLoader(loader);
    return this.http.get(this.prependApiUrl(url), { headers: this.getHeaders(), responseType: 'blob', observe: 'response' }).subscribe
      (data => {
        let name = data.headers.get('content-disposition');
        if (name === undefined || name === null || name === '') {
          name = fileName;
        } else {
          name = name.substring(name.indexOf('='));
          name = name.replace('=', '');
        }
        this.downloadFile(data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name);
      }),
      error => this.handleError(error);
  }

  pdf(url: string, fileName: string, loader?: string,
    options = {
      headers: this.getHeaders(),
      responseType: 'blob'
    }) {
    this.showLoader(loader);
    return this.http.get(this.prependApiUrl(url), { headers: this.getHeaders(), responseType: 'blob', observe: 'response' }).subscribe
      (data => {
        let name = data.headers.get('content-disposition');
        if (name === undefined || name === null || name === '') {
          name = fileName;
        } else {
          name = name.substring(name.indexOf('='));
          name = name.replace('=', '');
        }
        this.downloadFile(data.body, 'application/pdf', name);
      }),
      error => this.handleError(error);
  }

  //   getFileNameFromHttpResponse(httpResponse) {
  //     const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
  //     const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
  //     return result.replace(/"/g, '');
  // }

  image(url: string, fileName: string, loader?: string) {
    this.showLoader(loader);
    return this.http.get(url, { headers: this.getHeaders(), responseType: 'blob' }).subscribe(data =>
      this.downloadFile(data, this.getContentType(fileName), fileName)),
      error => this.handleError(error);
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE
    });

    // const todayDate = new Date().toString();
    // AppSettings.HEADER_TIMEZONE_VALUE = todayDate.substr(todayDate.search(AppSettings.TIME_ZONE_FIRST_STRING),
    //   todayDate.search(AppSettings.TIME_ZONE_SECOND_STRING)).replace('(', '').replace(')', '');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (AppSettings.HEADER_AUTHORIZATION_VALUE !== null) {
      headers = new HttpHeaders({
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.storageService.getItemFromSessionStorage(AppSettings.TOKEN_KEY),
        // 'Timezone': AppSettings.HEADER_TIMEZONE_VALUE
        'Timezone': timeZone,
      });
    }
    return headers;
  }

  private handleError(error: HttpErrorResponse | any) {
    this.hideLoader();
    if (error.status === 400) {
      return throwError(error.error);
    } else if (error.status === 500 || error.status === 403) {
      return throwError(error.error);
    } else if (error.status === 401) {
      this.router.navigate(['/signin']);
    } else if (error.status === 502) {
      this.shoeErrorMessage.next('Server is not available for this moment. Please try again.');
    }
    // TODO handle 401 and other errors;
  }

  downloadFile(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data], { type: contentType });
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    this.hideLoader();
    setTimeout(() => {
      link.remove()
    }, 1000);
  }

  private getContentType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    switch (extension) {
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'bmp':
        return 'image/x-ms-bmp';
      case 'pdf':
        return 'application/pdf';
      case 'xls':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return '';
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(loader?: string): void {
    if (loader !== undefined && loader !== null && 'none' !== loader.toLowerCase()) {
      this.loaderService.show(loader);
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

  // special case wit body
  deleteUser(url: string, loader?: string, body?): Observable<{}> {
    this.showLoader(loader);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      headers: new HttpHeaders({
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.storageService.getItemFromSessionStorage(AppSettings.TOKEN_KEY),
        'Timezone': timeZone,
      }),
      body: body
    }
    return this.http.delete(this.prependApiUrl(url), options).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }
}
