<app-loader></app-loader>
<div *ngIf="userCommonService?.isMetadataLoaded" class="ui-g ui-g-nopad device-height">
  <!-- <div class="ui-g ui-g-nopad device-height"> -->
  <app-header *ngIf="notificationService.canFetchNotification" class="top-nav ui-g-12 ui-g-nopad header-panel"></app-header>
  <div class="main-panel ui-g-12 ui-g-nopad">
    <app-sidebar *ngIf="notificationService.canFetchNotification" class="sidebar scroll-bar-css ui-g-12 ui-md-3 left-panel ui-g-nopad"></app-sidebar>
    <div class="right-panel scroll-bar-css" id="rightPanel">
      <!-- <app-page-header *ngIf="notificationService.canFetchNotification"></app-page-header> -->
      <!-- <router-outlet *ngIf="isMetadataLoaded"></router-outlet> -->
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>

</div>

<app-message></app-message>

<!-- <p-confirmDialog header="Confirmation" icon="fa fa-question-circle">
</p-confirmDialog> -->
