import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { Message } from 'primeng/components/common/api';

@Component({
  selector: 'app-export-user-details',
  templateUrl: './export-user-details.component.html',
  styleUrls: ['./export-user-details.component.scss']
})
export class ExportUserDetailsComponent implements OnInit {

  usernamePlaceholder;
  passwordPlaceHolder;
  signInLabel;
  passwordType = 'password';
  tokenId;
  message: Message[] = [];
  signInForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private restApiService: RestApiService,
    private commonBindingDataService: CommonBindingDataService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.tokenId = params['token'];
    });

    this.signInLabel = this.commonBindingDataService.getLabel('label_export_data');
    this.signInForm = this.formBuilder.group({
      securityCode: ['', [Validators.required]],
    });
  }

  export() {
    if (this.tokenId) {
      this.getLoginObject();
    } else {
      this.uiService.showMessage('Invalid request', AppSettings.MESSAGE_TYPE.ERROR)
    }
  }

  getLoginObject() {
    let data = {};
    data = {
      'auditFilesExportedDetailsId': this.tokenId,
      'securityCode': this.signInForm.controls.securityCode.value,
    };
    this.exportApiCall(data);
  }

  exportApiCall(signInData) {
    this.restApiService.postExcel('/gdpr/export-details/file', JSON.stringify(signInData), 'page-center').subscribe(result => {
      // console.log('got response');
      this.signInForm.reset();
    }, (err) => {
      // console.log(err);  
      this.uiService.showMessage('Invalid security code OR invalid request', AppSettings.MESSAGE_TYPE.ERROR)
      });
  }

  eventHandler(event) {
    if (event.keyCode === 13) {
      if (this.signInForm.controls.username.errors) {
        setTimeout(() => {
          const element = this.renderer.selectRootElement('#username');
          element.focus();
        }, 500);
      } else if (this.signInForm.controls.password.errors) {
        setTimeout(() => {
          const element = this.renderer.selectRootElement('#password');
          element.focus();
        }, 500);
      } else {
        // this.onSignInEmitter.emit(this.getLoginObject());
      }
    }
  }

  tooglePasswordType() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

}
