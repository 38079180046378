import { Component, OnInit } from '@angular/core';
import { AppSettings } from './modules/shared/app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { UserCommonService } from './modules/shared/services/user-common.service';
import { NotificationsService } from './modules/shared/services/notifications.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // TODO Take this from localization
  showHeaderAndSidebar = false;
  isMetadataLoaded: any = false;
  constructor(
    private router: Router,
    public notificationService: NotificationsService,
    public userCommonService: UserCommonService,
  ) {
    this.router.events.subscribe((url: any) => {
      if (url.url !== undefined && url.url !== null) {
        const browserUrl = url.url.split('?');
        if (AppSettings.HEADER_NOT_REQUIRED.includes(browserUrl[0])) {
          this.showHeaderAndSidebar = false;
        } else {
          this.showHeaderAndSidebar = true;
        }
      }
    });
  }

  ngOnInit() {
    this.userCommonService.scrollSectionToTop.subscribe(result => {
      if (result) {
        this.onActivate();
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  onActivate(event?: any) {
    const contentSection = document.getElementById('rightPanel');
    contentSection.scroll(0, 0);
  }
}
