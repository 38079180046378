<div class="track-my-location-container ui-g">
  <div class="track-my-location">

    <div class="header ui-g-12 ui-md-12 ui-lg-12 ui-sm-12">
      <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12">
        <a href="" target="_blank">
          <img width="200" src="/assets/images/temp/fifa_header.png">
        </a>
      </div>
    </div>
    <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
      <div class="seperator"></div>
    </div>
    <div *ngIf = "supportData" class="ui-g-12" style="
    margin-right: 4em;
    padding: 23px;
    font-size: 10px;">
    <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
      <h2>Want to get in touch? We would love to hear from you.
        You can reach out to us on :</h2>
    </div>
      <div *ngIf="supportData && supportData.organisationName" class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
        <h2>Organization Name : {{supportData.organisationName}}</h2>
      </div>
      <div *ngIf="supportData && supportData.website" class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
        <h2>Website : {{supportData.website}}</h2>
      </div>
      <div *ngIf="supportData['email']" class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
        <h3>Email : {{supportData['email']}}</h3>
      </div>
      <div *ngIf = "supportData.contacts.length > 0">
        <div *ngFor = "let eachnumber of supportData.contacts ; index as i" class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
          <h3>Support Number {{(i+1)}} : <a [href]="'tel:'+eachnumber">{{eachnumber}}</a></h3>
        </div>
      </div>
      <div *ngIf=" supportData && supportData.addressLine1" class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
        <h2>Address : {{supportData.addressLine1}} ,{{supportData.addressLine2}}</h2>
      </div>
     
    </div>
  </div>
</div>
