import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() {
    this.sessionTransfer();
  }

  getItem<T>(key: string): T {
    let result = null;
    result = localStorage.getItem(key);
    if (result != null) {
      result = JSON.parse(result);
    }
    return result;
  }

  setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem<T>(key: string) {
    localStorage.removeItem(key);
  }

  removeAll<T>() {
    localStorage.clear();
  }

  setSessionStorage<T>(key: string, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeSessionStorage<T>(key: string, value?: T) {
    sessionStorage.removeItem(key);
  }

  clearSessionStorage<T>() {
    sessionStorage.clear();
  }
  getItemFromSessionStorage<T>(key: string): T {
    let result = null;
    result = sessionStorage.getItem(key);
    if (result != null) {
      result = JSON.parse(result);
    }
    return result;
  }

  sessionTransfer() {
    // transfers sessionStorage from one tab to another
    const sessionStorage_transfer = function(event) {
      if (!event) { event = window.event; } // ie suq
      if (!event.newValue) { return };          // do nothing if no value to work with
      if (event.key === 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        // another tab sent data <- get it
        const data = JSON.parse(event.newValue);
        for (const key in data) {
          if (data[key]) {
            sessionStorage.setItem(key, data[key]);
          }
        }
      }
    };


    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener('storage', sessionStorage_transfer, false);
    } else {
      window.addEventListener('onstorage', sessionStorage_transfer);
    };


    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage');
    };
  }
}
