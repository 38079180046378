<p-panelMenu class="sidebar-panel-menu" [multiple]="isMultipleTabOpen" [model]="items"></p-panelMenu>
<div class="version-section-in-sidebar" [title]="buildVersion">
  {{buildVersion}}
</div>
<div class="powered-by-section">
  <div class="ui-g-12 wrapper" >
      <!-- <div class="ui-g-4 text">Powered by </div> -->
      <div>
        <a [href]="poweredByRedirectUrl" target="_blank">  
          <!-- <img src="/assets/images/temp/powered-by-logo.png"> -->
          <img src="{{poweredLogoUrl}}">
        </a>
      </div>
  </div>
</div>
