<div class="track-my-location-container ui-g">
  <div class="track-my-location">

    <div class="header ui-g-12 ui-md-12 ui-lg-12 ui-sm-12">
      <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12">
        <a href="" target="_blank">
          <img width="200" src="/assets/images/temp/fifa_header.png">
        </a>
      </div>
    </div>
    <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
      <div class="seperator"></div>
    </div>

    <div class=" ui-g-12 ui-md-12 ui-lg-12" style="
    margin-right: 4em;
    padding: 23px;
    font-size: 7px;
    overflow-y: scroll;
    max-height: 81vh;">
      <h1 [innerHtml]="privacyPolicyData"></h1>
    </div>

  </div>
</div>

<!--
  PPLorem Ipsum has been the industry's standard  dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
-->