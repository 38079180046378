<div class="ui-g form-container">
  <form [formGroup]='otpForm'>

    <div class="ui-g-12">
      <div class="form-field ui-g-12">
        <div class="form-label">
          <label for="username" translate>label_otp_msg</label>
        </div>
        <div class="form-control">
          <input formControlName="otpInput" type="text" pInputText />
        </div>
        <div class="form-errors" *ngIf="otpForm.controls.otpInput.errors  && (otpForm.controls.otpInput.dirty || otpForm.controls.otpInput.touched)">
          <div [hidden]="!otpForm.controls.otpInput.errors.required" translate>
             invalid_otp
          </div>
        </div>
      </div>
    </div>
    <div class="form-buttons ui-g-12 ui-g-nopad">
      <button class="negative-button" pButton appRipple type="button" icon="fa fa-close" label="Cancel" (click)="closeEditForgot($event)"></button>
      <button pButton appRipple type="button" label="Send" icon="fa fa-check" [disabled]="!otpForm.valid" (click)="changePassword($event)"></button>
    </div>
  </form>
</div>

<p-growl [value]="msg"></p-growl>