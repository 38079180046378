import { Injectable } from '@angular/core';

import { RestApiService } from '../services/rest-api.service';
import { Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { AppSettings } from '../app.settings';
import { CommonBindingDataService } from './common-binding-data.service';
declare var Stripe: any;
@Injectable()
export class UserCommonService {
  isMetadataLoaded = false;
  isCompanyRole = false;
  stripElements: any;
  stripe: any;
  userMetaData: any;
  scrollSectionToTop: Subject<any> = new Subject<any>();
  poweredByRedirectBeforeLoginUrl = AppSettings.DEFAULT_POWERED_BY_REDIRECT_URL;
  poweredByBeforeLogoUrl = AppSettings.DEFAULT_POWERED_BY_LOGO_URL;
  constructor(private restApiService: RestApiService,
    private commonService: CommonBindingDataService,
    private storageService: StorageService) {
    this.getAdminUserMetaData();
    if (this.storageService.getItemFromSessionStorage(AppSettings.TOKEN_KEY)) {
      this.getStripeKeyData();
    }
  }
  signOut(): Observable<any> {
    return this.restApiService.delete('/secure/signout', 'page-center');
  }

  signIn(data): Observable<any> {
    return this.restApiService.post('/user-signin', data, 'page-center');
  }

  ssoSignIn(data): Observable<any> {
    return this.restApiService.post('/sso/user-signin', data, 'page-center');
  }

  publicUserExportData(data): Observable<any> {
    return this.restApiService.post('/gdpr/export-details/file', data, 'page-center');
  }

  passwordChange(data): Observable<any> {
    return this.restApiService.put('/secure/user-profile/users/update-password', data, 'page-center');
  }

  updatePassword(data, userId: any): Observable<any> {
    return this.restApiService.put(`/secure/manage-users/update-password/${userId}`, data, 'page-center');
  }

  resetPassword(data): Observable<any> {
    return this.restApiService.post(`/users/reset-password/verify-token`, data, 'page-center');
  }

  setPassword(data): Observable<any> {
    return this.restApiService.post('/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('/secure/user-profile/users', 'page-center');
  }

  updateUserProfile(data): Observable<any> {
    return this.restApiService.put('/secure/user-profile/users', data, 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('/users/forgot-password', data, 'page-center');
  }

  validateOtp(data): Observable<any> {
    return this.restApiService.post('/users/verify-otp', data, 'page-center');
  }

  getServiceAreaId(): Observable<any> {
    return this.restApiService.get('/secure/tenants/servicearea', 'page-center');
  }

  getSSOLogin(): Observable<any> {
    return this.restApiService.get('/saml/ssologin', 'page-center');
  }

  getUserMetaData(): Observable<any> {
    return this.restApiService.get('/secure/user-metadata', 'page-center');
  }

  getPessangerDetails(userId, eventId?): Observable<any> {
    return this.restApiService.get(`/secure/admin-booking/passenger-details/${userId}?eventId=${eventId}`, 'page-center');
  }

  getUserMetaDataInsecure(): Observable<any> {
    return this.restApiService.get('/admin/metadata', 'page-center');
  }

  getTrackMyLocationDetails(bookingId): Observable<any> {
    return this.restApiService.get('/track-my-location/' + bookingId, 'page-center');
  }

  getTrackMyLiveLocation(bookingId): Observable<any> {
    return this.restApiService.get('/track-my-location/live/' + bookingId);
  }

  updateSavedFilter(data): Observable<any> {
    return this.restApiService.post('/secure/save-filter', data);
  }

  updateFilterOpenClose(data, key?): Observable<any> {
    return this.restApiService.post(`/secure/save-filter?filterKey=${key}`, data);
  }

  // for driver checkin grid filter
  getPassengerAllocatedLocationData(): Observable<any> {
    return this.restApiService.get(`/secure/locations?projection=grid&isLimit=false`, 'page-center');
  }

  addUserManualDocument(data): Observable<any> {
    return this.restApiService.post(`/secure/user-manuals`, data);
  }

  activeDeActiveManual(data): Observable<any> {
    if (data.id !== null && data.id !== '' && data.id !== undefined) {
      return this.restApiService.put(`/secure/user-manuals/activate-deactivate/${data.id}`, data, 'page-center');
    }
  }

  deleteManual(userManualId): Observable<any> {
    return this.restApiService.delete(`/secure/user-manuals/delete/${userManualId}`, 'page-center');
  }

  /**
   *
   * @param screenCode : Screencode to get filter data
   */
  getSavedFilters(screenCode: String) {
    let filters: any[] = this.storageService.getItem(AppSettings.SAVE_FILTERS);
    filters = filters ? filters : [];
    const objects = filters.filter(ele => {
      return ele.screenCode === screenCode;
    });
    if (objects.length > 0) {
      return objects[0];
    } else {
      return null;
    }
  }

  updateFilterDataToLocal(filterData: any, key?, val?) {
    let filters: any[] = this.storageService.getItem(AppSettings.SAVE_FILTERS);
    filters = filters ? filters : [];
    const objects = filters.filter(ele => {
      return ele.screenCode === filterData.screenCode;
    });

    if (objects.length === 0) {
      filters.push(filterData);
    } else {
      const index = filters.findIndex(ele => ele.screenCode === filterData.screenCode);
      if (index >= 0) {
        if (key && val) {
          filters[index]['filterData'][key] = val;
        } else {
          filters[index] = filterData;
        }
      }
    }
    this.storageService.setItem(AppSettings.SAVE_FILTERS, filters);
  }


  getAdminUserMetaData() {
    this.getUserMetaDataInsecure().subscribe(result => {
      let decryptedGoogleBrowserKey = this.commonService.decrypt(result.googleBrowserAPIKey);
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${decryptedGoogleBrowserKey}&libraries=visualization,places`;
      document.getElementsByTagName('head')[0].appendChild(script);
      if (result.tenantInfo.poweredByName) {
        this.poweredByRedirectBeforeLoginUrl = result.tenantInfo.poweredByName;
        this.poweredByBeforeLogoUrl = AppSettings.GET_FILE_URL + '/' + result.tenantInfo.poweredByImageId;
      }
      setTimeout(() => {
        this.isMetadataLoaded = true;
      }, 2000);
    }, (error) => {
    });
  }

  setDefaultConstants(result: any) {
    const countryCodeObj = result.tenantConfigKeys.find(ele => {
      return ele.configKey === 'num_country_code';
    });

    if (countryCodeObj) {
      AppSettings.DEFAULT_COUNTRY_CODE = countryCodeObj.configValue;
    }
  }

  getStripeKeyData() {
    this.getUserMetaData().subscribe(result => {
      this.userMetaData = result;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://js.stripe.com/v3/`;
      // this.stripe = Stripe(`${result.pkKey}`); // use your test publishable key
      // this.stripElements = this.stripe.elements();
      this.setDefaultConstants(result);
      // document.getElementsByTagName('head')[0].appendChild(script);

    }, (error) => {
    });
  }

  // exportPassengerDetails(data): Observable<any> {
  //   return this.restApiService.postExcel('/gdpr/export-details/file',JSON.stringify(data));
  //   // return this.restApiService.get('/secure/tenants/servicearea', 'page-center');
  // }

  // updateOpenCloseFilterInStorage(openCloseFilterName,filterStatus) {
  //   if (openCloseFilterName) {
  //     this.storageService.setItem(openCloseFilterName, filterStatus);
  //   }
  // }

  // getOpenCloseFilterInStorage(openCloseFilterName?) {
  //   let status = false;
  //   if (openCloseFilterName) {
  //     // return 
  //     status = this.storageService.getItem(openCloseFilterName);
  //     return status ? status : false;
  //   }
  // }

}

