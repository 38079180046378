import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from 'primeng/components/common/api';
import { CommonBindingDataService } from './../../services/common-binding-data.service';

@Component({
  selector: 'app-notification-mg1',
  templateUrl: './notification-mg1.component.html'
})
export class NotificationMG1Component implements OnInit {
  @Input() notifications;
  @Output() loadMoreNotification: EventEmitter<any> = new EventEmitter();
  message: Message[] = [];
  labelLoadMore = '';
  constructor(private commonBindingDataService: CommonBindingDataService) { }

  ngOnInit() {
    this.labelLoadMore = this.commonBindingDataService.getLabel('label_load_more');

  }

  onLoadMore() {
    this.loadMoreNotification.emit();
  }

}
