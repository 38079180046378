import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  supportData: any;
  bookingId;
  locationDetails: any = null;

  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private userCommonService: UserCommonService,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.bookingId = this.route.snapshot.queryParams['id'];
    // this.supportData = {
    //   // organisationName: 'abc',
    //   // website: 'abc',
    //   numCountryCode: '+91',
    //   phoneNumber: '7798787118',
    //   numCountryCode2: '+91',
    //   phoneNumbe2: '9405990176',
    //   numCountryCode3: '+91',
    //   phoneNumber3: '9975559536',
    //   numCountryCode4: '+91',
    //   phoneNumber4: '9028844624',
    //   // addressLine1: 'abc',
    //   // addressLine2: 'abc',
    //   // email: 'abc',
    // }
    this.commonBindingDataService.getSupportData().subscribe((result) => {
      this.supportData = result;
      this.supportData['contacts'] = [];
      if(result.supportContacts.length >0){
        result.supportContacts.forEach(number => {
          let contact = '';
          contact = number.numCountryCode+number.phoneNumber
          this.supportData['contacts'].push(contact);
        }); 
      }
      this.supportData['email'] = result.supportEmailAddress;
    }, (reason) => {
      // this.errorMessage = 'The page you are looking is not exist. Please contact to your Admin.';
    });
  }


}