import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSettings } from './../../app.settings';
import { UiService } from '../../services/ui.service';
import { ConfirmationService, MenuItem, Message, SelectItem } from 'primeng/components/common/api';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { NotificationsService } from './../../services/notifications.service';
import { EditProfileComponent } from './../edit-profile/edit-profile.component';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'

})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(EditProfileComponent) editPro: EditProfileComponent;
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  message: Message[] = [];
  eventListOptions: any = [];
  eventListOptions1: SelectItem[];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [];
  unReadNotificatonsCount = 0;
  params: any = '';
  limit = 5;
  offset = 0;
  selectedEvent;
  showMoreOff = true;
  hasShowNotificationBar = false;
  notificationLabel;
  noNotificationFoundLabel;
  uploadedImagePath = AppSettings.GET_FILE_THUMB_URL;


  constructor(
    private uiService: UiService,
    private userCommandService: UserCommonService,
    private router: Router,
    private commonBindingDataService: CommonBindingDataService,
    private storageService: StorageService,
    private confirmationService: ConfirmationService,
    private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    this.notificationLabel = this.commonBindingDataService.getLabel('label_notifications');
    this.noNotificationFoundLabel = this.commonBindingDataService.getLabel('label_no_notification_found');
    this.commonBindingDataService.changedEventId = this.selectedEvent;

    this.items = [
      {
        label: 'Edit Profile', icon: 'fa fa-user-circle-o', command: (event) => {
          this.editProfile();
        }
      },
      {
        label: 'Change password', icon: 'fa  fa-key', command: (event) => {
          this.changePassword();
        }
      },
      {
        label: 'Logout', icon: 'fa fa-sign-out', command: (event) => {
          this.doSignOut();
        }
      }
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (userObj.userDetails !== undefined && userObj.userDetails !== null
        && userObj.userDetails.profileImage !== null && userObj.userDetails.profileImage !== '') {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      } else {
        this.profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      }
    }

    this.subscription = this.uiService.profileUpdatedEvent
      .subscribe((state: string) => {
        this.updateProfileContent();
      });

    this.notificationProcessInitialization();
    this.getEventList();


  }

  notificationProcessInitialization() {
    this.notificationsService.startFetchingNotifications(() => {
      this.getNotificationUnReadcountCount();
    });
  }

  getEventList() {

    this.commonBindingDataService.getEventList().subscribe((results) => {
      for (let event = 0; event < results.eventList.length; event++) {
        this.eventListOptions.push({ label: results.eventList[event].eventName, imageId: results.eventList[event].eventImageId, value: results.eventList[event].id });
      }

      const storedEventId = this.storageService.getItemFromSessionStorage('storedEventId');
      if (storedEventId) {
        this.selectedEvent = storedEventId;

      } else {
        if (this.eventListOptions.length > 0) {
          this.selectedEvent = this.eventListOptions[0].value;
        }
      }
      //Above code for session storage
      //this.selectedEvent = this.eventListOptions[0].value;
      this.commonBindingDataService.changedEventId = this.selectedEvent;
      this.commonBindingDataService.eventId.next(this.selectedEvent);

    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
    });
  }

  getNotificationUnReadcountCount() {
    this.notificationsService.getCount().subscribe(data => {
      this.unReadNotificatonsCount = data.unReadcount;
    });
  }

  changeFilter(event: any) {
    this.params = '?';
    this.confirmationService.confirm({
      message: `${this.commonBindingDataService.getLabel('event_change_confirmation')}`,
      accept: () => {
        this.commonBindingDataService.changedEventId = event.value;

        this.commonBindingDataService.eventId.next(event.value);
        this.storageService.setSessionStorage('storedEventId', event.value);

        this.router.navigate(['/admin-dashboard/home/inbound-journey']);

        setTimeout(() => {
          window.location.reload();

        }, 1000);

      },
      reject: () => {
        const storedEventId = this.storageService.getItemFromSessionStorage('storedEventId');
        if (storedEventId) {
          this.selectedEvent = storedEventId;

        } else {
          if (this.eventListOptions.length > 0) {
            this.selectedEvent = this.eventListOptions[0].value;
          }
        }
        // if (storedEventId) {
        //   this.selectedEvent = storedEventId;

        // }
        // this.getEventList();


      }

    });
  }


  getNotificationListAndToggle(event) {
    this.unReadNotificatonsCount = 0;
    this.limit = 10;
    this.offset = 0;
    this.notifications = [];
    this.getNotificationList();
    this.hasShowNotificationBar = true;
  }

  getNotificationList() {
    this.notificationsService.getDetails(this.limit, this.offset).subscribe(notification => {
      if (notification.data.length > 0) {
        this.offset = this.offset + notification.limit;
        this.notifications = [...this.notifications, ...notification.data];
      } else {
        this.showMessage('Success', this.noNotificationFoundLabel);
      }
    });
  }

  loadMoreNotification() {
    this.getNotificationList();
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.userCommandService.signOut().subscribe(results => {
      this.storageService.removeAll();
      const userType = this.storageService.getItemFromSessionStorage('loggedInUserType');
      this.storageService.clearSessionStorage();
      this.notificationsService.stopFetchingNotifications();
      // this.router.navigate(['/signin']);
      this.notificationsService.canFetchNotification = false;
      if (userType == 'adminUser') {
        window.location.href = 'https://eventtransport.mi2.in/#/signin'
      }
       
      if (userType == 'ssoUser') {
        setTimeout(() => {
          window.location.href = `${AppSettings.BASE_URL}/${AppSettings.TENANT}/saml/ssologout`
        }, 5000);
      }

    }, (error) => {
      this.showMessage('Error', error.general[0].message);
    });
  }

  updateProfileContent() {
    this.userCommandService.getUserProfile().subscribe(results => {
      this.storageService.setItem(AppSettings.USER_DETAILS, results);
      AppSettings.USER = results;
      this.userFullName = results.firstName + ' ' + results.lastName;
      if (results.userDetails !== undefined && results.userDetails !== null && results.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + results.userDetails.profileImage;
      }
    }, (error) => {

      this.showMessage('Error', error.general[0].message);
    });
  }

  changePassword() {
    this.changePasswordPopup = true;
  }

  editProfile() {
    this.editProfilePopup = true;
    this.editPro.setData();
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  ngOnDestroy() {
    this.notificationsService.stopFetchingNotifications();
    this.subscription.unsubscribe();
  }

  showMessage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }


}
