<form [formGroup]='signInForm'>
  <div class="form-field  ui-g-12 ui-md-12 ui-lg-12">
    <div class="form-label">
      <label for="email-label" translate>label_username</label>
    </div>
    <div class="form-control">
      <div class="form-control">
        <input formControlName="username" id="username" class="full-width-transperent" type="text" pInputText
          (keypress)="eventHandler($event)" />
      </div>
      <div class="form-errors"
        *ngIf="signInForm.controls.username.errors  && (signInForm.controls.username.dirty || signInForm.controls.username.touched)">
        <div [hidden]="!signInForm.controls.username.errors.required" translate>
          err_username_required
        </div>
        <div [hidden]="!signInForm.controls.username.errors.maxlength" translate>
          exceed_max_length
        </div>
      </div>
    </div>
  </div>

  <div class="form-field  ui-g-12 ui-md-12 ui-lg-12">
    <div class="form-label">
      <label for="email-label" translate>label_password</label>
    </div>
    <div class="form-control">
      <div class="form-control password-show">
        <input pInputText [type]="passwordType" id="password" formControlName="password"
          class="full-width-transperent password-field" (keypress)="eventHandler($event)" />
        <span class="eye-icon sign-in-eye" (click)="tooglePasswordType()">
          <i *ngIf="passwordType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
          <i *ngIf="passwordType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
        </span>
      </div>
      <div class="form-errors"
        *ngIf="signInForm.controls.password.errors  && (signInForm.controls.password.dirty || signInForm.controls.password.touched)">
        <div [hidden]="!signInForm.controls.password.errors.required" translate>
          error_password_required
        </div>
        <div [hidden]="!signInForm.controls.password.errors.minlength" translate>
          password_too_short
        </div>
        <div [hidden]="!signInForm.controls.password.errors.maxlength" translate>
          exceed_max_length
        </div>
        <div [hidden]="!signInForm.controls.password.errors.pattern" translate>
          error_password_validation
        </div>
      </div>
    </div>
  </div>

  <div class="form-field ui-g-12">
    <div class="form-field ui-g-12 ui-g-nopad">
      <img src=''>
    </div>
    <div class="form-field ui-g-12 ui-g-nopad">
      <button pButton appRipple label="{{signInLabel}}" class='sign-in-buttons' type="button"
        [disabled]="!signInForm.valid" (click)="doSignIn()"></button>
    </div>
    <div style="text-align: center;font-weight: bold;padding: 6px 0 6px 0;" class="form-field ui-g-12 ui-g-nopad">
      <span>OR</span>
    </div>
    <div class="form-field ui-g-12 ui-g-nopad">
      <button pButton appRipple label="{{signInLabelWithSSO}}" class='sign-in-buttons' type="button"
        (click)="doSignInWithSSO()"></button>
    </div>

  </div>
</form>