import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UiService } from 'app/modules/shared/services/ui.service';
import { ErrorBindingService } from 'app/modules/shared/services/error-binding.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  supportData: any;
  tokenId;
  locationDetails: any = null;
  setPasswordForm: FormGroup;
  inputType = 'password';
  cnfInputType = 'password';
  signInDescription = this.commonBindingDataService.getLabel('label_sign_in_description');
  errorMessage: Message[] = [];
  otpId;
  passwordError = [{
    message: false
  }];
  changePasswordErrors = [
    {
      newPassword: false,
      newConfirmPassword: false,
    }
  ];
  passwordType = 'password';
  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userCommonService: UserCommonService,
    private uiService: UiService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,

  ) { }

  ngOnInit() {
    this.tokenId = this.route.snapshot.params.token;
    this.setPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(AppSettings.PASSWORD_VALIDATIONS)]],
      newConfirmPassword: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(AppSettings.PASSWORD_VALIDATIONS)]]
    });
  }

  tooglePasswordType() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }

  toogleCnfPasswordType() {
    if (this.cnfInputType === 'password') {
      this.cnfInputType = 'text';
    } else {
      this.cnfInputType = 'password';
    }
  }

  passwordNotMatched() {
    if (this.setPasswordForm.controls.newPassword.value !== this.setPasswordForm.controls.newConfirmPassword.value) {
      const error = {
        general: [
          {
            message: this.commonService.getLabel('error_new_confirm_password_not_match')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
    } else {
      return true;
    }
  }

  requestData() {
    return {
      tokenType: 'RESET_PASSWORD',
      token: this.tokenId,
      password: this.setPasswordForm.controls.newPassword.value,
    };
  }

  doChangePassword() {
    const passwordVerified = this.passwordNotMatched();
    if (passwordVerified) {
      const data = this.requestData();
      // const c = this;
      this.userCommonService.resetPassword(data).subscribe(results => {
        if (results.general !== null && results.general !== undefined) {
          results.general.forEach(element => {
            this.uiService.showMessage(element.message, AppSettings.MESSAGE_TYPE.SUCCESS);
          });
          this.setPasswordForm.reset();
          setTimeout(() => {
            this.onCancel();
          }, 500);
        }
      }, (error) => {
        this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
      });
    }
  }

  onCancel() {
    this.setPasswordForm.reset();
    // this.onSetPasswordPopClose.emit(false);
  }

}
