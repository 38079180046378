<div class="ui-g form-container">
    <form [formGroup]='chagePasswordForm' autocomplete="off">
        <div>
            <div class="ui-g">
                <div class="form-field ui-g-12 UI-MD-12 ui-lg-12">
                    <div class="form-errors alert alert-danger" [hidden]='!changePasswordErrors[0]["general"]'>
                        <div *ngFor="let ErrorMessage of changePasswordErrors['general']">
                            {{ErrorMessage.message}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui-g">
                <div class="form-field ui-g-12">
                    <div class="form-label">
                        <label for="oldPassword" translate>label_old_password</label>
                        <label class="asterisk">*</label>
                    </div>
                    <div class="form-control password-field">
                        <input pPassword [type]="oldInputType" autocomplete="off" class="password-field"
                            formControlName="oldPassword" [feedback]="false" />
                        <span class="eye-icon sign-in-eye" (click)="toogleOldPasswordType()">
                            <i *ngIf="oldInputType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                            <i *ngIf="oldInputType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="form-errors"
                        *ngIf="chagePasswordForm.controls.oldPassword.errors  && (chagePasswordForm.controls.oldPassword.dirty || chagePasswordForm.controls.oldPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.required" translate>
                            error_old_password_required
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.minlength" translate>
                            password_too_short
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.pattern" translate>
                            invalid_old_password
                        </div>
                    </div>
                </div>

                <div class="form-field ui-g-12">
                    <div class="form-label">
                        <label for="newPassword" translate>label_new_password</label>
                        <label class="asterisk">*</label>
                    </div>
                    <div class="form-control password-field">
                        <input pPassword [type]="inputType" autocomplete="off" class="password-field"
                            formControlName="newPassword" [feedback]="false" />
                        <span class="eye-icinputTypeon sign-in-eye" (click)="tooglePasswordType()">
                            <i *ngIf="inputType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                            <i *ngIf="inputType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="form-errors"
                        *ngIf="chagePasswordForm.controls.newPassword.errors  && (chagePasswordForm.controls.newPassword.dirty || chagePasswordForm.controls.newPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.required" translate>
                            error_new_password_required
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.pattern && !chagePasswordForm.controls.newPassword.errors.minlength"
                            translate>
                            error_password_validation
                        </div>
                    </div>
                </div>

                <div class="form-field ui-g-12">
                    <div class="form-label">
                        <label for="newConfirmPassword" translate>label_new_confirm_password</label>
                        <label class="asterisk">*</label>
                    </div>
                    <div class="form-control password-field">
                        <input [type]="cnfinputType" class="password-field" autocomplete="off" pPassword
                            formControlName="newConfirmPassword" [feedback]="false" />
                        <span class="eye-icon sign-in-eye" (click)="toogleCnfPasswordType()">
                            <i *ngIf="cnfinputType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                            <i *ngIf="cnfinputType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="form-errors"
                        *ngIf="chagePasswordForm.controls.newConfirmPassword.errors  && (chagePasswordForm.controls.newConfirmPassword.dirty || chagePasswordForm.controls.newConfirmPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.required" translate>
                            error_new_confirm_password_required
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.pattern && !chagePasswordForm.controls.newConfirmPassword.errors.minlength"
                            translate>
                            error_password_validation
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui-g-12">
                <div class="form-buttons">
                    <button pButton appRipple type="button" label="Save" [disabled]="!chagePasswordForm.valid"
                        (click)="doChangePassword()"></button>
                    <button class="negative-button" pButton appRipple type="button" label="Cancel"
                        (click)="onCancel()"></button>
                </div>
            </div>
        </div>
    </form>
</div>
<p-growl [value]="errorMessage"></p-growl>