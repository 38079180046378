
import { NavtabComponent } from './components/navtab/navtab.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  TranslateModule, TranslateLoader, TranslateService,
  TranslatePipe, TranslateDirective, MissingTranslationHandler
} from '@ngx-translate/core';
import { TripMapComponent } from './components/trip-map/trip-map.component';
import { UploadQuoteComponent } from './components/upload-quote/upload-quote.component';
import { TripDetailsComponent } from './components/trip-details/trip-details.component';
import { BookingHistoryTripDetailsComponent } from './components/booking-history-trip-details/booking-history-trip-details.component';
import { BookingUserDetailsComponent } from './components/booking-user-details/booking-user-details.component';
import { BookingDriverDetailsComponent } from './components/driver-details/booking-driver-details.component';
import { EmployeeUserDetailsComponent } from './components/employee-user-details/employee-user-details.component';
import { EditableEmployeeComponent } from './components/editable-employee/editable-employee.component';
import { EmployeeTripDetailsComponent } from './components/employee-trip-details/employee-trip-details.component';
import { CancelSaleQuotationComponent } from './components/cancel-sale-quotation/cancel-sale-quotation.component';
import { BookingCancelationComponent } from './components/booking-cancelation/booking-cancelation.component';

import { MenuModule } from 'primeng/components/menu/menu';
import { ChartModule } from 'primeng/components/chart/chart';
import { GrowlModule } from 'primeng/components/growl/growl';
import { ButtonModule } from 'primeng/components/button/button';
import { DialogModule } from 'primeng/components/dialog/dialog';
import { EditorModule } from 'primeng/components/editor/editor';
import { RatingModule } from 'primeng/components/rating/rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/components/blockui/blockui';
import { TabMenuModule } from 'primeng/components/tabmenu/tabmenu';
import { TabViewModule } from 'primeng/components/tabview/tabview';
import { ConfirmationService } from 'primeng/components/common/api';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { CheckboxModule } from 'primeng/components/checkbox/checkbox';
import { DropdownModule } from 'primeng/components/dropdown/dropdown';
import { PanelMenuModule } from 'primeng/components/panelmenu/panelmenu';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
// import { MultiSelectModule } from 'primeng/components/multiselect/multiselect';
import { RadioButtonModule } from 'primeng/components/radiobutton/radiobutton';
import { AutoCompleteModule } from 'primeng/components/autocomplete/autocomplete';
import { OverlayPanelModule } from 'primeng/components/overlaypanel/overlaypanel';
import { InputSwitchModule } from 'primeng/components/inputswitch/inputswitch';
import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
import { SidebarModule } from 'primeng/components/sidebar/sidebar';
import { ToastModule } from 'primeng/components/toast/toast';
import { AccordionModule } from 'primeng/accordion';

import { UiService } from './services/ui.service';
import { RestApiService } from './services/rest-api.service';
import { UserCommonService } from './services/user-common.service';
import { StorageService } from './services/storage.service';
import { ErrorBindingService } from './services/error-binding.service';
import { CommonBindingDataService } from './services/common-binding-data.service';
import { SidebarService } from './services/sidebar.service';
import { AuthenticationValidationService } from './services/authentication-validation.service';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MiTranslateLoaderService } from './services/mi-translate-loader.service';
import { MiMissingTranslationHandlerService } from './services/mi-missing-translation-handler.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { AssignSearchComponent } from './components/assign-search/assign-search.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { DashboardStatsCountsComponent } from './components/dashboard-stats-counts/dashboard-stats-counts.component';
import { DashboardActivityComponent } from './components/dashboard-activity/dashboard-activity.component';
import { LoaderService } from './components/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { FileUploaderComponent } from './components/file-uploader/file.uploader.component';
import { AgoPipe } from './pipes/ago.pipe';
import { DateFormatPipe } from './pipes/dateformat.pipe';
import { DateTimeFormatPipe } from './pipes/datetimeformat.pipe';
import { MinutsFormatPipe } from './pipes/minutesformat.pipe';
import { TotalAndActiveCountsComponent } from './components/total-and-active-counts/total-and-active-counts.component';
import { NotificationMG1Component } from './components/notification-mg1/notification-mg1.component';
import { CommentInfoComponent } from './components/comment-info/comment-info.component';
import { CommentReplyComponent } from './components/comment-reply/comment-reply.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { BookingHistoryDetailsComponent } from './components/booking-history-details/booking-history-details.component';
import { SearchLocationDirective } from './directives/search-location.directive';
import { DisableControlDirective } from './directives/input-disabled.directive';
import {
  EditablePricingChauffeurComponent
} from 'app/modules/shared/components/editable-pricing-chauffeur/editable-pricing-chauffeur.component';
import { OtpVerifiyComponent } from './components/otp-verifiy/otp-verifiy.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { EditTaxiPartnerPricingComponent } from './components/edit-taxi-partner-pricing/edit-taxi-partner-pricing.component';
import { EditRentalPartnerPricingComponent } from './components/edit-rental-partner-pricing/edit-rental-partner-pricing.component';
import { EditPartnerRoutesComponent } from './components/edit-partner-routes/edit-partner-routes.component';
import { RentalDocsComponent } from './components/rental-docs/rental-docs.component';
import { BookTaxiComponent } from './components/book-taxi/book-taxi.component';
import { BookRentalComponent } from './components/book-rental/book-rental.component';
import { BookChauffeurComponent } from './components/book-chauffeur/book-chauffeur.component';
import { RecentEmergencyRequestComponent } from './components/recent-emergency-request/recent-emergency-request.component';
import { OtpComponent } from './components/otp/otp.component';
import { TrackLocationComponent } from './components/track-location/track-location.component';
import { AccessProviderDirective } from './directives/access-provider.directive';
import { PasswordModule } from 'primeng/components/password/password';
import { MiGrid2Component } from './components/mi-grid-2/mi-grid-2.component';
import { TableModule } from 'primeng/table';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { AssignDriverComponent } from './components/assign-driver/assign-driver.component';
import { LogDeailsComponent } from './components/log-deails/log-deails.component';
import { SharedBookingsListComponent } from './components/shared-bookings-list/shared-bookings-list.component';
import { SharedCriticalJourneyComponent } from './components/shared-critical-journey/shared-critical-journey.component';
import { SharedOutboundJourneyComponent } from './components/shared-outbound-journey/shared-outbound-journey.component';
import { SharedVehicleTransferComponent } from './components/shared-vehicle-transfer/shared-vehicle-transfer.component';
import { MessageComponent } from './components/message/message.component';
import { SidebarAssignDriverComponent } from './components/sidebar-assign-driver/sidebar-assign-driver.component';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { SharedThirdPartyTransferComponent } from './components/shared-third-party-transfer/shared-third-party-transfer.component';
import { DevelopementInProgressComponent } from './components/developement-in-progress/developement-in-progress.component';
import { RippleDirective } from './directives/ripple.directive';
import { KeyFilterModule, MultiSelectModule, SplitButtonModule } from 'primeng/primeng';
import { FieldsetModule } from 'primeng/components/fieldset/fieldset';
import { SidebarBookingDetailsComponent } from './components/sidebar-booking-details/sidebar-booking-details.component';
import { PassengerDayBookingsComponent } from './components/passenger-day-bookings/passenger-day-bookings.component';
import { LogicalAccessProviderDirective } from './directives/logical-access-provider.directive';
import { SharedOtherJourneyComponent } from './components/shared-other-journey/shared-other-journey.component';
import { SharedInboundOtherJourneyComponent } from './components/shared-inbound-other-journey/shared-inbound-other-journey.component';
import { SharedLuggageRequestComponent } from './components/shared-luggage-request/shared-luggage-request.component';
import { SharedAllBookingsComponent } from './components/shared-all-bookings/shared-all-bookings.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PanelMenuModule,
    RatingModule,
    ButtonModule,
    EditorModule,
    ConfirmDialogModule,
    CalendarModule,
    GrowlModule,
    ChartModule,
    MenuModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    FieldsetModule,
    DialogModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    TableModule,
    BlockUIModule,
    OverlayPanelModule,
    TabMenuModule,
    TabViewModule,
    SidebarModule,
    PasswordModule,
    InputSwitchModule,
    ToastModule,
    MultiSelectModule,
    AccordionModule,
    SplitButtonModule,
    KeyFilterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MiTranslateLoaderService
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MiMissingTranslationHandlerService
      }
    }),
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    DisableControlDirective,
    SearchLocationDirective,
    ChangePasswordComponent,
    EditProfileComponent,
    KeepHtmlPipe,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    DashboardStatsCountsComponent,
    DashboardActivityComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    TotalAndActiveCountsComponent,
    BookingDriverDetailsComponent,
    BookingUserDetailsComponent,
    TripDetailsComponent,
    TripMapComponent,
    UploadQuoteComponent,
    NotificationMG1Component,
    CommentInfoComponent,
    CommentReplyComponent,
    ForgotPasswordComponent,
    OtpVerifiyComponent,
    BookingHistoryDetailsComponent,
    BookingHistoryTripDetailsComponent,
    EmployeeUserDetailsComponent,
    EditableEmployeeComponent,
    EmployeeTripDetailsComponent,
    CancelSaleQuotationComponent,
    EditablePricingChauffeurComponent,
    OtpVerifiyComponent,
    SetPasswordComponent,
    EditTaxiPartnerPricingComponent,
    EditRentalPartnerPricingComponent,
    EditPartnerRoutesComponent,
    RentalDocsComponent,
    NavtabComponent,
    BookTaxiComponent,
    BookRentalComponent,
    BookChauffeurComponent,
    BookingCancelationComponent,
    RecentEmergencyRequestComponent,
    OtpComponent,
    TrackLocationComponent,
    AccessProviderDirective,
    MiGrid2Component,
    AssignDriverComponent,
    LogDeailsComponent,
    SharedBookingsListComponent,
    SharedCriticalJourneyComponent,
    SharedOutboundJourneyComponent,
    SharedVehicleTransferComponent,
    MessageComponent,
    SidebarAssignDriverComponent,
    BreadCrumbsComponent,
    UpdatePasswordComponent,
    SharedThirdPartyTransferComponent,
    DevelopementInProgressComponent,
    RippleDirective,
    SidebarBookingDetailsComponent,
    PassengerDayBookingsComponent,
    LogicalAccessProviderDirective,
    SharedOtherJourneyComponent,
    SharedInboundOtherJourneyComponent,
    SharedLuggageRequestComponent,
    SharedAllBookingsComponent
  ],
  exports: [
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    TranslateModule,
    ConfirmDialogModule,
    DialogModule,
    SearchLocationDirective,
    AccessProviderDirective,
    DisableControlDirective,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    DashboardStatsCountsComponent,
    DashboardActivityComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    KeepHtmlPipe,
    MinutsFormatPipe,
    TotalAndActiveCountsComponent,
    BookingDriverDetailsComponent,
    BookingUserDetailsComponent,
    TripDetailsComponent,
    TripMapComponent,
    UploadQuoteComponent,
    NotificationMG1Component,
    CommentInfoComponent,
    ForgotPasswordComponent,
    OtpVerifiyComponent,
    BookingHistoryDetailsComponent,
    BookingHistoryTripDetailsComponent,
    EmployeeUserDetailsComponent,
    EditableEmployeeComponent,
    EmployeeTripDetailsComponent,
    CancelSaleQuotationComponent,
    EditablePricingChauffeurComponent,
    SetPasswordComponent,
    EditTaxiPartnerPricingComponent,
    EditRentalPartnerPricingComponent,
    EditPartnerRoutesComponent,
    RentalDocsComponent,
    NavtabComponent,
    BookTaxiComponent,
    BookRentalComponent,
    BookChauffeurComponent,
    BookingCancelationComponent,
    RecentEmergencyRequestComponent,
    OtpComponent,
    MiGrid2Component,
    TrackLocationComponent,
    AssignDriverComponent,
    LogDeailsComponent,
    SharedBookingsListComponent,
    MessageComponent,
    SidebarAssignDriverComponent,
    BreadCrumbsComponent,
    UpdatePasswordComponent,
    RippleDirective,
    DevelopementInProgressComponent,
    MultiSelectModule,
    AccordionModule,
    SidebarBookingDetailsComponent,
    PassengerDayBookingsComponent,
    LogicalAccessProviderDirective,
    TabMenuModule,
    CheckboxModule,
    SplitButtonModule,
    CalendarModule,
    FieldsetModule,
    AutoCompleteModule,
    TableModule,
    KeyFilterModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        LoaderService,
        RestApiService,
        UiService,
        UserCommonService,
        StorageService,
        ErrorBindingService,
        CommonBindingDataService,
        SearchLocationDirective,
        DisableControlDirective,
        AuthenticationValidationService,
        ConfirmationService,
        TranslateService,
        SidebarService,
      ]
    };
  }

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

  }

}
