import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appRipple]'
})
export class RippleDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.el.nativeElement, 'rippleevent');
    // this.el.nativeElement.attributes.label.nodeValue
  }

  @HostListener('click', ['$event'])
  clickEvent(e) {
    e.preventDefault();
    this.renderer.addClass(this.el.nativeElement, 'app-ripple');

    setTimeout(() => {
      this.renderer.removeClass(this.el.nativeElement, 'app-ripple');
    }, 500);
  }

  @HostListener('mouseenter', ['$event'])
  hoverEvent(e) {
    e.preventDefault();
    this.renderer.addClass(this.el.nativeElement, 'app-ripple');
    const title = this.el.nativeElement.textContent;
    this.el.nativeElement.setAttribute('title', title);
    setTimeout(() => {
      this.renderer.removeClass(this.el.nativeElement, 'app-ripple');
    }, 500);
  }

}
