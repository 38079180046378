import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/components/common/api';
import { AppSettings } from './../../app.settings';
import { StorageService } from './../../services/storage.service';
import { SidebarService } from './../../services/sidebar.service';
import { Subscription } from 'rxjs';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { Title } from '@angular/platform-browser';
import { UserCommonService } from '../../services/user-common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  items: MenuItem[];
  subscription: Subscription;
  isMultipleTabOpen: any = false;
  user;
  buildVersion = AppSettings.VERSION;
  tenantInfo: any;
  // poweredByRedirectUrl = AppSettings.DEFAULT_POWERED_BY_REDIRECT_URL;
  // poweredLogoUrl = AppSettings.DEFAULT_POWERED_BY_LOGO_URL;
  poweredByRedirectUrl = AppSettings.DEFAULT_POWERED_BY_REDIRECT_URL;
  poweredLogoUrl;
  constructor(
    private storageService: StorageService,
    private sidebarService: SidebarService,
    private title: Title,
    private commonBindingDataService: CommonBindingDataService,
    public signInService: UserCommonService,

  ) {
    this.poweredLogoUrl = signInService.poweredByBeforeLogoUrl;
   }


  ngOnInit() {
    this.initSidebarItems();
    this.subscription = this.sidebarService.sideState
      .subscribe((state: any) => {
        this.updateItems(state);
      });

    this.commonBindingDataService.redrawSidebar.subscribe(() => {
      this.initSidebarItems();
    });
    this.tenantInfo = this.storageService.getItem(AppSettings.TENANT_INFO);
    if (this.tenantInfo && this.tenantInfo.poweredByName) {
      this.poweredByRedirectUrl = this.tenantInfo.poweredByName;
    }
    if (this.tenantInfo && this.tenantInfo.poweredByImageId) {
      this.poweredLogoUrl = AppSettings.GET_FILE_URL + '/' + this.tenantInfo.poweredByImageId;
    }
  }

  initSidebarItems() {

    // TODO Remove Comment when user management implementation done

    this.items = [];
    this.user = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (this.items.length <= 0) {
      if (this.user !== null && this.user !== undefined) {
        this.items = this.getMenu();
      }
    }
    this.updateItems();
    this.manageActiveStatus();


    // TODO Remove Below Initialisation when user management implementation done
    // this.items = this.initialiseSidebarMenu();
  }

  updateItems(state?) {
    if (state) {
      this.items = state;
    }
    this.items = this.removeDuplicates(this.items, 'label');
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }


  private manageActiveStatus() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.routerLink) {
        if (item.routerLink.length >= 1 && document.location.pathname === item.routerLink[0]) {
          item.expanded = true;
        }
      }
    }
  }

  selectedMenuClick(item) {
    this.storageService.setItem('selectedMenu', item.srcElement.textContent.trim());
  }

  getMenu() {
    return this.sidebarService.sideBarMenu;
  }

  // TODO Remove Function Initialisation when user management implementation done
  initialiseSidebarMenu() {
    return [
      {
        label: 'Dashboard',
        title: 'Dashboard',
        icon: 'fa-dashboard-menu',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_dashboard'));
        },
        routerLink: [
          '/admin-dashboard'
        ],
        accessCode: 'DASHBOARD'
      },
      {
        label: 'Bookings',
        title: 'Bookings',
        icon: 'fa-booking-menu',
        accessCode: 'BOOKINGS',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_bookings'));
        },
        'items': [
          {
            label: 'Current Bookings',
            title: 'Current Bookings',
            // icon: 'fa fa-chevron-right fa-1',
            icon: 'fa-current-booking',
            routerLink: [
              '/bookings/current-bookings'
            ],
            // accessCode: 'ABOUT_COMPANY'
          },
          {
            label: 'Booking History',
            title: 'Booking History',
            // icon: 'fa fa-chevron-right fa-1',
            icon: 'fa-booking-history',
            routerLink: [
              '/bookings/booking-history'
            ],
            // accessCode: 'FAQ'
          },
        ]
      },
      {
        label: 'Emergency',
        title: 'Emergency',
        icon: 'fa-emergency-menu',
        routerLink: [
          '/emergency'
        ],
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_emergency_bookings'));
        },
        // accessCode: 'Queue Management'
      },
      // {
      //   label: 'Queue Management',
      //   title: 'Queue Management',
      //   icon: 'fa-queue-menu',
      //   routerLink: [
      //     '/queue-management'
      //   ],
      //   accessCode: 'Queue Management'
      // },
      {
        label: 'Manage Shifts',
        title: 'Manage Shifts',
        icon: 'fa-shift-menu',
        routerLink: [
          '/shift-management'
        ],
        accessCode: 'Shift Management',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_shift_management'));
        },
      },
      {
        label: 'Driver Management',
        title: 'Driver Management',
        icon: 'fa-driver-menu',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_driver_management'));
        },
        // routerLink: [
        //   '/drivers'
        // ],
        // accessCode: 'DRIVERS'
        'items': [
          {
            label: 'Manage Drivers',
            title: 'Manage Drivers',
            // icon: 'fa-manage-user-menu',
            icon: 'fa-manage-driver',
            routerLink: [
              '/drivers/driver-list'
            ],
          },
          {
            label: 'Driver Dashboard',
            title: 'Driver Dashboard',
            icon: 'fa-driver-dashboard',
            routerLink: [
              '/drivers/driver-dashboard'
            ],
          }
        ]
      },
      {
        label: 'Manage Passengers',
        title: 'Manage Passengers',
        icon: 'fa-passenger-menu',
        routerLink: [
          '/passengers'
        ],
        accessCode: 'PASSENGERS',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_manage_passengers'));
        }
      },
      {
        label: 'Manage Vehicles',
        title: 'Manage Vehicles',
        icon: 'fa-vehicle-menu',
        routerLink: [
          '/vehicles'
        ],
        accessCode: 'VEHICLES',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_vihicle_management'));
        }
      },
      {
        label: 'Manage Locations',
        title: 'Manage Locations',
        icon: 'fa-locations-menu',
        routerLink: [
          '/manage-locations'
        ],
        accessCode: 'Manage Locations',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_manage_locations'));
        }
      },
      {
        label: 'Manage Zones',
        title: 'Manage Zones',
        icon: 'fa-regions-menu',
        routerLink: [
          '/manage-zones'
        ],
        accessCode: 'MANAGE_CITIES',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_manage_zones'));
        },
      },
      {
        label: 'User Management',
        title: 'User Management',
        icon: 'fa-user-management-menu',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_user_management'));
        },
        'items': [
          {
            label: 'Manage Users',
            title: 'Manage Users',
            icon: 'fa-manage-users',
            routerLink: [
              'user-management/manage-users'
            ],
            accessCode: 'MANAGE_USER'
          },
          {
            label: 'Manage Roles',
            title: 'Manage Roles',
            icon: 'fa-manage-roles',
            routerLink: [
              '/user-management/manage-roles'
            ],
            accessCode: 'MANAGE_ROLE'
          }
        ]
      },
      {
        label: 'Broadcast Management',
        title: 'Broadcast Management',
        icon: 'fa-marketing-menu',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_broadcast_management'));
        },
        'items': [
          // {
          //   label: 'Promo Code',
          //   title: 'Promo Code',
          //   // icon: 'fa-promocode-menu',
          //   routerLink: [
          //     'marketing/promo-code'
          //   ],
          //   accessCode: 'MARKETING_PROMO_CODE'
          // },
          {
            label: 'Manage Notifications',
            title: 'Manage Notifications',
            icon: 'fa-manage-notification',
            routerLink: [
              '/marketing/notifications'
            ],
            accessCode: 'MARKETING_NOTIFICATIONS'
          },
          {
            label: 'Manage Segments',
            title: 'Manage Segments',
            icon: 'fa-manage-segment',
            routerLink: [
              '/marketing/segments'
            ],
            accessCode: 'MARKETING_SEGMENTS'
          }
        ]
      },
      {
        label: 'Content Management',
        title: 'Content Management',
        icon: 'fa-content-menu',
        command: (event) => {
          this.title.setTitle(this.commonBindingDataService.getLabel('title_broadcast_management'));
        },
        'items': [
          {
            label: 'About Company',
            title: 'About Company',
            icon: 'fa-about-company',
            routerLink: [
              'content-management/about-company'
            ],
            accessCode: 'ABOUT_COMPANY'
          },
          {
            label: 'FAQs',
            title: 'FAQs',
            icon: 'fa-faq',
            routerLink: [
              'content-management/FAQs'
            ],
            accessCode: 'FAQ'
          },
          {
            label: 'Terms And Conditions',
            title: 'Terms And Conditions',
            icon: 'fa-term-condition',
            routerLink: [
              'content-management/terms-and-conditions'
            ],
            accessCode: 'TERMS_AND_CONDITIONS'
          },
          {
            label: 'Privacy Policy',
            title: 'Privacy Policy',
            icon: 'fa-privacy-policy',
            routerLink: [
              'content-management/privacy-policy'
            ],
            accessCode: 'PRIVACY_POLICY'
          }
        ]
      },
      {
        label: 'Reports',
        title: 'Reports',
        icon: 'fa-report-menu',
        'items': [
          {
            label: 'Driver Login Logout',
            title: 'Driver Login Logout',
            icon: 'fa-driver-login-logout-report',
            routerLink: [
              'reports/driver-login-logout'
            ],
            accessCode: 'DRIVER_LOGIN_LOGOUT'
          },
          {
            label: 'Driver Checkin Reports',
            title: 'Driver Checkin Reports',
            icon: 'fa-driver-checkin-report',
            routerLink: [
              '/reports/driver-checkin-reports'
            ],
            accessCode: 'DRIVER_LOGIN_LOGOUT'
          },
          {
            label: 'Driver On Duty',
            title: 'Driver On Duty',
            icon: 'fa-driver-on-duty-report',
            routerLink: [
              'reports/driver-duty'
            ],
            accessCode: 'DRIVER_ON_DUTY'
          },
          // {
          //   label: 'Driver Mileage',
          //   title: 'Driver Mileage',
          //   icon: 'fa-driver-mileage-menu',
          //   routerLink: [
          //     'reports/driver-mileage'
          //   ],
          //   accessCode: 'DIRVER_MILEAGE'
          // },
          {
            label: 'Driver Performance',
            title: 'Driver Performance',
            icon: 'fa-driver-performace',
            routerLink: [
              'reports/driver-performance'
            ],
            accessCode: 'DIRVER_PERFORMANCE'
          },
          {
            label: 'Vehicle Mileage',
            title: 'Vehicle Mileage',
            icon: 'fa-car-mileage',
            routerLink: [
              'reports/car-mileage'
            ],
            accessCode: 'VEHICLE_MILEAGE'
          },
          // {
          //   label: 'Reports - Promo Code',
          //   title: 'Reports - Promo Code',
          //   icon: 'fa-promocode-menu',
          //   routerLink: [
          //     'reports/promo-code'
          //   ],
          //   accessCode: 'REPORT_PROMO_CODE'
          // },
          // {
          //   label: 'Referral Credit',
          //   title: 'Referral Credit',
          //   icon: 'fa-refferal-credit-menu',
          //   routerLink: [
          //     'reports/referral-credit'
          //   ],
          //   accessCode: 'REFERRAL_SETTINGS'
          // },
          {
            label: 'Passenger Feedback',
            title: 'Passenger Feedback',
            icon: 'fa-passenger-feedback',
            routerLink: [
              'reports/passenger-feedback'
            ],
            accessCode: 'PASSENGER_FEEDBACK'
          },
          // {
          //   label: 'Driver Income',
          //   title: 'Driver Income',
          //   icon: 'fa-driver-income-menu',
          //   routerLink: [
          //     '/reports/driver-reports'
          //   ],
          //   accessCode: 'DRIVER_INCOME'
          // },
          // {
          //   label: 'Refund Report',
          //   title: 'Refund Report',
          //   icon: 'fa-segment-menu',
          //   routerLink: [
          //     '/reports/refund-reports'
          //   ],
          //   accessCode: 'REFUND_BOOKINGS'
          // },
          {
            label: 'Passenger Bookings',
            title: 'Passenger Bookings',
            icon: 'fa-passenger-bookings',
            routerLink: [
              '/reports/passenger-bookings'
            ],
            accessCode: 'PASSENGER_BOOKINGS'
          },

          // {
          //   label: 'Driver Feedback',
          //   title: 'Driver Feedback',
          //   icon: 'fa-segment-menu',
          //   routerLink: [
          //     'reports/driver-feedback'
          //   ],
          //   accessCode: 'DRIVER_FEEDBACK'
          // }
        ]
      },
      {
        label: 'Settings',
        title: 'Settings',
        icon: 'fa-setting-menu',
        'items': [
          {
            label: 'Time Settings',
            title: 'Time Settings',
            icon: 'fa-time-setting',
            routerLink: [
              'settings/time-settings'
            ],
            accessCode: 'TIME_SETTINGS'
          },
          {
            label: 'Time Settings',
            title: 'Time Settings',
            // icon: 'fa-priority-setting',
            routerLink: [
              'settings/priority-settings'
            ],
            accessCode: 'PRIORITY_SETTINGS'
          },
          {
            label: 'Privilege Settings',
            title: 'Privilege Settings',
            icon: 'fa-privilege-setting',
            routerLink: [
              'settings/privilege-settings'
            ],
            accessCode: 'Privilege-Settings'
          },
          // {
          //   label: 'Vehicle Type',
          //   title: 'Vehicle Type',
          //   icon: 'fa-vehicle-type-menu',
          //   routerLink: [
          //     'settings/vehicle-type'
          //   ],
          //   accessCode: 'VEHICLE_TYPES'
          // },
          // {
          //   label: 'Referral Settings',
          //   title: 'Referral Settings',
          //   icon: 'fa-reference-setting-menu',
          //   routerLink: [
          //     'settings/referral-setting'
          //   ],
          //   accessCode: 'REFERANCE_SETTINGS'
          // },
          // {
          //   label: 'Radius Settings',
          //   title: 'Radius Settings',
          //   icon: 'fa-redius-menu',
          //   routerLink: [
          //     'settings/radius-settings'
          //   ],
          //   accessCode: 'RADIUS_SETTINGS'
          // },
          // {
          //   label: 'Manage Taxes',
          //   title: 'Manage Taxes',
          //   icon: 'fa-tax-menu',
          //   routerLink: [
          //     'settings/manage-taxes'
          //   ],
          //   accessCode: 'TAXES'
          // }
        ]
      }
    ]
  }

}

